@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';

.title-container {
  @media screen and (max-width: $break-small) {
    background-image: url('../../img/oro-orb-video-still.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30rem;
    background-attachment: local;
  }
  max-width: 53.125rem;
  color: $color-black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  h1 {
    font-size: 4.375rem;
    line-height: 5rem;
    font-weight: bold;
  }
  h3 {
    font-size: 1.9375rem;
    line-height: 3.4375rem;
    font-family: 'Barlow', sans-serif;
    max-width: 36rem;
    margin: 0 auto;
  }
  @media screen and (max-width: $break-small) {
    padding: 0 0.5rem;
    h1 {
      font-size: 2.0625rem;
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.3125rem;
      line-height: 2.25rem;
    }
  }
}

button.home-btn {
  padding: 0 2.5rem;
  font-size: 1.3125rem;
  min-width: 13rem;
  min-height: 4.5625rem;
  border-radius: 5rem;
  @media screen and (max-width: $break-small) {
    font-size: 1.125rem;
    min-width: 11.375rem;
    min-height: 3.8125rem;
  }
}

.get-started-container {
  padding: 2rem;
  justify-content: center;
  text-align: center;
  background: url('../../img/main6.svg');
  background-position: left -6.25rem top -18.75rem;
  background-repeat: no-repeat;
  background-size: 62.5rem;
  h2 {
    font-size: 2.5625rem;
    line-height: 3.1875rem;
    font-weight: bold;
  }
  .subtitle {
    font-size: 1.625rem;
    line-height: 3.1875rem;
  }
  .children-container {
    display: flex;
    justify-content: space-between;
    max-width: 80rem;
    text-align: center;
  }
  .children-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1.375rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
  .children-content {
    font-size: 1.375rem;
    line-height: 2.5rem;
    @media screen and (max-width: $break-medium) {
      word-wrap: break-word;
    }
  }
  img {
    max-width: 15rem;
  }
  button {
    margin-top: 1.5rem;
  }
  @media screen and (max-width: $break-small) {
    background-position: left 5rem top -12.5rem;
    background-size: 37.5rem;
    h2 {
      font-size: 1.4375rem;
      line-height: 1.75rem;
    }
    .subtitle {
      font-size: 1.0625rem;
      line-height: 1.875rem;
    }
    .children-title {
      font-size: 1rem;
      text-align: center;
    }
    .children-content {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
    .children-container {
      text-align: center;
    }
    img {
      width: 5.125rem;
      height: 5.125rem;
    }
    button {
      margin-top: 1rem;
    }
  }
}

.best-sellers-container {
  padding: 2rem 0;
  text-align: center;
  background: $color-gray-bsellers;
  -webkit-box-shadow: 0px -10px 6px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px -10px 6px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px -10px 6px 0px rgba(0, 0, 0, 0.04);
  a > button {
    margin-top: 2rem;
  }
  h3 {
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 3.4375rem;
  }
  p {
    font-size: 1.375rem;
    line-height: 2.5rem;
  }
  @media screen and (min-width: $break-small) {
    & > div {
      padding: 0 1.6rem;
    }
  }
  @media screen and (max-width: $break-small) {
    padding: 2rem 1rem;
    h3 {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    p {
      font-size: 1.0625rem;
      line-height: 1.875rem;
      margin-bottom: 0;
    }
  }
}

.horizontal-content {
  display: flex;
  justify-content: space-between;
  max-width: 89.375rem;
  @media screen and (max-width: $break-small) {
    flex-direction: column;
    flex-direction: column-reverse;
    padding: 1rem;
    .second-column-mobile {
      margin: 0 0 0 auto;
      width: 22rem;
    }
    .third-column-mobile {
      margin: 0 0 0 auto;
    }
  }
}

.cbd-banner {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  text-align: center;
  color: $color-white;
  background: url('../../img/lets-talk-cbd-desktop.svg');
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    font-size: 4.375rem;
    line-height: 5.125rem;
    font-weight: bold;
  }
  b {
    font-size: 1.875rem;
    line-height: 2.8125rem;
  }
  p {
    font-size: 1.375rem;
    line-height: 2.5rem;
  }
  @media screen and (max-width: $break-small) {
    text-align: right;
    background: url('../../img/lets-talk-cbd-mobile.svg');
    h1 {
      font-size: 2.0625rem;
      font-weight: bold;
      padding: 0 1rem;
      line-height: 2.5rem;
    }
    b {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    div > div {
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
    }
    div > a > button {
      margin: 0 1rem;
    }
    p {
      margin-bottom: 0;
    }
    div {
      padding: 0.4rem 1rem;
    }
  }
}

.honest-wellness-container {
  position: relative;
  margin: 0 1rem;
  @media screen and (min-width: $break-small) {
    max-width: 93.75rem;
    margin: -7rem auto 0 auto;
  }
  .oro-cert-img {
    position: absolute;
    margin: auto;
    left: 5rem;
    right: 0;
    @media screen and (min-width: $break-small) {
      left: 60%;
      top: 5rem;
      right: auto;
    }
  }
  .banner-honest {
    max-width: 14.3125rem;
    margin-left: 1rem;
    margin-top: 10rem;
    @media screen and (min-width: $break-small) {
      max-width: 26.25rem;
    }
  }
  .wellness-product {
    margin-left: -4rem;
    max-width: 15.3125rem;
    @media screen and (min-width: $break-small) {
      margin: auto;
      text-align: center;
      max-width: 28.125rem;
    }
  }
  .honest-wellness-text {
    margin-top: -6rem;
    @media screen and (min-width: $break-small) {
      margin: auto;
      text-align: right;
    }
    h3 {
      font-size: 2.0625rem;
      font-weight: bold;
      line-height: 2.5rem;
      margin-bottom: 1.5rem;
      @media screen and (min-width: $break-small) {
        font-size: 2.8125rem;
        line-height: 2.8125rem;
      }
    }
    p {
      font-size: 1.0625rem;
      line-height: 1.875rem;
      margin-bottom: 3rem;
      @media screen and (min-width: $break-small) {
        font-size: 1.375rem;
        line-height: 2.5rem;
      }
    }
  }
}

.innovation-banner {
  display: flex;
  justify-content: space-around;
  h3 {
    font-weight: bold;
    font-size: 2.8125rem;
    line-height: 3.75rem;
  }
  background: linear-gradient(
    to left top,
    $color-blue-light,
    $color-green-light
  );
  padding: 6rem;
  p {
    font-size: 1.375rem;
    line-height: 2.5rem;
    max-width: 31.25rem;
    padding-bottom: 1rem;
  }
  & > div {
    max-width: 43.75rem;
  }
  & > img {
    max-width: 20rem;
    max-height: 20rem;
  }
  @media screen and (max-width: $break-small) {
    & > div {
      display: flex;
      flex-direction: column;
      & > img {
        max-width: 10rem;
        margin: 0 auto 2rem auto;
      }
    }
    padding: 2rem 1.5rem;
    text-align: center;
    & > div > a > button {
      margin-top: 1rem;
    }
    h3 {
      font-size: 1.4375rem;
      line-height: 1.75rem;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.6875rem;
    }
  }
}

.collaboration-banner {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('../../img/collaboration-banner.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 55.1875rem;
  color: $color-white;
  h3 {
    font-weight: bold;
    font-size: 3.125rem;
    line-height: 5.125rem;
  }
  p {
    font-size: 1.625rem;
    line-height: 3rem;
    max-width: 60.5rem;
    margin: 0 auto;
  }
  @media screen and (max-width: $break-small) {
    min-height: 0;
    h3 {
      font-size: 2.0625rem;
      padding: 0 2rem;
      line-height: 2.625rem;
    }
  }
}

.collaboration-mobile {
  text-align: center;
  padding: 2rem;
  p {
    font-size: 0.9375rem;
    line-height: 1.6875rem;
  }
}

.title-buttons {
  z-index: 1;
  margin-top: 1rem;
  button,
  button:hover {
    color: $color-white;
    border: 3px solid $color-white;
  }
}

.shipping-banner {
  position: absolute;
  top: 60px;
  margin-top: 15px;
  right: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.banner-button {
  z-index: 1;
  margin-top: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -$header-height-desktop;
  padding-top: $header-height-desktop;
  @media screen and (min-width: $break-small) {
    min-height: 100vh;
  }
  @media screen and (max-width: $break-small) {
    height: 31.25rem;
    margin-top: -$header-height-mobile;
    padding-top: $header-height-mobile;
  }
}

.video {
  position: absolute;
  display: flex;
  align-self: center;
  max-height: 100%;
  width: 100%;
  height: 100%;
  z-index: -2;
  object-fit: contain;
}
