@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.label {
  color: #000000;
  font-family: Barlow;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;
  padding-right: 10px;
  @media screen and (max-width: $break-small) {
    padding-right: 0px;
  }
}

.rightarrow {
  padding-right: 30px;
  @media screen and (max-width: $break-small) {
    padding-right: 20px;

    img {
      height: 11px;
      width: 14px;
    }
  }
}

.leftarrow {
  padding: 0px 0px 0px 30px;
  @media screen and (max-width: $break-small) {
    padding-right: 0px;
    img {
      height: 11px;
      width: 14px;
    }
  }
}

.bgImage {
  display: inline-block;
  background-image: url('../../../img/OrbMain.svg');
  background-repeat: no-repeat;
}
.imgbgsmall {
  @extend .bgImage;
  margin: 0px 30px;
  height: 41px;
  width: 45px;
  padding: 0px;
  @media screen and (max-width: $break-small) {
    height: 28px;
    width: 31px;
  }

  img {
    height: 42px;
    width: 30px;
    position: relative;
    bottom: 20%;
    left: 5%;
    @media screen and (max-width: $break-small) {
      height: 28px;
      width: 21px;
    }
  }
}
.imgbg {
  @extend .bgImage;
  margin: 0px 30px;
  height: 76px;
  width: 70px;
  padding: 0 0 20px 0;
  @media screen and (max-width: $break-small) {
    margin: 0px 20px;
    height: 41px;
    width: 45px;
  }

  img {
    height: 61px;
    width: 54px;
    position: relative;
    bottom: 20%;
    left: 5%;
    @media screen and (max-width: $break-small) {
      height: 41px;
      width: 29px;
    }
  }
}
.link {
  cursor: pointer;
}
