@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
  & label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }
  input {
    box-shadow: none;
    @media screen and (max-width: $break-small) {
      height: 39px;
      font-size: 12px;
      padding: 0 20px;
    }
  }
}

.leftNav {
  padding: 0px !important;
}

.closeAccountModal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 340px;
  max-width: 332px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5rem;
    @media screen and (max-width: $break-small) {
      padding: 4rem 2rem;
    }
    h1 {
      font-size: 20px;
      line-height: 1.875rem;
      text-align: center;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }
  }
  .box {
    p {
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
}

.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  h1 {
    color: $color_black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    @media screen and (max-width: $break-small) {
      margin-left: -5px;
      font-size: 14px;
    }
  }
  .profileSection {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    @media screen and (max-width: $break-small) {
      flex-direction: column;
      margin-top: 10px;
    }
    .profilePic {
      max-width: 200px;
      align-items: center;
      @media screen and (max-width: $break-small) {
        max-width: initial;
      }
      .profileimage {
        padding-bottom: 10px;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100px;
        height: 100px;
        border-radius: 50px;
      }
      button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
        min-width: 180px;
      }
      .removeBtnCell {
        margin-top: -12px;
        padding: 0;
      }
    }
    .profileDetil {
      width: 80%;
      padding: 0px 30px;
      word-break: break-word;
      @media screen and (max-width: $break-small) {
        padding: 0px;
        width: 100%;
      }
      .profileinfo {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
        border: solid 1px #f0f2f8;
        background-color: #ffffff;
        margin-bottom: 20px;
        font-size: 14px;

        .detailsDiv {
          display: flex;
          justify-content: flex-start;
          padding: 0px 20px;
          @media screen and (max-width: $break-small) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
          }
          .fieldTitle {
            min-width: 150px;
            padding: 20px 10px;
            font-weight: bold;
            @media screen and (max-width: $break-small) {
              padding: 20px 5px 5px 5px;
            }
          }
          .fieldvalue {
            display: flex;
            flex-wrap: wrap;
            border-bottom: solid 1px #e9e9f0;
            width: 100%;
            padding: 20px 10px;
            color: #43425d;
            @media screen and (max-width: $break-small) {
              padding: 5px 5px 20px 5px;
            }
          }
        }
        .detailsDiv:last-child .fieldvalue {
          border-bottom: none;
        }
      }
      button {
        display: block;
        font-size: 12px;
        min-width: 180px;
      }
      .switchdiv {
        display: flex;
        padding-bottom: 10px;
        .label {
          padding: 5px 10px;
          justify-items: center;
          font-weight: bold;
        }
      }
      a {
        text-decoration: underline;
        padding-left: 5px;
        @media screen and (max-width: $break-small) {
          padding-left: 0px;
        }
      }
    }
  }
  .profileGrid {
    .profilePic {
      align-items: center;
      margin-left: -40px;
      @media screen and (max-width: $break-small) {
        margin-left: 0px;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100px;
        height: 100px;
        border-radius: 50px;
      }
      button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        font-size: 11px;
      }
      .removeBtnCell {
        margin-top: -12px;
        padding: 0;
      }
    }
    .info {
      margin-top: 12px;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-left: -20px;

      @media screen and (max-width: $break-small) {
        font-size: 14px;
      }

      .link {
        margin-top: 5px;
        cursor: pointer;
        text-decoration: underline;
      }
      .infoGrid {
        .lableCell {
          padding: 0;
          margin: 0;
          width: 22%;
          @media screen and (max-width: $break-small) {
            width: 40%;
          }
        }
        .valueCell {
          margin: 0;
          padding: 0;
        }
        .fullwidth {
          width: 100% !important;
        }

        .infodiv {
          max-width: 1088px;
          width: 100%;
          border-radius: 10px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
          border: solid 1px #f0f2f8;
          background-color: #ffffff;
          margin: 30px 0;
          .detailsDiv {
            display: flex;
            justify-content: flex-start;
            padding: 30px 20px;

            @media screen and (max-width: $break-small) {
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
            }
          }
          .fieldTitle {
          }
          .fieldvalue {
            display: flex;
            flex-wrap: wrap;
            border-bottom: solid 1px #e9e9f0;
            width: 100%;
            padding-bottom: 30px;
            padding-left: 10px;
          }
        }
      }
    }
    .email {
      word-break: break-all;
    }
  }
}

.modal {
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 500px;
  max-width: 700px;
  width: 100%;
  overflow: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 7rem;
  background-position: 33rem 4.5rem;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content {
    display: block;
    padding: 16px 18px;
  }
  .actions-container {
    display: flex;
    flex-direction: row;
    button {
      margin-right: 20px;
      border-radius: 5rem;
      @media screen and (max-width: $break-small) {
        padding: 0.6rem 0.7rem;
        font-size: 1rem;
      }
    }
  }
}
.profileModal {
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  min-height: 0px;
  max-height: 680px;
  max-width: 1001px;
  width: 100%;
  margin: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  margin-top: 2% !important;
  background-repeat: no-repeat;
  background-size: 7rem;
  background-position: 33rem 4.5rem;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content {
    text-align: center;
    display: block;
    padding: 16px 18px;
    h1 {
      font-family: Montserrat SemiBold;
      font-size: 22px;
      line-height: 32px;
      margin-top: 25px;
    }
    p {
      font-family: Barlow Regular;
      font-size: 16px;
      line-height: 24px;
    }
    .cropper {
      width: '100%';
      text-align: center;
      margin-top: 20px;
    }
  }
  .crop-action-grid {
    margin-left: 19%;
    margin-right: 19%;
    margin-top: -8px;
    margin-bottom: 3%;
    @media screen and (max-width: $break-small) {
      margin: 0;
    }
    .actions-container-left {
      text-align: left;
      float: left;
      flex-direction: row;
      button {
        border-radius: 5rem;
        font-size: 0.9rem;
        @media screen and (max-width: $break-small) {
          padding: 0.6rem 0.7rem;
          font-size: 0.6rem;
        }
      }
    }
    .actions-container-right {
      text-align: right;
      float: right;
      flex-direction: row;
      button {
        margin-left: 10px;
        border-radius: 5rem;
        font-size: 0.9rem;

        @media screen and (max-width: $break-small) {
          padding: 0.6rem 0.7rem;
          font-size: 0.6rem;
        }
      }
    }
  }
}
.showDialog {
  display: block;
}
.hideDialog {
  display: none;
}
.imageUploadError {
  margin-left: 20px;
  color: red;
  font-size: 12px;
}
