@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.footer {
  .container {
    justify-content: space-between;
  }
  background: linear-gradient(135deg, $color-blue-footer, $color-purple-footer);
  color: $color-white;
  padding: 2rem;
  text-align: center;
  white-space: nowrap;
  max-height: 506px;
  @media screen and (max-width: $break-small) {
    max-height: 100%;
    padding: 2rem;
  }
  & > div {
    margin: 0 auto;
    max-width: 78.75rem;
    @media screen and (max-width: $break-small) {
      text-align: initial;
      margin: 0;
    }
  }
  a {
    color: $color-white;
  }
  .logo-container {
    text-align: left;
    @media screen and (max-width: $break-small) {
      padding: 0px;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 0.21rem;
      margin-bottom: -1.06rem;
    }
    img {
      max-height: 50px;
      margin-top: 0.7rem;
      @media screen and (max-width: $break-small) {
        margin-left: 0;
        margin-bottom: 0;
      }
      &.eveLogo {
        max-width: 6.0625rem !important;
      }
    }
  }

  .form {
    position: relative;
    button {
      position: absolute;
      font-size: 1.125rem;
      top: 1rem;
      right: 7.5rem;
      @media screen and (max-width: $break-small) {
        top: 0;
        right: 0;
      }
    }
    input {
      width: 22.4331rem;
      margin-top: 1rem;
      @media screen and (max-width: $break-small) {
        margin: 0;
        max-width: 100%;
      }
    }
  }
  .social {
    display: flex;
    justify-content: space-between;
    margin: 0 6px;
    & > a > img {
      max-width: 1.25rem;
      max-height: 1.25rem;
      height: 100%;
    }
  }
  .terms {
    display: flex;
    justify-content: flex-start;
    font-size: 0.9375rem;
    line-height: 2.8125rem;
    margin-top: 0.7rem;
    & > * {
      margin-left: 2rem;
    }
    & > *:first-child {
      margin-left: 1.08rem;
    }
    a:hover,
    a:visited {
      color: $color-white;
    }
    @media screen and (max-width: $break-small) {
      padding: 0.9375rem;
      flex-direction: column;
      justify-content: flex-start;
      & > * {
        margin: 0;
      }
      & > *:first-child {
        margin: 0;
      }
    }
  }
  .title {
    letter-spacing: 0.1rem;
    color: $color-white;
    font-size: 0.8125rem;
    line-height: 3.3125rem;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid #f5f0aa;
    @media screen and (max-width: $break-small) {
      font-size: 0.6875rem;
      line-height: 2.8125rem;
    }
  }
  .title2 {
    font-size: 1rem;
    line-height: 3.4375rem;
    @media screen and (max-width: $break-small) {
      font-size: 0.8125rem;
      line-height: 2.8125rem;
    }
  }
  .copyright {
    @media screen and (max-width: $break-small) {
      text-align: center;
      font-size: 0.75rem;
      line-height: 2.8125rem;
      margin-top: 1rem;
      word-break: break-all;
      b {
        display: block;
        white-space: break-spaces;
        line-height: normal;
      }
    }
  }
}

.thank-you {
  margin: 0.5rem;
  font-size: 1.125rem;
  @media screen and (max-width: $break-small) {
    font-size: 0.9375rem;
  }
}
