@import '../../../styles/_breaks.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
}
.leftnav {
  padding: 0px !important;
}
.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  h1 {
    color: $color_black;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
  .title {
    margin: 0 0 15px 12px;
  }
  .paymentContainer {
    max-width: 52rem;
    margin-left: 1rem;

    @media screen and (max-width: $break-small) {
      padding: 0px 0px 10px;
    }
  }
  button {
    @media screen and (max-width: $break-small) {
      margin-right: 10px;
      border-radius: 30px;
      padding: 8px 18px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
  .cards {
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    .cardItem {
      display: flex;
      border-radius: 1.625rem;
      height: 3.25rem;
      border: 1px solid $color-gray-light2;
      padding: 0 1.625rem;
      margin: 12px 0 8px;

      @media screen and (max-width: $break-small) {
        margin: 10px 0;
        height: 2.6rem;
        width: 100%;
        position: relative;
        p {
          padding-top: 0;
          margin-top: 0;
          font-size: 12px;
          text-overflow: ellipsis;
        }
      }

      p {
        padding-top: 0.6rem;
        width: 40%;
      }
      span {
        margin-right: 30px;
        background: url('../../../img/cards.png') no-repeat 0 0;
        background-size: auto 26px;
        width: 50px;
        display: inline-block;
        margin-top: 5px;
        @media screen and (max-width: $break-small) {
          margin-top: 5px;
        }
        &.mastercard {
          background-position: -45px 0;
        }
        &.american_express {
          background-position: -235px 0;
        }
        &.jcb {
          background-position: -188px 0;
        }
        &.discover {
          background-position: -92px 0;
        }
        &.discover_diners {
          background-position: -141px 0;
        }
      }
      button {
        outline: none;
        font-size: 0px;
        margin-right: -3%;
        width: 10%;
        cursor: pointer;
        background: url('../../../img/icons/dustbin.svg') no-repeat center
          center;
      }
    }
  }

  .form {
    margin-left: -13px;
  }
  .actions-container {
    display: flex;
    align-items: left;
    & > * {
      text-align: center;
    }
    button {
      margin-right: 20px;
      border-radius: 5rem;
    }
  }
  .error-container {
    width: 100%;
    margin-left: 1rem;
    .error {
      position: relative;
      top: 3px;
      font-size: 14px;
      color: red;
    }
  }
}

.card-input {
  border-radius: 1.625rem;
  height: 3.25rem;
  border: 1px solid $color-gray-light2;
  padding: 2px 15px;
  margin: 0;
  font-size: 1.3125rem;
  &:focus {
    outline-width: 0;
  }
  @media screen and (max-width: $break-small) {
    height: 2.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  &input {
    line-height: 0px;
    color: red;
  }
}
