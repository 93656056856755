@import '../../../../styles/_colors.scss';

.array-field-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0 !important;
  margin-bottom: -15px;
}

.addNewLink {
  text-decoration: underline;
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.fieldArray {
  display: flex;
  justify-content: space-between;
  border-radius: 1.625rem;
  height: 3.25rem;
  border: 1px solid $color-gray-light2;
  padding: 0 1.625rem;
  margin: 2px 0 8px;

  @media screen and (max-width: 767px) {
    margin: 10px 0;
    height: auto;
    width: 100%;
    position: relative;
    input {
      max-width: 15%;
      padding: 0;
      text-overflow: ellipsis;
      &:first-child {
        max-width: 40%;
      }
    }
  }

  input {
    border: none;
    &:focus {
      outline-width: 0;
    }
  }
  button {
    outline: none;
    font-size: 0px;
    min-width: 20px;
    cursor: pointer;
    background: url('../../../../img/icons/dustbin.svg') no-repeat center center;
  }
}
