@import '../../../styles/_colors.scss';

.container {
  position: relative;
  .popup {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    top: 4.4rem;
    right: -1.9rem;
    position: absolute;
    z-index: 2;
    width: 15.8125rem;
    height: auto;
    background: $color-white;
    padding: 1.5rem;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    & > ul > li {
      padding-left: 0;
      margin: 0 !important;
      a,
      span {
        color: $color-black !important;
        font-size: 1.1875rem;
      }
    }
    b {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      word-break: break-word;
    }
  }
  .popup:after {
    content: '';
    width: 1.215rem;
    height: 1.215rem;
    transform: rotate(-45deg);
    background: $color-white;
    position: absolute;
    -webkit-box-shadow: 5px -5px 6px -3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 5px -5px 6px -3px rgba(0, 0, 0, 0.1);
    box-shadow: 5px -5px 6px -3px rgba(0, 0, 0, 0.1);
    top: -0.5rem;
    right: 3rem;
  }
  &:hover .popup {
    top: 5.4rem;
    opacity: 1;
    visibility: visible;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}
