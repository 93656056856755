@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
}

.leftnav {
  padding: 0px !important;
}

.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;

  h1 {
    color: $color_black;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
  }

  .buttons {
    text-align: center;
    margin-top: 32px;

    .btn {
      box-sizing: border-box;
      border: 2px solid #000000;
      border-radius: 30px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      padding: 1rem 1.75rem;
      padding: 6px 19px;
    }
  }
}

.header {
  .subheader {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    border-bottom: 1px solid #b4b4c6;
    padding-bottom: 10px;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
      border: none;
    }

    .tabs {
      display: flex;
      flex-direction: row;
      margin-bottom: -10px;

      @media screen and (max-width: 1024px) {
        margin: 15px 0px;
        border-bottom: 1px solid #b4b4c6;
        width: 100%;
      }

      .tab {
        background-color: unset;
        padding-left: unset;
        border: none;
        outline: none;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: normal;
        line-height: 24px;
        color: #b4b4c6;
        margin-right: 25px;
        cursor: pointer;
        letter-spacing: 1px;
        position: relative;

        span {
          padding-bottom: 5px;
          display: inline-block;
        }

        div {
          padding-bottom: 1px;
        }

        &.activeTab {
          > span {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: bold;
            color: #43425d;
          }

          > div {
            text-align: center;
            background-color: #808bff;
            position: relative;
          }
        }
      }
    }

    .filters {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media screen and (min-width: $break-small) {
        margin-left: auto;
      }

      .searchFilter {
        display: flex;
        align-items: center;
        width: 300px;

        @media screen and (max-width: 1024px) {
          margin-top: 10px;
          flex-wrap: wrap;
          width: 100%;
        }

        @media screen and (max-width: $break-small) {
          [class*='-control'] {
            min-height: 41px;
            font-size: 14px;
          }
          [class*='-menu'] {
            font-size: 14px;
          }
        }
      }

      .to-label {
        margin-left: 5px;
      }

      label {
        white-space: nowrap;
        margin-right: 5px;
        color: #000000;
        font-family: Barlow;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;

        @media screen and (max-width: $break-small) {
          margin-bottom: 5px;
        }
      }
    }

    .datePicker {
      margin-right: 20px;

      @media screen and (min-width: $break-small) {
        display: flex;

        align-items: center;
      }

      p {
        font-size: 14px;
        white-space: nowrap;
        margin: 0px;
      }
    }
  }

  .list {
    display: flex;
    align-items: center;

    > div {
      display: inline-block;
      width: 100px;

      @media screen and (min-width: $break-small) {
        display: flex;
        width: 130px;
      }

      label {
        white-space: nowrap;
        color: #000000;
        font-family: Barlow;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-transform: none;

        @media screen and (max-width: $break-small) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.daterange {
  border-radius: 1.625rem;
  border: 1px solid #d8d8d8;
  padding: 8px 50px 8px 15px;
  margin: 0;
  position: relative;
  border-color: hsl(0, 0%, 80%);

  @media screen and (max-width: $break-small) {
    min-width: 200px;
  }

  img {
    position: absolute;
    top: 10px;
    bottom: 0;
    right: 0px;
    margin-right: 15px;
  }

  .closeIcon {
    height: 16px;
    width: 12px;
    margin-right: 10px;
  }
}

.nodata {
  font-size: 16px;
  color: $color_black;
}

.collapse {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 1.5rem 0;
  color: #000000;
  align-items: center;

  .collapse-title {
    text-align: left;
    margin-right: 2rem;
    font-family: Barlow;
    min-width: 60px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;

    @media screen and (max-width: $break-small) {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }

  .collapse-icon:after,
  .collapse-icon-active:after {
    content: '\2796';
    border: 1px solid #000000;
    padding: 0px;
    border-radius: 40%;
    font-size: 10px;
    height: 20px;
    width: 20px;
    display: block;
    margin-left: 10px;
    text-align: center;
    line-height: 20px;

    @media screen and (max-width: $break-small) {
      height: 16px;
      width: 16px;
      line-height: 16px;
    }
  }

  .collapse-icon:after {
    content: '\02795';
  }

  .hr {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #000000;
    width: 90%;

    @media screen and (max-width: $break-small) {
      margin-top: 0px;
    }
  }
}
