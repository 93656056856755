@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.container {
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: 100%;
  background-color: #3b3b53;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16rem;
  min-height: 9.0625rem;
  @media screen and (max-width: $break-small) {
    min-height: 16.25rem;
    flex-direction: column;
    padding: 0;
  }
  p {
    text-align: center;
    color: $color-white;
    margin: 0 1rem 1rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    line-height: 2.125rem;
    max-width: 68rem;
    @media screen and (max-width: $break-small) {
      text-align: initial;
      font-size: 0.8125rem;
      line-height: 1.5625rem;
    }
  }
  button {
    max-width: 6rem;
    margin: 0 auto;
    font-size: 1rem;
    border: none;
    border-radius: 30px;
    background: linear-gradient(to right, #7bbef2 -39%, #b65eba 110%);
    @media screen and (max-width: $break-small) {
      font-size: 0.9375rem;
    }
  }
  img {
    @media screen and (max-width: $break-small) {
      display: none;
    }
  }
}

.link > a,
.link > a:visited {
  text-decoration: underline;
  color: $color-white;
}
.line {
  margin: 20px 0 0;
  background: linear-gradient(to right, #7bf2e9 2%, #b65eba);
}
