@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.container {
  min-height: 700px;
  height: 100%;
  width: 360px;
  float: left;
  background-color: #f6f6f6;

  .title {
    padding: 32px 51px 15px 51px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
  .menu {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      display: block;
      a {
        padding: 8px 51px 8px 51px;
        font-family: Barlow;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        display: block;
        &.active {
          border-radius: 100px 0 0 100px;
          background-color: #ffffff;
          margin-left: 35px;
          text-decoration: none;
          padding-left: 15px;
          span {
            display: inline-block;
            background: linear-gradient(to right, #31c8e3 0%, #eb81d2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &:after {
              height: 1px;
              width: 100%;
              content: '';
              background-color: #979797;
              background: linear-gradient(270deg, #ea84d2 0%, #3bc9e1 100%);
              float: left;
            }
          }
        }
      }
    }
  }
}
.bookAppointmentModal {
  display: flex;
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: none;
  height: auto;
  max-width: 332px;
  width: 100%;
  margin: auto;
  overflow: visible !important;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 14px;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  @media screen and (min-width: $break-medium) {
    max-height: 430px;
    max-width: 350px;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    text-align: center;
    h1 {
      font-size: 22px;
      line-height: 1.875rem;
    }
    p {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
    }
    .notInvited {
      p {
        margin: 10px auto;
        font-size: 16px;
        color: $color-error;
      }
    }
    .dropDown {
      max-width: 270px;
      padding: 20px;
    }
  }
  .box {
    p {
      margin: 0;
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
}
.bookBtn {
  font-size: 16px;
  font-family: Barlow;
  line-height: 24px;
  button {
    letter-spacing: 1px;
    color: $color-black;
    text-decoration: none;
    outline: none;
    display: block;
    width: 100%;
    text-align: left;
    span {
      padding: 8px 51px 8px 51px;
      outline: none;
      float: left;
    }

    &.active,
    &:focus {
      border-radius: 100px 0 0 100px;
      background-color: #ffffff;
      text-decoration: underline;
      margin-left: 35px;
      span {
        padding-left: 15px;
        background: linear-gradient(to right, #31c8e3 0%, #eb81d2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &:after {
          height: 1px;
          width: 100%;
          content: '';
          background-color: #979797;
          background: linear-gradient(270deg, #ea84d2 0%, #3bc9e1 100%);
          display: block;
        }
      }
    }
  }
}
