@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.button-container {
  cursor: pointer;
  background-color: $color-white;
  width: 100%;
  padding: 0.125rem;
  margin: 0.5rem 0;
  outline: none;
  border-radius: 0.4375rem;
  & > div {
    align-items: center;
  }
  @media screen and (min-width: $break-small) {
    max-width: 18.1875rem;
    padding: 1rem;
    margin-right: 1rem;
  }
  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: $color-white;
    @media screen and (min-width: $break-small) {
      width: 8rem;
      height: 8rem;
      margin: 0 auto;
    }
  }
  &.is-selected {
    padding: 0;
    border: solid 0.125rem $color-black;
    border-radius: 0.5rem;
    @media screen and (min-width: $break-small) {
      padding: 1rem;
      background: $color-black;
      color: $color-white;
      border-radius: 0.4375rem;
      .circle {
        border: 0.125rem solid $color-white;
      }
    }
  }
}

.text-container {
  text-align: left;
  font-weight: bold;
  @media screen and (min-width: $break-small) {
    text-align: center;
  }
}

.button-title {
  font-family: 'Montserrat', sans-serif;
}

.subtitle {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-family: 'Roboto Mono', monospace;

  max-width: 10.375rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $break-small) {
    max-width: inherit;
  }
}
