@import '../../../../styles/_breaks.scss';
@import '../../../../styles/_colors.scss';

.checkboxContainer {
  display: flex;
  cursor: pointer;
  background: #fff;
  align-items: center;
}
.checkboxLabel {
  font-size: 13px;
  line-height: 2.8125rem;
  margin-bottom: 0.3rem;
}
.checkboxLabel {
  font-size: 13px;
  line-height: 2.8125rem;
  margin-bottom: 0.3rem;
  cursor: pointer;
  @media screen and (max-width: $break-small) {
    font-size: 0.813rem;
  }
}

.checkboxCustom {
  opacity: 0;
  position: absolute;
  margin: 0;

  & + .checkboxCustomLabel:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 16px;
    margin-right: 1rem;
    height: 16px;
    cursor: pointer;
    @media screen and (max-width: $break-small) {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  &:checked + .checkboxCustomLabel:before {
    content: '';
    background: $color-black;
    background-image: url('../../../../img/icons/check-mark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px;
    @media screen and (max-width: $break-small) {
      background-size: 10px;
    }
  }
}
