@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.root {
  .container {
    margin: 50px auto;
    padding: 4rem 1rem;
    box-sizing: border-box;
    width: 801px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #ffffff;
    @media screen and (max-width: $break-small) {
      padding: 3rem 2rem;
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
    }
  }
  .backDiv {
    margin: auto;
    position: relative;
    left: 30px;
    top: 30px;
    @media screen and (max-width: $break-small) {
      margin: 0 auto;
      left: -20px;
      top: -15px;
    }
    span {
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 24px;
      padding-left: 5px;
      @media screen and (max-width: $break-small) {
        font-size: 12px;
      }
    }
  }
  .content {
    max-width: 360px;
    margin: 0 auto 0 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: $break-small) {
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
      flex-direction: column;
    }

    & label {
      color: $color-black;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 24px;
      text-transform: none;
      padding: 0 0 10px 0;
    }
    h4 {
      padding: 50px 0px 20px 0;
      font-weight: bold;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    .nextBtn {
      text-align: left;
      padding: 15px 0;
      .primaryBtn {
        font-weight: bold;
        letter-spacing: 0;
        border: none;
        border-radius: 30px;
        background: linear-gradient(90deg, #3bc9e1 0%, #ea84d2 100%);
      }
    }
  }
}
.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}

.toastDiv {
  color: black;
  font-weight: 600;
}
