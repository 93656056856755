@import '../../styles/breaks';
.logo {
  display: block;
  text-align: center;
  img {
    max-height: 60px;
    @media screen and (max-width: $break-small) {
      max-height: 40px;
    }
    &.evelogo {
      max-width: 100px;
      @media screen and (max-width: $break-small) {
        max-width: 70px;
      }
    }
  }
}
