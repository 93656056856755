@import '../../../styles/_breaks.scss';

.container {
  @media screen and (min-width: $break-small) {
    background-image: url('../../../img/orbe-desktop.svg'),
      url('../../../img/main8.svg');
    background-repeat: no-repeat;
    background-position: right 35rem top 0rem, right -8rem top -15rem;
    background-size: 46.875rem, 56.875rem;
    min-height: 200px;
  }
  .content {
    margin: 0 2rem;
    font-size: 14px;
    @media screen and (min-width: $break-small) {
      max-width: 40rem;
      margin-left: 27rem;
    }
    @media screen and (max-width: $break-small) {
      margin: 2rem 2rem;
      min-height: 450px;
    }

    h1 {
      margin-bottom: 4rem;
      font-size: 2.8125rem;
      line-height: 2.1875rem;
      font-weight: bold;
      @media screen and (min-width: $break-small) {
        padding: 5rem 0;
        font-size: 9.6875rem;
        line-height: 6.75rem;
      }
      @media screen and (max-width: $break-small) {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
    h6 {
      margin-bottom: 1.5rem;
      @media screen and (min-width: $break-small) {
        font-size: 1.5rem;
        margin: 0 2rem 1.5rem 2rem;
      }
      @media screen and (max-width: $break-small) {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

    p {
      white-space: pre-wrap;
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      @media screen and (min-width: $break-small) {
        font-size: 1.5625rem;
        line-height: 2.375rem;
        margin: 2rem;
      }
    }
  }
}
