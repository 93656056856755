@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/colors';

.root {
  width: 50%;
  padding: 10px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .clinicianBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 150px;
    padding: 0 20px 0 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #f0f2f8;
    background-color: $color-white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    .viewProfileDiv {
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 1399px) {
      padding: 15px;
      height: auto;
      flex-wrap: wrap;
      .viewProfileDiv {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;
        button {
          margin: 0;
        }
      }
    }
    @media screen and (max-width: 1024px) {
    }
    .commonButton {
      background-color: #e6e6e6;
      cursor: pointer;
      color: #7a7a7a;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 14px;
      border-radius: 100px;
      border: none;
      outline: none;
      padding: 10px 20px;
      @media screen and (max-width: $break-small) {
        padding: 10px 15px;

        font-size: 10px;
      }
    }
    .enable {
      border-radius: 100px;
      color: $color-white;
      background-color: #19913d;
    }
    .notEnable {
      border-radius: 30px 0px 0px 30px;
    }

    .disable {
      border-radius: 100px;
      color: $color-white;
      background-color: #ff6565;
    }
    .notDisable {
      border-radius: 0px 30px 30px 0px;
    }

    .profilePic {
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin-right: 15px;
      @media screen and (max-width: $break-small) {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      img {
        border-radius: 50%;
      }
    }
    .ClinicianDetails {
      margin-right: auto;
      max-width: 155px;
      .status {
        .complete {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          // max-width: 96px;
          padding: 0 5px;
          width: 100%;
          height: 29px;
          object-fit: contain;
          background-color: #808bff;
          border-radius: 5px;
          color: #ffffff;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.23;
          letter-spacing: normal;
          background-color: #808bff;
          @media screen and (max-width: $break-small) {
            font-size: 10px;
            height: 22px;
          }
        }
        .inComplete {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          // max-width: 96px;
          padding: 0 5px;
          width: 100%;
          height: 29px;
          object-fit: contain;
          background-color: #808bff;
          border-radius: 5px;
          color: #ffffff;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.23;
          letter-spacing: normal;
          background-color: #ffa177;
          @media screen and (max-width: $break-small) {
            font-size: 10px;
            height: 22px;
          }
        }
      }
      .name {
        margin: 18px 0 17px;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: left;
        color: #2f2e50;
        @media screen and (max-width: $break-small) {
          font-size: 10px;
          margin: 10px 0px;
        }
      }
      .speciality {
        height: 30px;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #43425d;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: $break-small) {
          font-size: 10px;
          height: 25px;
        }
      }
      p {
        margin: auto;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        @media screen and (max-width: $break-small) {
          font-size: 10px;
        }
      }
      @media screen and (max-width: $break-small) {
        max-width: 110px;
      }
    }
    .seeProfile {
      max-width: 170px;
      width: 100%;
      padding: 10px 20px;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        width: auto;
        max-width: none;
      }
      @media screen and (max-width: $break-small) {
        font-size: 10px;
      }
    }
    .actionDiv {
      display: flex;
      background-color: #ebebf2;
      border-radius: 100px;
      margin: 20px 0px;
      border: none;
      @media screen and (max-width: $break-small) {
        margin: 0px;
      }
      button {
        border: none;
      }
    }

    .approveBtn {
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 14px;
      border-radius: 100px;
      border: none;
      outline: none;
      width: 170px;
      background-color: #808bff;
      color: #ffffff;
      font-family: Montserrat;
      padding: 10px 20px;
      margin: 20px 0px;
      @media screen and (max-width: $break-small) {
        font-size: 10px;
        padding: 10px 15px;
        width: 100px;
      }
    }
  }
  .switchBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: #ebebf2;
    border: solid 1px #f0f2f8;
    border-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 54px;
    .matchingSwitchDiv {
      display: flex;
      max-height: 50px;
    }
    @media screen and (max-width: 1399px) {
      padding: 15px;
      height: auto;
      flex-wrap: wrap;
      .viewProfileDiv {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;
        button {
          margin: 0;
        }
      }
    }
    p {
      font-family: Montserrat;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      margin: 0px;
      @media screen and (max-width: $break-small) {
        font-size: 10px;
      }
    }
  }
}
