@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.container {
  margin: 16px 0px;

  .addNewBtn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $color-gray-background;
    padding: 20px;

    margin-bottom: 20px;

    @media screen and (max-width: $break-small) {
      flex-direction: column;
    }

    // @media screen and (max-width: $break-small) {
    //   display: inline-block;
    //   width: 100%;
    //   height: 100%;
    //   padding: 0px;
    //   padding-top: 5px;
    //   flex-direction: row;
    // }

    .bgImg {
      display: inline-block;
      background-image: url('../../img/OrbMain.svg');
      background-repeat: no-repeat;

      @media screen and (max-width: $break-small) {
        float: left;
      }
    }

    .title {
      color: #000000;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 24px;

      @media screen and (max-width: $break-small) {
        margin-left: 0;
      }
    }
  }

  .rightContent {
    margin-left: auto;
    display: flex;
    align-items: center;

    @media screen and (max-width: $break-small) {
      flex-direction: column;
      margin-left: 0;
      margin-top: 20px;
    }

    .desc {
      font-size: 16px;
      color: $color-gray-dark;
      margin-right: 40px;
      margin-left: 20px;
    }

    .buttons {
      padding-left: 20px;
      display: flex;
      width: 250px;
      justify-content: flex-end;
      align-self: center;

      @media screen and (max-width: $break-medium) {
        padding-left: 20px;
        text-align: right;
        flex-wrap: wrap;
        width: 150px;
      }

      @media screen and (max-width: $break-small) {
        padding-left: 0;
        width: 100%;
        margin-top: 20px;
        justify-content: center;
      }

      .btn {
        @media screen and (max-width: $break-medium) {
          &:first-child {
            margin-bottom: 20px;
          }
        }

        @media screen and (max-width: $break-small) {
          &:first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.btn {
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  margin: 0 0 0 20px;
}

.modal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  bottom: auto;
  width: 100%;
  margin: auto;
  overflow: auto;
  border: 1px solid black;
  border-radius: 10px;
  max-width: 400px;
  top: 10%;

  @media screen and (max-width: $break-small) {
    max-width: 90%;
    bottom: 10%;
    max-height: 86%;
  }

  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }

  .modalcontent {
    display: block;
    padding: 16px 18px;

    .closeButton {
      width: 16px;
      top: 10px;
      position: absolute;
      right: 10px;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;

      button:first-child {
        margin-left: 0;
      }
    }

    .dropzone {
      display: flex;
      align-items: center;

      button {
        margin-bottom: 0;
      }

      .dropzoneDesc {
        margin-left: 10px;
        color: $color-gray;
      }

      margin-bottom: 10px;
    }
  }
  :global .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
  :global .ql-container.ql-snow {
    height: 350px;
    border: none;
  }
  :global .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc;
    height: 350px;
  }

  :global .ql-snow.ql-toolbar {
    border: none;
    display: block;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  :global .ql-editor {
    min-height: 18em;
  }
}

.modalheader {
  display: flex;

  > div {
    margin-right: 40px;
    letter-spacing: 0.75px;
    line-height: 20px;
  }
}

.line {
  box-sizing: border-box;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid $color-gray-light3;
}

.fileName {

}

.fileError {
  margin-bottom: 10px;
  color: $color-error;
}
