@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.circle-small-container {
  background-color: $color-white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  width: 16.7675rem;
  height: 5rem;
  outline: none;
  cursor: pointer;
  padding-left: 1rem;
  > :first-child {
    margin-right: 0.5rem;
  }
  @media screen and (max-width: $break-small) {
    width: 10.2338rem;
    height: 3.125rem;
  }
  .circle-small {
    width: 2.2662rem;
    height: 2.2662rem;
    border-radius: 50%;
    margin-right: 0.7rem;
    border: 2px solid $color-black;
    &.is-selected {
      background: $color-black;
    }
    @media screen and (max-width: $break-small) {
      width: 1.6875rem;
      height: 1.6875rem;
      border: 2px solid $color-black;
    }
  }
  .circle-small-text {
    text-align: left;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    line-height: 2.8125rem;
    @media screen and (max-width: $break-small) {
      font-size: 0.9375rem;
      font-weight: 400;
    }
  }
}
