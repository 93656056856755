@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;

  .content {
    max-width: 1170px;
    width: 100%;
    overflow: hidden;
    padding: 20px;
    @media screen and (max-width: $break-small) {
      padding: 20px 10px 10px 10px;
    }
    h1 {
      color: $color_black;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    .buttons {
      text-align: center;
      margin-top: 32px;

      .btn {
        box-sizing: border-box;
        border: 2px solid #000000;
        border-radius: 30px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        padding: 1rem 1.75rem;
        padding: 6px 19px;
      }
    }

    .clinicianList {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .header {
    padding: 0 10px;
    @media screen and (min-width: $break-small) {
      display: flex;
    }
  }
}
