@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/colors';

.root {
  font-family: Montserrat;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 30.5px 20px 20px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f0f2f8;
  background-color: $color-white;
  position: relative;

  @media screen and (max-width: $break-small) {
    padding: 10px;
  }

  .requestButton {
    margin-bottom: 0px;
    white-space: nowrap;
    margin-right: 10px;
    @media screen and (max-width: $break-small) {
      margin-bottom: 10px;
      min-width: 140px;
    }
  }

  .moreBtn {
    padding: 0 0 10px 0;
    border: none;
    font-size: 20px;
    min-width: 26px;
  }

  .popup {
    width: 130px;
    position: absolute;
    right: 0;
    top: 40px;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    z-index: 2;
    background-color: $color-white;

    button {
      cursor: pointer;
      text-align: left;
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }
  }

  .meeting-time {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $break-small) {
      margin: 0px 0px 12px 0px;
    }

    .timeDiv {
      display: flex;
    }

    .cancelBtn {
      position: absolute;
      right: 20px;
      top: 0px;

      @media screen and (max-width: $break-small) {
        right: 10px;
      }

      .btn {
        margin-right: 20px;

        @media screen and (max-width: $break-small) {
          margin: 0 10px;
          width: 105px;
          padding: 10px 10px;
          font-size: 10px;
        }
      }
    }

    p {
      margin: 0 0 0 10px;
      font-family: Montserrat;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: bold;
      color: #2f2e50;

      @media screen and (max-width: $break-small) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .meeting-details {

    .title {
      font-family: Montserrat;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: bold;
      padding: 0 0 10px 0px;
      color: #2f2e50;

      @media screen and (max-width: $break-small) {
        padding: 0px;
      }
    }

    .text {
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: $break-small) {
       flex-direction: row;
       width: 100%;
      }

      .type,
      .location {
        font-family: Montserrat;
        letter-spacing: 0.75px;
        line-height: 24px;
        margin-right: 20px;
        color: #2f2e50;

        @media screen and (max-width: $break-small) {
          margin-right: 10px;
        }

        span {
          font-size: 15px;
          opacity: 0.8;
        }

        p {
          font-size: 15px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .imgBox {
    min-width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 40px;
    width: 80px;
    display: flex;
    margin-right: 15px;
    align-self: center;

    @media screen and (max-width: $break-small) {
      min-width: 40px;
      border-radius: 30px;
      width: 40px;
      height: 40px;
      align-self: inherit;
    }

    img {
      object-fit: cover;
      height: 100%;

      @media screen and (max-width: $break-small) {
        border-radius: 40px;
      }
    }
  }

  .statusDetailsWrapper {
    display: flex;
    align-items: center;
    color: #2f2e50;
    font-size: 15px;
    @media screen and (max-width: $break-small) {
      flex-wrap: wrap;
    }

    .superbilltext {
      margin-left: 10px;
      @media screen and (max-width: $break-small) {
        margin-left: 0px;
        width: 100%;
        margin-bottom: 5px;
      }
    }

    .buttons {
      margin-left: 10px;
      display: flex;
      @media screen and (max-width: $break-small) {
        margin-left: 0px;
        margin-bottom: 5px;
        flex-wrap: wrap;
      }
    }

    .fees {
      text-transform: capitalize;
      font-size: 15px;
      color: $color-gray-deep;
      margin-left: 10px;
      @media screen and (max-width: $break-small) {
        margin-left: 0px;
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }

  .appointmentDetails {
    width: 100%;
    display: flex;
    @media screen and (max-width: $break-small) {
      flex-wrap: wrap;
    }
    .meetingData {
      display: inline-block;
      margin-right: auto;
    }

  }

  .appointmentWrapper {
    display: flex;
    margin-left: 10px;
  }

  .completed {
    color: $color-green-forest;
    font-weight: bold;
    font-size: 15px;
    font-weight: bold;
    padding-right: 37px;
    position: relative;

    &:after {
      content: '';
      background: $color-green-forest;
      background-image: url('../../img/icons/check-mark.svg');
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
      display: inline-block;
      border-radius: 50%;
      background-position: center;
      position: absolute;
      background-size: 18px;
      right: 0;
      top: -5px;
    }
  }


}