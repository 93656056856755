@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.container {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ececec;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 16px 0px;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: $break-small) {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0px;
      padding-top: 5px;
      flex-direction: row;
    }

    img {
      position: relative;
    }

    .closeButton {
      img {
        left: 0px;
        position: relative;
        bottom: 0px;
      }
    }

    .bgImg {
      display: inline-block;
      background-image: url('../../img/OrbMain.svg');
      background-repeat: no-repeat;
      position: relative;
      @media screen and (max-width: $break-small) {
        float: left;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      color: #000000;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      padding-left: 24px;
      display: flex;
      @media screen and (max-width: $break-small) {
        float: left;
      }
      p {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .lastEdited {
      display: flex;
      flex-wrap: wrap;
    }

    .lastSaved {
      color: #000000;
      font-family: Barlow;
      font-size: 12px;
      letter-spacing: 0.75px;
      line-height: 20px;
      padding-right: 20px;
      @media screen and (max-width: $break-small) {
        float: left;
      }
    }
  }
  .noteContent {
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 700px;
    width: 100%;
    @media screen and (max-width: $break-small) {
      max-width: 250px;
      width: 100%;
    }
  }
  .addedNote {
    width: 100%;
    display: flex;
    border: 2px solid #ececec;
    border-radius: 8px;
    padding: 16px 10px;

    @media screen and (max-width: $break-small) {
      justify-content: start;
    }
  }
  .rightContent {
    margin-left: auto;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: $break-small) {
      margin-left: auto;
      display: flex;
      justify-content: space-around;
      margin-top: -15px;
      padding-left: 10px;
    }
    > span {
      color: #000000;
      font-family: Barlow;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: right;
      padding-left: 30px;
      @media screen and (max-width: $break-small) {
        vertical-align: top;
        padding-left: 14px;
      }
    }

    .buttons {
      padding-left: 20px;
      display: flex;
      width: 250px;
      justify-content: flex-end;

      @media screen and (max-width: $break-medium) {
        padding-left: 20px;
        text-align: right;
        flex-wrap: wrap;
        width: 150px;
      }
      button {
        margin-left: 10px;
        margin-bottom: 10px;
      }
      .btn {
        box-sizing: border-box;
        border: 2px solid #000000;
        border-radius: 30px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        padding: 1rem 1.75rem;
        padding: 6px 19px;
      }
    }
  }
}

.modal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  bottom: auto;
  width: 100%;
  margin: auto;
  overflow: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 691px;
  top: 10%;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
    bottom: 10%;
    max-height: 86%;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: block;
    padding: 16px 18px;

    .closeButton {
      width: 16px;
      top: 10px;
      position: absolute;
      right: 10px;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }
    h1 {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }
    .contentDetail {
      padding-top: 10px;

      .box {
        border: solid 15px #f2f2f2;

        .content-detail {
          background-color: #ffffff;
          padding: 19px 28px;
          max-height: 451px;
          overflow: auto;

          h2 {
            color: #000000;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
          }

          span {
            color: #b3b3b3;
            font-family: Barlow;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
          p {
            color: #000000;
            font-family: Barlow;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
          .detail {
            display: flex;
          }
          .column3 {
            width: 33%;
          }
          .column2 {
            width: 50%;
          }
        }
        .table {
          width: 100%;

          .table-tr {
            display: flex;
            div {
              width: 23%;
              color: #b3b3b3;
              font-family: Barlow;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
            }
            p {
              width: 23%;
              color: #000000;
              font-family: Barlow;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
            }
          }
          .table-td {
            display: flex;
            div {
              width: 25%;
              color: #b3b3b3;
              font-family: Barlow;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
            }
            span {
              width: 25%;
              color: #000000;
              font-family: Barlow;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
            }
          }
        }
      }

      .meeting-details {
        .meeting-time {
          display: flex;
          p {
            margin: 0 0 0 10px;
            font-family: Montserrat;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 24px;
            font-weight: bold;
            @media screen and (max-width: $break-small) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
        .isAfter {
          color: #3bc9e1;
        }
        .isBefore {
          color: black;
        }
        .title {
          font-family: Montserrat;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: bold;
          padding: 10px 0px;
        }
        .text {
          display: flex;
          @media screen and (max-width: $break-small) {
            display: inline-block;
            width: 100%;
          }
          .type,
          .location {
            font-family: Barlow;
            letter-spacing: 0.75px;
            line-height: 24px;
            margin-right: 0px;
            width: 33%;
            float: left;
            @media screen and (max-width: $break-small) {
              font-family: Barlow;
              letter-spacing: 0.75px;
              line-height: 24px;
              float: left;
              width: 50%;
              padding-right: 10px;
              margin: 0px;
            }
            span {
              font-size: 12px;
              opacity: 0.8;
            }
            p {
              font-size: 16px;
            }
          }
          @media screen and (max-width: $break-small) {
            .reason {
              width: 100%;
            }
          }
        }
      }
    }
  }
  :global .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
  :global .ql-container.ql-snow {
    height: 350px;
    border: none;
  }
  :global .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc;
    height: 350px;
  }

  :global .ql-snow.ql-toolbar {
    border: none;
    display: block;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  :global .ql-editor {
    min-height: 18em;
  }
}

.modalheader {
  display: flex;

  > div {
    margin-right: 40px;
    letter-spacing: 0.75px;
    line-height: 20px;
  }
}

.line {
  box-sizing: border-box;
  margin-bottom: 10px;
  height: 1px;
  width: 662px;
  border-bottom: 1px solid #f1f1f1;
}

.buttons {
  text-align: end;
  @media screen and (max-width: $break-small) {
    padding-left: 20px;
    margin-top: 10px;
    padding: 15px 0 0 0;
  }
  button {
    margin-left: 10px;
    margin-bottom: 0px;
  }
  .btn {
    box-sizing: border-box;
    border: 2px solid #000000;
    border-radius: 30px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding: 1rem 1.75rem;
    padding: 6px 19px;
  }
}
