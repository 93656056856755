@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.container {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ececec;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 16px 0px;

  .content {
    display: flex;
    flex-direction: row;
    padding: 7px 15px;
    align-items: center;
    @media screen and (max-width: $break-small) {
      display: inline-block;
      width: 100%;
      padding: 0px;
      padding-top: 5px;
      flex-direction: row;
    }
    .tick {
      height: 14px;
      width: 14px;
      margin-top: 32px;
      @media screen and (max-width: $break-small) {
        margin-top: 17px;
        margin-left: 20px;
      }
    }

    img {
      height: 43px;
      width: 32px;
      left: 10px;
      position: relative;
      bottom: 15px;
    }
    .bgImg {
      height: 36px;
      width: 40px;
      padding: 0 0 20px 0;
      display: inline-block;
      background-image: url('../../img/OrbMain.svg');
      background-repeat: no-repeat;
      position: relative;
      top: 10px;
      @media screen and (max-width: $break-small) {
        float: left;
      }
    }
    .title {
      color: #000000;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      padding-left: 24px;
      display: flex;
      @media screen and (max-width: $break-small) {
        float: left;
      }
    }

    .rightContent {
      margin-left: auto;
      display: flex;
      align-items: center;
      @media screen and (max-width: $break-small) {
        margin-left: auto;
        display: inline-block;
        width: 100%;
        padding-left: 30px;
        margin-top: -15px;
      }
      > span {
        color: #000000;
        font-family: Barlow;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-align: right;
        padding-left: 30px;
        @media screen and (max-width: $break-small) {
          vertical-align: top;
          padding-left: 14px;
        }
      }

      .buttons {
        padding-left: 20px;
        @media screen and (max-width: $break-small) {
          padding-left: 20px;
          text-align: right;
          margin-top: 15px;
        }
        button {
          margin-left: 10px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.modal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  bottom: auto;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 691px;
  margin: auto;
  top: 10%;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
    bottom: 10%;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: block;
    padding: 0;
    .closeButton {
      width: 16px;
      top: 21px;
      position: absolute;
      right: 25px;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }
    h1 {
      font-size: 1.25rem;
      line-height: 1.875rem;
      padding: 16px 18px;
      margin: 0;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }

    .box {
      border: solid 15px #f2f2f2;

      .content-detail {
        background-color: #ffffff;
        padding: 19px 28px;
        max-height: 451px;
        overflow: auto;

        h2 {
          color: #000000;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 24px;
          text-align: left;
        }

        span {
          color: #b3b3b3;
          font-family: Barlow;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 16px;
        }
        p {
          color: #000000;
          font-family: Barlow;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 16px;
        }
        .detail {
          display: flex;
        }
        .column3 {
          width: 33%;
        }
        .column2 {
          width: 50%;
        }
      }
      .table {
        width: 100%;

        .table-tr {
          display: flex;
          div {
            width: 23%;
            color: #b3b3b3;
            font-family: Barlow;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
          p {
            width: 23%;
            color: #000000;
            font-family: Barlow;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
        }
        .table-td {
          display: flex;
          div {
            width: 25%;
            color: #b3b3b3;
            font-family: Barlow;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
          span {
            width: 25%;
            color: #000000;
            font-family: Barlow;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.resetmodal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 250px;
  max-width: 250px;
  width: 100%;
  margin: auto;
  overflow: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  @media screen and (max-width: $break-small) {
    max-width: 250px;
    font-size: 12px;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    text-align: center;
    h1 {
      font-size: 22px;
      line-height: 30px;
      @media screen and (max-width: $break-small) {
        font-size: 20px;
        margin: 0 0 10px 0;
      }
    }
    p {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      @media screen and (max-width: $break-small) {
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 10px 0;
      }
    }
    .primaryButton {
      padding: 10px 20px;
      background-color: $color-black;
      color: $color-white;
      font-weight: bold;
      font-size: 12px;
    }
    .secondaryButton {
      padding: 10px 20px;
      font-size: 12px;
    }
  }
}
