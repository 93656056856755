@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';
@import '../styles/_common.scss';
.form {
  max-width: 52rem;
  margin: auto;
  font-size: 13px;

  form > span {
    background-image: url('../../../../img/gradient.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .label-bold > div > div > label,
  & h5,
  & h3 {
    text-align: left;
    margin: 0 0 3px;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
  .label-bold > div > div > label {
    margin-right: 12px;
  }
  & h3 {
    margin: 0 0 -12px;
    @media screen and (max-width: $break-small) {
      margin: 0 0 -5px;
    }
  }

  & button {
    margin-bottom: 0;
    min-width: 115px;
    padding: 10px;
    font-size: 16px;
  }

  & label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }
  input {
    box-shadow: none;
    outline: none;
    @media screen and (max-width: $break-small) {
      height: 39px;
      font-size: 12px;
      padding: 0 20px;
    }
  }
  hr {
    border-color: #ececec;
    opacity: 1;
    width: 100%;
    margin: 10px 15px;
  }
  .box {
    border-color: rgba(0, 0, 0, 0.5) !important;
  }
  .CheckBox_box-name {
    margin: 0;
  }
  form > div > div:last-child {
    padding: 25px 15px 45px;
    justify-content: center;
  }
  @media screen and (max-width: $break-small) {
    form > div > div {
      padding: 5px 15px;
    }
  }
}
.CheckBox_box-name {
  margin: 0;
}
.formTitle {
  font-size: 2rem;
  line-height: 1.5rem;
  text-align: left;
  font-weight: bold;
  @media screen and (max-width: $break-small) {
    font-size: 22px;
    line-height: 32px;
  }
}

.formInfo {
  margin-top: -8px;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.step-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    left: 0.9375rem;
    right: 0.9375rem;
    bottom: 0;
    display: block;
    border-bottom: solid 1px #d8d8d8;
  }
  @media screen and (max-width: $break-small) {
    margin: 10px 0 0;
    padding-bottom: 15px !important;
  }
}

.formSubTitle {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  @media screen and (max-width: $break-small) {
    font-size: 16px;
    margin: 0;
  }
}

.stepInfo {
  padding: 0 12px;
  border: 1px solid #d8d8d8;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  height: 24px;
  border-radius: 15px;
  @media screen and (max-width: $break-small) {
    margin: 0;
  }
}

.section {
  margin: 20px 0 -5px 0;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  font-weight: bold;
  @media screen and (max-width: $break-small) {
    margin: 10px 0 0 0;
  }
}
.radioBtn,
.radioBtnNp {
  * {
    height: auto;
  }
  > div {
    justify-content: normal;
    flex-wrap: wrap;
  }
  label {
    margin: 0 10px 0 0;
    float: left;
    span {
      width: 20px;
      height: 20px;
      background-size: 9px;
    }
  }
}
.radioBtnNp {
  padding: 0 !important;
}
.radio-buttons {
  margin-right: 30px;
}
.actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    text-align: center;
  }
  button {
    margin-right: 20px;
    border-radius: 5rem;
  }
}
.error-label {
  font-size: 12px;
}
.border-top {
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: solid 1px #d8d8d8;
}
:global .mt-none {
  margin-top: 0;
}
.firstImage {
  height: 510px;
  width: 435px;
  top: 125px;
  left: -190px;
  position: absolute;
}
.secondImage {
  background-position: 100px 10px;
  height: 538px;
  width: 435px;
  top: 1198px;
  right: 0;
  position: absolute;
}

.thirdImage {
  height: 510px;
  width: 435px;
  top: 2245px;
  left: -190px;
  position: absolute;
  background-image: url('../../../../img/gradient.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.fourthImage {
  background-position: 100px 10px;
  height: 538px;
  width: 435px;
  top: 3250px;
  right: 0;
  position: absolute;
  background-image: url('../../../../img/gradient.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.dropDown {
  z-index: 3;
}
