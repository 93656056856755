@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.container {
  box-sizing: border-box;
  max-width: 500px;
  border: 2px solid #000000;
  border-radius: 8px;
  background-color: #f6f6f6;
  display: flex;
  cursor: pointer;
}

.box-name {
  color: #000000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin: 5px 0px;
  text-align: left;
  padding: 8px 5px;
  @media screen and (max-width: $break-small) {
    font-size: 16px;
    padding: 15px 12px 12px 0px;
    line-height: 20px;
    margin: 0px;
  }
}

.box {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border: 2px solid #000000;
  border-radius: 2px;
  background-color: #ffffff;
  margin: 15px 10px;
  @media screen and (max-width: $break-small) {
    margin: 15px;
  }
}

.box-active {
  @extend .box;
  background: $color-black;
  &:after {
    content: '';
    background-image: url('../../../../img/icons//check-mark.svg');
    background-repeat: no-repeat;
    min-height: 10.11px;
    max-width: 14px;
    position: absolute;
    margin: 3px 0px 0px -7px;
    width: 100%;
  }
}
