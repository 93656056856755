@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';
@import '../styles/_common.scss';

.form {
  max-width: 52rem;
  margin: auto;
  font-size: 13px;

  form > span {
    background-image: url('../../../../img/gradient.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .label-bold > div > div > label,
  & h5,
  & h3 {
    text-align: left;
    margin: 0 0 3px;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
  .label-bold > div > div > label {
    margin-right: 12px;
  }
  & h3 {
    margin: 0 0 -12px;
    @media screen and (max-width: $break-small) {
      margin: 0 0 -5px;
    }
  }

  & label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }
  input {
    box-shadow: none;
    outline: none;
    @media screen and (max-width: $break-small) {
      height: 39px;
      font-size: 12px;
      padding: 0 20px;
    }
  }
  hr {
    border-color: #707070;
    opacity: 1;
    width: 100%;
    margin: 10px 10px;
  }
  .box {
    border-color: rgba(0, 0, 0, 0.5) !important;
  }
  .CheckBox_box-name {
    margin: 0;
  }
  form > div > div:last-child {
    padding: 25px 15px 45px;
    justify-content: center;
  }
  @media screen and (max-width: $break-small) {
    form > div > div {
      padding: 5px 15px;
    }
  }
}

.formTitle {
  font-size: 2rem;
  line-height: 1.5rem;
  text-align: left;
  font-weight: bold;
  @media screen and (max-width: $break-small) {
    font-size: 22px;
    line-height: 32px;
  }
}

.formInfo {
  font-size: 15px;
  color: $color-gray-dark;
}

.formSubtitle {
  font-size: 20px;
  color: #43425d;
}

.radioButtonList {
  display: flex;
  flex-direction: column;
}

.section {
  margin: 20px 0 -5px 0;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  font-weight: bold;
  @media screen and (max-width: $break-small) {
    margin: 10px 0 0 0;
  }
}

.subsection {
  margin: 15px 0 -10px 0;
  font-size: 13px;
  font-weight: bold;
  @media screen and (max-width: $break-small) {
    margin: 10px 0 0 0;
  }
}
.radioBtn {
  * {
    height: auto;
  }
  > div {
    justify-content: normal;
    flex-wrap: wrap;
  }
}
.customLabel {
  padding: 0 0.9375rem;
}

.actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    text-align: center;
  }
  button {
    margin-right: 20px;
    border-radius: 5rem;
  }
}
.noMarginButton {
  margin: 0;
}
.error-label {
  font-size: 12px;
}
.border-top {
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: solid 1px #d8d8d8;
}
:global .mt-none {
  margin-top: 0;
}

.fieldset {
  padding: 0;
  width: 100%;
}
.centeredButton {
  text-align: center;
  padding: 0;
}

.centeredButton .buttonAddItem {
  display: block;
  margin: 0 auto;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  padding: 0;

  &:disabled {
    color: $color-gray-disabled;
  }
}

.customAddedItem {
  width: 100%;

  .fieldsContainer {
    font-family: 'Montserrat';
    border-top: 1px solid $color-gray-dark;
    border-bottom: 1px solid $color-gray-dark;
    color: $color-gray-dark;
    width: 100%;
  }

  .fieldArray {
    display: flex;
    justify-content: space-between;
    padding: 9px 0;
    align-items: center;
  }

  .fieldsWrapper {
    span:first-child {
      font-weight: bold;
    }

    span {
      margin-right: 20px;
      font-size: 13px;
    }
  }

  .itemButtonsWrapper {
    button:first-child {
      margin-right: 10px;
    }
  }
  .remove,
  .edit {
    outline: none;
    font-size: 0px;
    min-width: 20px;
    min-height: 20px;
    cursor: pointer;
    background: url('../../../../img/icons/dustbin.svg') no-repeat center top;
  }
}
.cellSpacing {
  margin-bottom: -15px;
}

.pageNumber {
  display: 'flex';
  justify-content: 'space-between';
  width: 100%;
}
