@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.SignInBox {
  .LogoPart {
    padding: 30px 0 25px;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
  }
  .waitListForm {
    padding: 15px 40px;
    p.title {
      font-size: 15px;
      line-height: 18px;
      color: #5e5e5e;
      text-align: center;
      padding: 30px 0 10px 0;
      margin: 0;
    }
  }
  img {
    margin: 20px 0 0 0;
  }
  label {
    color: #5e5e5e;
    font-size: 14px;
    text-transform: none;
  }
}

.sucessMessage {
  padding: 0 40px;
  p {
    margin: 0;
    color: #5e5e5e;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  }
  p:first-child {
    padding-bottom: 16px;
    padding-top: 36px;
  }
  p:last-child {
    padding-top: 0;
  }
}

.emailField {
  input {
    border-radius: 3px !important;
    box-shadow: none;
    @media screen and (max-width: $break-small) {
      height: 39px;
      font-size: 12px;
      padding: 0 20px;
    }
  }
  input:focus {
    box-shadow: none;
  }
}

.submitInBtn,
.backSignInButton {
  width: 100%;
  line-height: 36px;
  padding: 2px 10px !important;
  font-size: 14px !important;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #3bc9e1 0%, #ea84d2 100%);
}

.radioDiv {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.backSignInButton {
  margin-top: 10px;
}

.backSignInLink {
  a {
    font-size: 13px !important;
    color: #5e5e5e !important;
  }
}

.fieldErrorText {
  font-size: 0.625rem;
  font-family: 'Montserrat', sans-serif;
  color: #ff0080;
  text-transform: uppercase;
  padding-left: 12px;
}

.categorySelection {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 22px;
  color: #777777;
}

/* Hide the browser's default radio button */
.categorySelection input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  margin: 0;
  z-index: 1;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.categorySelection:hover input ~ .checkmark {
  border: 1px solid #ccc;
}

/* When the radio button is checked, add a blue background */
.categorySelection input:checked ~ .checkmark {
  background: $color-black;
  background-image: url('../../img/icons/check-mark.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.categorySelection input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.categorySelection .checkmark:after {
}

.radioCustomDiv {
  margin: 1rem 0;
  label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
    cursor: pointer;
    font-size: 13px;
    font-family: 'Barlow', sans-serif;
    position: relative;
  }
}
.radioCustom {
  opacity: 0;
  position: absolute;
  margin: 0;

  & + .radioCustomLabel:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 1.875rem;
    width: 1.6rem;
    margin-right: 1rem;
    height: 1.6rem;
    cursor: pointer;
  }
  &:checked + .radioCustomLabel:before {
    content: '';
    background: $color-black;
    background-image: url('../../img/icons/check-mark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px;
    @media screen and (max-width: $break-small) {
      background-size: 10px;
    }
  }
}
