@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 24rem;
  @media screen and (max-width: $break-small) {
    background: $color-white;
    min-height: auto;
    justify-content: center;
  }
  .text {
    font-size: 1.125rem;
    line-height: 2.3125rem;
    color: $color-gray-light4;
    min-height: 8.0625rem;
    width: 66.25rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    margin: 0 auto;
    @media screen and (max-width: $break-small) {
      padding: 1.5rem;
      border: none;
      font-size: 0.875rem;
      line-height: 1.25rem;
      width: 20.9375rem;
    }
  }
}
