@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.box-name {
  padding-top: 0.5rem;
  margin: 0 -0.977rem;
  text-align: center;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1875rem;
  line-height: 2.8125rem;
  @media screen and (max-width: $break-small) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.box {
  width: 3.125rem;
  height: 3.125rem;
  padding: 0.2rem;
  border-radius: 50%;
  margin: 0 auto;
  @media screen and (min-width: $break-small) {
    height: 6.875rem;
    width: 6.875rem;
    font-size: 1.2rem;
    padding: 0.5rem;
  }
}

.box-active {
  @extend .box;
  border: 2px solid $color-black;
}

.gray {
  background: $color-gray-light5;
}
