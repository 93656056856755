@import '../../../styles/_breaks.scss';
@import '../../../styles/_colors.scss';

.card-input {
  border-radius: 1.625rem;
  height: 3.25rem;
  border: 1px solid $color-gray-light2;
  padding: 2px 15px;
  margin: 0;
  font-size: 1.3125rem;
  &:focus {
    outline-width: 0;
  }
  @media screen and (max-width: $break-small) {
    height: 2.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  &input {
    line-height: 0px;
    color: red;
  }
}
.btnDiv {
  max-width: 700px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: $break-small) {
    max-width: 300px;
    margin: 5px;
  }
  .appointmentBtn {
    font-size: 14px;
    @media screen and (max-width: $break-small) {
      margin: 0;
      border-radius: 30px;
      padding: 8px 18px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}
.backBtn {
  display: flex;
  justify-content: flex-end;
}
.cancelDiv {
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.stepHead {
  color: $color-blue-sky;
}
.root {
  max-width: 1100px;
  margin: auto;
  padding-top: 20px;
  padding: 0 15px;
  font-size: 13px;
  font-family: Montserrat;

  @media screen and (max-width: $break-small) {
    padding: 20px 20px;
  }
  .contentDiv {
    min-height: 600px;
  }
  .title,
  .schedule-header,
  .form {
    max-width: 680px;
    margin: 0 auto;
  }

  & label,
  .radio-label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }

  & h3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  .title {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin: 30px auto 20px;
  }

  .schedule-header,
  .header-left,
  .header-right {
    display: flex;
    align-items: center;
    @media screen and (max-width: $break-small) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .header-left {
    @media screen and (max-width: $break-small) {
      display: flex;
      flex-flow: row;
    }
    .schedule-user {
      float: left;
      width: 46px;
      height: 46px;
      border-radius: 50px;
      overflow: hidden;
      background-color: #eee;
    }
    h3 {
      font-size: 16px;
      margin: 0 0 0 20px;
      @media screen and (max-width: $break-small) {
        padding-top: 10px;
      }
    }
  }
  .header-right {
    margin-left: auto;
    @media screen and (max-width: $break-small) {
      display: flex;
      flex-direction: row;
      margin-left: 0;
      align-items: center;
    }
    .schedule-content {
      position: relative;
      padding-left: 30px;
      font-family: Montserrat;
      line-height: 15px;
      @media screen and (max-width: $break-small) {
        padding-left: 0px;
      }
      h3 {
        font-size: 15px;
        margin: 0 0 0 20px;
        letter-spacing: 0;
        @media screen and (max-width: $break-small) {
          padding-top: 10px;
          margin: 0 0 0 0px;
        }
      }
      h6 {
        font-size: 12px;
        margin: 0 0 0 20px;
        letter-spacing: 0;
        @media screen and (max-width: $break-small) {
          padding-top: 10px;
          margin: 0 0 0 0px;
        }
      }
    }
  }
  .continer {
    margin-top: 10px;
    @media screen and (min-width: 768px) {
      display: flex;
      text-align: center;
      padding-bottom: 24px;
    }
  }
  hr {
    margin: 30px 0 20px 0;
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #d8d8d8;
  }
  .radioBtn {
    * {
      height: auto;
    }
    > div {
      justify-content: normal;
      flex-wrap: wrap;
      @media screen and (max-width: $break-small) {
        justify-content: inherit;
        flex-wrap: inherit;
      }
    }
    label {
      margin: 0 10px 0 0;
      float: left;
      span {
        width: 20px;
        height: 20px;
        background-size: 9px;
      }
    }
  }
  .radio-buttons {
    margin-right: 30px;
    @media screen and (max-width: $break-small) {
      margin-right: 6px;
      font-size: 12px;
    }
  }
  .actions-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    & > * {
      text-align: center;
    }
    button {
      margin-left: 20px;
      border-radius: 5rem;
      @media screen and (max-width: $break-small) {
        margin-left: 10px;
        border-radius: 30px;
        padding: 8px 18px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
  .error-container {
    width: 100%;
    margin-left: 1rem;
    .error {
      position: relative;
      top: 3px;
      font-size: 14px;
      color: red;
    }
  }
  .modal {
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
    position: fixed;
    max-height: 451px;
    max-width: 1000px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    border: 1px solid #000000;
    border-radius: 10px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: 7rem;
    background-position: 33rem 4.5rem;
    @media screen and (max-width: $break-small) {
      max-width: 90%;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px;
      font-family: Montserrat Light;
      img {
        height: 61px;
        width: 54px;
        position: relative;
        left: 7px;
      }
      .icon {
        height: 76px;
        width: 70px;
        padding: 0 0 20px 0;
        display: inline-block;
        background-image: url('../../../img/OrbMain.svg');
        background-repeat: no-repeat;
      }
      p {
        font-family: Barlow;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        text-align: center;
      }
      .hr {
        margin: 20px 0 20px 0;
        box-sizing: border-box;
        height: 1px;
        border: 1px solid #d8d8d8;
        width: 54%;
      }
      .meeting-section {
        text-align: left;
      }
      .meeting-details {
        display: flex;
        align-items: flex-start !important;
        text-align: left;
        flex-direction: row;
        p {
          margin-left: 10px;
        }
      }
      .dot {
        height: 22px;
        width: 22px;
        background-color: #a2a3eb;
        border-radius: 50%;
        display: inline-block;
      }
      .meeting-duration {
        font-size: 18px;
        font-weight: bold;
      }
      .meeting-icon {
        height: 25px;
        width: 25px;
        left: 0px;
      }
      .meeting-timing {
        font-size: 16px;
        color: #5fc6b3;
        font-weight: bold;
      }
      .meeting-timezone {
        font-size: 16px;
        font-weight: bold;
      }
      .invitation-message {
        margin-top: 10px;
        padding-left: 24px;
        font-weight: bold;
        color: #4c4f55;
      }
    }
    .primaryButton {
      background-color: $color-black;
      color: $color-white;
      font-weight: bold;
      font-size: 12px;
    }
  }
  .cards {
    margin-bottom: 2.5rem;

    .cardItem {
      display: flex;
      border-radius: 1.625rem;
      height: 3.25rem;
      border: 1px solid $color-gray-light2;
      padding: 0 1.625rem;
      margin: 12px 0 8px;
      margin-left: 35px;

      @media screen and (max-width: $break-small) {
        margin: 10px 0 0 28px;
        height: 2.6rem;
        position: relative;
        p {
          padding-top: 0;
          margin-top: 0;
          font-size: 12px;
          text-overflow: ellipsis;
        }
      }

      p {
        padding-top: 0.6rem;
        width: 40%;
      }
      span {
        margin-right: 30px;
        background: url('../../../img/cards.png') no-repeat 0 0;
        background-size: auto 26px;
        width: 50px;
        display: inline-block;
        margin-top: 5px;
        @media screen and (max-width: $break-small) {
          margin-left: -12px;
          margin-right: 15px;
        }
        &.mastercard {
          background-position: -45px 0;
        }
        &.american_express {
          background-position: -235px 0;
        }
        &.jcb {
          background-position: -188px 0;
        }
        &.discover {
          background-position: -92px 0;
        }
        &.discover_diners {
          background-position: -141px 0;
        }
      }
    }
  }
  .card-radio {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    &:before {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      border: 1px solid #000;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: 10px;
      text-align: center;
      color: #000;
      background-size: 9px;
    }
  }
  .card-radio-default {
    &:before {
      content: '';
    }
  }
  .card-radio-checked {
    &:before {
      content: '';
      background: $color-black;
      background-image: url('../../../img/icons/check-mark.svg');
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      background-size: 9px;
      background-position: center;
    }
  }
  .formGrid {
    border-radius: 23px;
    border: 1px solid #d8d8d8;
    margin-left: 35px;
    @media screen and (max-width: $break-small) {
      display: flex;
      flex-direction: column;
    }
  }
  .newCard {
    margin-left: 35px;
  }
  .backButton {
    background-color: $color-black;
    color: $color-white;
    font-weight: bold;
    font-size: 14px;
    @media screen and (max-width: $break-small) {
      font-size: 10px;
    }
  }
}

.cell {
  max-width: 610px;
  width: 100%;
  margin-bottom: 20px;
  @media screen and (max-width: $break-small) {
    max-width: 355px;
    width: 100%;
  }
}

.cell-md-6 {
  margin-left: -0.9375rem;
  margin-right: 0.938rem;
  width: 50%;
  margin-top: -1.7rem;
}

.cell-sm-6 {
  margin-top: -1.7rem;
  @media screen and (max-width: $break-small) {
    margin-top: -1rem;
    margin-left: -0.9375rem;
    margin-right: 0.938rem;
    width: 100%;
  }
}

.payment {
  padding: 5px 10px 5px 10px;
}
.paymentInput {
  padding: 0px 10px 5px 10px;
  width: 50%;
}
.checkboxBtn {
  div {
    margin: 0;
  }
}
.rememberMe {
  padding: 0;
}
.calendlyBox {
  width: 100%;
  display: flex;
  max-width: 700px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: $break-small) {
    margin-bottom: 28px;
    box-sizing: border-box;
    border: 1px solid #ececed;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(225, 225, 225, 0.5);
  }
}

.intakeFormText {
  margin-top: 28px;
  max-width: 700px;
  margin: 0 auto 15px;
  display: block;
  color: #000000;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 41px;
  @media screen and (max-width: 1000px) {
    max-width: 680px;
  }

  @media screen and (max-width: $break-small) {
    margin-left: 5px;
    font-size: 15px;
    line-height: 21px;
  }
}
.progressBar {
  margin: 10px 0 20px 0;
  display: flex;
  justify-content: center;
}
.progressHead {
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: $break-small) {
    justify-content: space-around;
  }
  p {
    margin: 10px 0 0 0;
    font-weight: 600;
  }
}
.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}
.toastDiv {
  color: black;
  font-weight: 600;
}
.contact-container {
  max-width: 700px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}
.resetmodal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 340px;
  max-width: 332px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5rem;
    @media screen and (max-width: $break-small) {
      padding: 4rem 2rem;
    }
    h1 {
      font-size: 20px;
      line-height: 1.875rem;
      text-align: center;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }
  }
  .box {
    p {
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
}
.textInput {
  input {
    @media screen and (max-width: $break-small) {
      height: 2rem;
      min-height: 38px;
      font-size: 12px;
      max-height: 40px;
    }
  }
}
