@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/colors';

.root {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 30.5px 20px 20px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f0f2f8;
  background-color: $color-white;

  .superbillButtons {
    display: flex;
  }

  .meeting-details {
    .profileDiv {
      display: flex;
      align-items: center;

      > img {
        height: 40px;
        max-width: 40px;
        margin-bottom: 12px;
        margin-top: -25px;
        border-radius: 20px;

        @media screen and (max-width: $break-small) {
          margin-top: 0px;
        }
      }

      .timeDiv {
        margin-left: 20px;
      }
    }

    .meeting-time {
      display: flex;
      align-items: flex-start;
      margin: 0 58px;

      @media screen and (max-width: $break-small) {
        margin: 0px 0px 12px 0px;
      }

      p {
        margin: 0 0 0 10px;
        font-family: Montserrat;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: bold;
        color: #2f2e50;

        @media screen and (max-width: $break-small) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      img {
        padding-top: 3px;
      }
    }

    .title {
      display: flex;
      font-family: Montserrat;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: bold;
      padding-bottom: 10px;
      color: #2f2e50;
    }

    .text {
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: $break-small) {
        display: inline-block;
        width: 100%;
      }

      .type,
      .location {
        font-family: Montserrat;
        letter-spacing: 0.75px;
        line-height: 24px;
        margin-right: 60px;

        @media screen and (max-width: $break-small) {
          font-family: Montserrat;
          letter-spacing: 0.75px;
          line-height: 24px;
          float: left;
          width: 50%;
          padding-right: 10px;
          margin: 0px;
        }

        span {
          font-size: 12px;
          color: #2f2e50;
          display: inline-block;
        }

        p {
          font-size: 14px;
          color: #2f2e50;
          @media screen and (max-width: $break-small) {
            margin-bottom: 0.5rem;
          }
        }
        .status {
          text-transform: capitalize;
          padding-left: 3px;
        }
        .feeamount {
          padding: 0px 3px;
        }
      }

      .reason {
        max-width: 180px;
        width: 100%;

        @media screen and (max-width: $break-small) {
          width: 100%;
        }
      }
    }
  }

  .imgBox {
    min-width: 80px;
    height: 109px;
    overflow: hidden;
    border-radius: 30px;
    width: 80px;
    float: left;

    @media screen and (max-width: $break-small) {
      min-width: 101px;
      border-radius: 30px;
      margin-top: 20px;
      margin-bottom: 10px;
      margin-right: 0px;
      width: 80px;
    }

    img {
      object-fit: cover;
      height: 100%;

      @media screen and (max-width: $break-small) {
        border-radius: 30px;
      }
    }
  }

  .rightContent {
    float: right;

    .fees {
      text-align: right;
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }

  .buttons {
    margin-left: auto;
    @media screen and (max-width: $break-small) {
      margin-top: 10px;
      margin-bottom: 0px;
      display: flex;
    }

    img {
      margin-right: 20px;
      vertical-align: middle;
      padding-top: 10px;
      cursor: pointer;
    }

    @media screen and (max-width: $break-small) {
      overflow: hidden;
      clear: both;
      float: right;
    }

    .superbillButton {
      color: $color-white;
      min-height: auto;
      border: 2px solid #43425d;
    }

    button {
      margin-right: 15px;

      @media screen and (max-width: $break-small) {
        display: inline-block;
        float: right;
      }
    }
  }
}

.typeDiv {
  max-width: 40px;
  width: 100%;
  @media screen and (max-width: $break-small) {
    max-width: 100px;
  }
}

.mobileDiv {
  max-width: 90px;
  width: 100%;
  @media screen and (max-width: $break-small) {
    max-width: 100px;
  }
}

.assessmentDiv {
  max-width: 80px;
  width: 100%;
}

.superbillDiv {
  max-width: 180px;
  width: 100%;
}

.seeProfile {
  margin-left: 20px;

  span {
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
  }
}
