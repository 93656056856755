@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.mainContainer {
  font-family: 'Montserrat';
  font-size: 15px;
  color: $color-gray-dark;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  background: $color-white;
  min-width: 720px;
  z-index: 2;
  opacity: 1;
  overflow-y: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 20px;
  margin: 0;

  &.errorModal {
    min-width: auto;
  }

  .modalTitle {
    font-size: 35px;
    font-weight: bold;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &.requestAgainButtonsWrapper {
      justify-content: space-between;
    }

    button {
      padding: 10px;
      min-width: 100px;
      font-size: 15px;
      margin-bottom: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .container {
      display: block;
      text-align: center;
      position: relative;
      max-width: 360px;

      .warningImg {
        height: auto;
        width: 90px;
        margin: 0 auto 20px;
      }

      .text {
        margin: 10px 0px;
      }
    }
  }
}
