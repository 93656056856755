@import '../../../../styles/_colors.scss';

.error-container {
  padding: 0 !important;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.67;
  color: $color-black;
}
