@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.box-container {
  cursor: pointer;
  box-sizing: border-box;
  max-width: 234px;
  width: 100%;
  border: 2px solid #ececec;
  border-radius: 8px;
  background-color: #f6f6f6;
  padding: 0.125rem;
  margin: 0.5rem 0;
  outline: none;

  @media screen and (max-width: $break-small) {
    width: 100%;
    display: flex;
    max-width: 100%;
    margin: 0px 0 10px 0;
    &:not(:last-child) {
      margin-right: 0px;
    }
  }
  & > div {
    align-items: center;
  }

  &:not(:last-child) {
    margin-right: 22px;
    @media screen and (max-width: $break-small) {
      margin-right: 0px;
    }
  }
  .div-img {
    display: flex;
    margin: 30px 0px 20px 0px;
    min-height: 115px;
    @media screen and (max-width: $break-small) {
      margin: 10px 0px 10px 0px;
      min-height: 10px;
    }
  }
  img {
    margin: 0 auto;
    background-color: transparent;
    @media screen and (max-width: $break-small) {
      width: 30px;
      height: 30px;
      padding: 0px;
      margin: 0px 15px;
    }
  }
  &.is-selected {
    border: solid 2px $color-black;
    border-radius: 8px;
    .button-container img {
      background-color: transparent;
    }
    @media screen and (min-width: $break-small) {
      background-color: #f6f6f6;
      color: $color-white;
      border-radius: 0.4375rem;
      .button-container img {
        background-color: transparent;
      }
    }
  }
  .circle {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    @media screen and (max-width: $break-small) {
      padding-bottom: 10px;
      padding-top: 10px;
      padding-right: 15px;
    }
  }
  .circle-small {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $color-black;
    &.is-selected {
      border: 6px solid $color-black;
      &:after {
        content: '';
        background: red;
      }
    }
    @media screen and (max-width: $break-small) {
      width: 20px;
      height: 20px;
      border: 2px solid $color-black;
      margin-right: 0px;
    }
  }
}

.text-container {
  text-align: left;
  font-weight: bold;
  @media screen and (min-width: $break-small) {
    text-align: center;
  }
}

.button-title {
  font-family: 'Montserrat', sans-serif;
  color: $color-black;
  font-size: 18px;
  padding: 10px 0px;
  @media screen and (max-width: $break-small) {
    max-width: inherit;
    font-size: 16px;
    padding: 13px 0px;
  }
}

.subtitle {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-family: 'Roboto Mono', monospace;

  max-width: 10.375rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $break-small) {
    max-width: inherit;
  }
}
