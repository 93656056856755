@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
}
.leftnav {
  padding: 0px !important;
}
.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  h1 {
    color: $color_black;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #b4b4c6;
  flex-wrap: wrap;
  margin: 20px 0px;

  .tab {
    background-color: unset;
    padding-left: unset;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: #b4b4c6;
    margin-right: 25px;
    cursor: pointer;
    letter-spacing: 1px;
    position: relative;
    span {
      padding-bottom: 5px;
      display: inline-block;
    }

    div {
      padding-bottom: 1px;
    }
    &.activeTab {
      > span {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: normal;
        color: #43425d;
      }
      > div {
        text-align: center;
        background-color: #808bff;
        position: relative;
      }
    }
  }
}
.nodata {
  font-size: 16px;
  color: $color_black;
}
