@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.container {
  display: flex;

  &.superBill {
    background-image: linear-gradient(to bottom, #f0f2f8, #fff);
  }
}
.superBillWrapper {
  list-style: none;
  margin: 0 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  @media screen and (max-width: $break-medium) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $break-small) {
    grid-template-columns: 1fr;
  }
}
.leftnav {
  padding: 0px !important;
}
.content {
  overflow: hidden;
  padding: 20px;
  width: 100%;
  @media screen and (max-width: $break-small) {
    padding: 10px;
    .header {
      color: #000000;
      font-family: Montserrat;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 42px;
      text-align: left;
      padding: 0;
      margin: 10px 0;
    }
  }
  .morebutton {
    text-align: center;
    margin: 10px 0px;
  }
}
.stepsCard {
  margin-bottom: 30px;

  @media screen and (max-width: $break-small) {
    display: flex;
    overflow: auto;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
}

.info {
  padding-top: 40px;
  h3 {
    color: #000000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  h1 {
    color: #000000;
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .text {
    max-width: 489px;
    color: #000;
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 42px;
  }
}

.modal {
  position: fixed;
  max-height: 33.8125rem;
  max-width: 25.75rem;
  margin: auto;
  text-align: center;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  border: 1px solid #000000;
  border-radius: 10px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    @media screen and (max-width: $break-small) {
      padding: 4rem 2rem;
    }
    h1 {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
    p {
      font-size: 0.85rem;
      line-height: 1.6875rem;
      font-weight: 400;
      font-family: Montserrat;
    }
    .btn {
      font-family: Montserrat;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0;
    }
  }
}
