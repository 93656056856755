@import '../../../styles/_colors.scss';

.icon-container {
  display: flex;
}

.sidebar {
  background: $color-white;
  position: fixed !important;
  width: 100% !important;
  background-image: linear-gradient(
    180deg,
    $color-blue-mobile-menu,
    $color-purple-mobile-menu,
    $color-orange-mobile-menu
  );
}

.root {
  position: relative !important;
  width: 1rem;
  height: 1.5rem;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
}
.center {
  display: flex;
}
