@import '../../styles/_breaks.scss';

.centered {
  display: flex;
  justify-content: center;
  flex-direction: column;

  img {
    width: 32.625rem;
    @media screen and (max-width: $break-small) {
      width: 13.8125rem;
      margin: auto;
    }
  }

  h1 {
    text-align: center;
    line-height: 7.25rem;
    font-size: 3.3125rem;
    @media screen and (max-width: $break-small) {
      line-height: 5.0625rem;
      font-size: 2.3125rem;
    }
  }

  p {
    margin: auto;
    text-align: center;
    font-size: 1.3125rem;
    line-height: 2.875rem;
    width: 25.625rem;

    @media screen and (max-width: $break-small) {
      max-width: 18rem;
      font-size: 0.9375rem;
      line-height: 2.0625rem;
      width: 18rem;
    }
  }

  .margin-bottom {
    margin-bottom: 2rem;
  }
}

.center-link {
  display: flex;
  justify-content: space-evenly;
}

.link {
  text-decoration: underline;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.75rem;
  line-height: 3.875rem;
  @media screen and (max-width: $break-small) {
    font-size: 1.25rem;
    line-height: 2.75rem;
  }
}

img.oro-error-img {
  margin-left: auto;
}
