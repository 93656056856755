@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/colors';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33.33%;
  padding: 10px;
  @media screen and (max-width: $break-medium) {
    width: 50%;
    padding: 10px;
  }
  @media screen and (max-width: $break-small) {
    width: 100%;
    padding: 0px;
  }

  .patientBox {
    display: flex;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #f0f2f8;
    background-color: $color-white;
    align-items: center;
    padding: 15px;
  }

  .profilePic {
    object-fit: contain;
    margin-right: 15px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
  .detail {
    max-width: 140px;
    h4 {
      line-height: 1.3;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      color: #2f2e50;
      margin: 0px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    button {
      width: 100%;
      margin: 10px 0 0 0.4px;
      object-fit: contain;
      border: solid 2px #43425d;
    }
  }
  .schedule {
    background-color: #43425d;
    color: $color-white;
  }

  .returning {
    color: #ffa177;
    font-size: 12px;
  }
  .new {
    color: #19913d;
    font-size: 12px;
  }
}
