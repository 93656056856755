@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.container {
  display: flex;
}
.leftnav {
  padding: 0px !important;
}
.root {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  max-width: 1088px;
  width: 100%;
  font-family: Montserrat Light;
  .locationDiv {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    img {
      width: 13px;
      height: 18px;
    }
    p {
      margin: 0 0 0px 10px;
    }
  }
  h1 {
    color: $color_black;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    @media screen and (max-width: $break-small) {
      margin: 0px;
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      text-align: left;
    }
  }
  .profileDiv {
    max-width: 1088px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #f0f2f8;
    background-color: #ffffff;
    margin: 30px 0;

    .detailsDiv {
      padding: 30px 20px;
      border-bottom: solid 1px #e9e9f0;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: $break-small) {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
      }
      .clinicianHead {
        display: flex;
        @media screen and (max-width: $break-small) {
          flex-direction: column;
          align-items: center;
        }
      }
    }
    .innerDiv {
      display: flex;
      flex-wrap: wrap;
    }
    .infoDiv {
      padding: 20px 20px;
      border-bottom: solid 1px #e9e9f0;
      display: flex;

      @media screen and (max-width: $break-small) {
        flex-direction: column;
        flex-wrap: wrap;
      }

      .textBackground {
        padding: 8px 19px 7px;
        border-radius: 100px;
        background-color: #e9e9f0;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .fieldHead {
        width: 170px;
      }
      .stateAndProvider {
        width: 100%;
      }
      .stateAndProviderHeaders {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
      .stateAndProviderRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        border-bottom: solid 1px #e9e9f0;
        padding: 5px 0px;
      }
      .stateAndProviderRow .fieldValue {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        width: 260px;
        margin-right: 15px;
        letter-spacing: normal;
        color: #43425d;
        word-break: break-word;
      }
    }
    .fieldTitle {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
      text-align: left;
      width: 260px;
      margin-right: 15px;
      color: #2f2e50;
      @media screen and (max-width: $break-small) {
        width: auto;
        margin: 0 0 10px 0;
      }
    }
    .fieldValue {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #43425d;
      word-break: break-word;
    }
    .profilePic {
      min-width: 80px;
      width: 80px;
      height: 80px;
      object-fit: contain;

      img {
        border-radius: 50%;
      }
    }
  }
  .clinicianTitle {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 30px;

    @media screen and (max-width: $break-small) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px 0 0 0px;
      p {
        text-align: center;
      }
    }
    .certification {
      margin-bottom: 10px;
      span {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #43425d;
      }
    }
  }
  .licenseDiv {
    display: flex;
    max-width: 360px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #bcbccb;
    background-color: #ffffff;
    .stateDiv {
      width: 50%;
      padding: 10px;
      text-align: left;
      border-right: 1px solid #bcbccb;
    }
    h6 {
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 30px;
      letter-spacing: normal;
      color: #2f2e50;
    }
    .licenseNumberDiv {
      width: 50%;
      padding: 10px;
      text-align: left;
    }

    .licenseDetails {
      display: flex;
    }
  }
  .unLicenseDiv {
    max-width: 360px;
    width: 100%;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: solid 1px #bcbccb;
    background-color: #ffffff;
    p {
      margin: 0;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
      text-align: center;
      color: #2f2e50;
    }
  }
  p {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #43425d;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .backDiv {
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    h1 {
      margin: 0px;
      padding: 0px;
    }
  }
  .button {
    margin: 0px;
    font-size: 12px;
    padding: 12px 20px;
    border-radius: 100px;
    @media screen and (max-width: $break-small) {
      padding: 10px;
    }
  }
}
