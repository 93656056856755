@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.container {
  display: flex;
  cursor: pointer;
  background: $color-white;
  align-items: center;
  > :first-child {
    margin-right: 1.2rem;
  }
}

.box-name {
  font-size: 1.2rem;
  line-height: 2.8125rem;
  margin-bottom: 0.3rem;
  @media screen and (max-width: $break-small) {
    font-size: 0.9375rem;
    font-weight: 400;
    padding: 15px 12px 12px 0px;
    line-height: 20px;
  }
}

.box {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  @media screen and (max-width: $break-small) {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.box-active {
  @extend .box;
  background: $color-black;
  &:after {
    content: '';
    background-image: url('../../../../img/icons/check-mark.svg');
    background-repeat: no-repeat;
    min-height: 10.11px;
    max-width: 13px;
    position: absolute;
    margin: 4px 10px 10px 2px;
    background-size: 14px;
    width: 100%;
    @media screen and (max-width: $break-small) {
      max-width: 12px;
      margin: 3px 10px 10px 2px;
    }
  }
}
