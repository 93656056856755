.container {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.card {
  align-self: center;
  justify-self: center;
}
.subtitle {
  color: #000000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
