.myswitch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 53px;
  height: 25px;
  padding: 3px 3px 4px;
  border-radius: 18px;
  cursor: pointer;
  box-sizing: content-box;
}
.myswitch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;

  &[disabled] {
    pointer-events: none;
    opacity: 0;

    &+span.myswitch-label {

      &:after {
        background: #b0b1b3!important;
      }
    }
  }

  &:checked {
    ~ {
      .myswitch-label {
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
      .myswitch-handle {
        left: 35px;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
.myswitch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #b0b1b3;
  border-radius: inherit;
  box-sizing: content-box;
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
  &:before {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
    content: attr(data-off);
    right: 11px;
    color: #ffffff;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  }
  &:after {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
    content: attr(data-on);
    left: 11px;
    color: #ffffff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }
}
.myswitch-handle {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 17px;
  height: 17px;
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  border-radius: 100%;
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.myswitch-left-right {
  .myswitch-label {
    overflow: hidden;
    &:before {
      width: 18px;
      height: 18px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 11px 0 0 0;
      text-indent: -12px;
      border-radius: 20px;
      background: #b0b1b3;
      text-align: left;
      padding-left: 40px;
    }
    &:after {
      width: 18px;
      height: 18px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 11px 0 0 0;
      text-indent: -12px;
      border-radius: 20px;
      text-align: left;
      text-indent: 9px;
      background: #19913d;
      left: -60px;
      opacity: 1;
      width: 100%;
    }
  }
  .myswitch-input {
    &:checked {
      ~ {
        .myswitch-label {
          &:before {
            opacity: 1;
            left: 50px;
          }
          &:after {
            left: 0;
          }
          background: inherit;
        }
      }
    }
  }
}
