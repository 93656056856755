@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
}
.leftnav {
  padding: 0px !important;
}
.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  h1 {
    color: $color_black;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
  .buttons {
    text-align: center;
    margin-top: 32px;

    .btn {
      box-sizing: border-box;
      border: 2px solid #000000;
      border-radius: 30px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      padding: 1rem 1.75rem;
      padding: 6px 19px;
    }
  }
}
.header {
  @media screen and (min-width: $break-small) {
    display: flex;
  }
}
.daterange {
  border-radius: 1.625rem;
  border: 1px solid #d8d8d8;
  padding: 8px 50px 8px 15px;
  margin: 0;
  position: relative;
  border-color: hsl(0, 0%, 80%);
  @media screen and (max-width: $break-small) {
    min-width: 200px;
  }
  img {
    position: absolute;
    top: 10px;
    bottom: 0;
    right: 0px;
    margin-right: 15px;
  }
  .closeIcon {
    height: 16px;
    width: 12px;
    margin-right: 10px;
  }
}
.listContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.filters {
  display: flex;
  align-items: center;
  margin-left: auto;
  @media screen and (max-width: $break-small) {
    flex-wrap: wrap;
  }
  .sortByfilter {
    min-width: 200px;
    margin-left: 10px;
    @media screen and (max-width: $break-small) {
      margin: 10px 0px;
      width: 100% !important;
    }
  }
  .searchfilter {
    min-width: 200px;
    @media screen and (max-width: $break-small) {
      margin: 10px 0px;
      width: 100% !important;
    }
  }
  .searchFilter {
    display: flex;
    align-items: center;
    width: 300px;
  }
  .to-label {
    margin-left: 5px;
  }
  label {
    white-space: nowrap;
    margin-right: 5px;
    color: #000000;
    font-family: Barlow;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    @media screen and (max-width: $break-small) {
      margin-bottom: 5px;
    }
  }
}
