@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.quiz-container-bg {
  width: 100%;
  background-color: $color-white;
  position: relative;
  min-height: 320px;
  min-width: 290px;
  overflow: hidden;

  .lineArt {
    opacity: 0.3;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    width: 90%;
    position: absolute;
    height: 90%;
    display: flex;
    img {
      width: 100%;
      max-height: 100%;
    }
    @media screen and (max-width: $break-small) {
      width: 575px;
    }
  }
  .content {
    max-width: 920px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 30px 15px 100px;
    margin: 0 auto;
    min-height: calc(100vh - 7.5rem);
    @media screen and (max-width: 810px) {
      max-width: 670px;
    }
    @media screen and (max-width: $break-small) {
      min-height: calc(100vh - 120px);
      padding: 60px 15px 100px;
      max-width: 530px;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      padding: 60px 15px 50px;
    }
  }

  .modal-content {
    max-width: 920px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 30px 15px 100px;
    margin: 0 auto;
    min-height: calc(100vh - 7.5rem);
    @media screen and (max-width: 810px) {
      max-width: 670px;
    }
    @media screen and (max-width: $break-small) {
      min-height: calc(100vh - 120px);
      padding: 60px 15px 100px;
      max-width: 460px;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      padding: 60px 15px 50px;
    }
  }
  h1 {
    padding: 0 0 0 0;
    color: $color-black;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 52px;
    text-align: center;
    margin-bottom: 0px;
    @media screen and (max-width: $break-small) {
      font-size: 28px;
      line-height: 42px;
    }
  }
  h2 {
    font-size: 30px;
    padding: 0 0 0 0;
    color: $color-black;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    margin-bottom: 0px;
    @media screen and (max-width: $break-small) {
      font-size: 22px;
      line-height: 32px;
      white-space: break-spaces;
    }
  }
  p {
    margin-bottom: 0px;
  }
  .sub-title {
    max-width: 904px;
    color: $color-black;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    padding: 40px 0 0 0;
    @media screen and (max-width: $break-small) {
      font-size: 22px;
      padding: 20px 0px;
      line-height: 32px;
      white-space: break-spaces;
    }
  }
  .sub-text {
    padding: 10px 0 0 0;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    @media screen and (max-width: $break-small) {
      padding: 6px 0 0 0;
    }
  }
  .form-div {
    width: 100%;
    position: relative;

    > div input {
      height: 43px;
      font-size: 16px;
      margin: auto;
      max-width: 402px;
      line-height: 1;
      border-radius: 50px;
      padding: 0 20px;
    }
    .button-row {
      padding-top: 50px;
      @media screen and (max-width: $break-small) {
        padding-top: 10px;
      }
    }

    .icon {
      img {
        height: 61px;
        width: 54px;
        position: relative;
        bottom: 20%;
        left: 10%;
      }
    }
    .Hi {
      img {
        height: 61px;
        width: 54px;
        position: relative;
        bottom: 20%;
        left: 10%;
        animation: shake 3s;
      }
    }
    @keyframes wave {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }
      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }
      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }
      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }

    .meet,
    .icon {
      img {
        height: 64px;
        width: 81px;
        position: relative;
        bottom: 20%;
        left: 5%;
      }
    }
  }
  .icon {
    height: 76px;
    width: 70px;
    padding: 0 0 20px 0;
    display: inline-block;
    background-image: url('../../img/OrbMain.svg');
    background-repeat: no-repeat;
  }
  .button {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0px auto;
    display: block;
    min-width: 112px;
    &[disabled] {
      opacity: 1 !important;
      background-color: #dddddd !important;
    }
  }
}

.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  @media screen and (min-width: $break-small) {
    margin-top: -$header-height-desktop;
  }
  @media screen and (max-width: $break-small) {
    background-size: 125% auto;
    min-height: 36.625rem;
    justify-content: start;
    margin-top: 1rem;
  }
  h2 {
    font-size: 2.375rem;
    white-space: pre-wrap;
  }
  h3 {
    font-size: 2.5rem;
    font-weight: bold;
    @media screen and (max-width: $break-small) {
      padding-top: 1.5625rem;
      font-size: 1.5625rem;
    }
  }
  p {
    font-family: 'Roboto Mono', monospace;
    font-size: 1rem;
    font-weight: normal;
  }
  .transition-container {
    @media screen and (max-width: $break-small) {
      margin-top: 7rem;
    }
  }
  .transition-subtitle {
    max-width: 21.25rem;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: $break-small) {
      max-width: 16rem;
    }
  }
  form {
    margin: 0 auto;
    max-width: 62.5rem;
    & > div {
      margin: 0 auto;
      width: 100%;
    }
    @media screen and (max-width: $break-small) {
      padding: 0 1rem;
    }
    @media screen and (min-width: $break-medium) {
      margin-top: 6.25rem;
    }
  }
  .logo {
    top: 1.7rem;
    width: 100%;
    position: absolute;
    margin: 0 auto;
    img {
      width: 6.25rem;
    }
    @media screen and (max-width: $break-small) {
      top: 1rem;
    }
  }
}

.back-link {
  font-family: 'Roboto Mono', monospace;
  display: flex;
  position: absolute;
  left: 5rem;
  bottom: 5rem;
  cursor: pointer;
  span {
    font-size: 1.25rem;
    margin-left: 1rem;
  }
  @media screen and (max-width: $break-small) {
    margin: 1rem;
  }
}

form > div.back-link {
  width: 11.5rem;
  height: 6.25rem;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $break-small) {
    width: 4.375rem;
    height: 4.375rem;
    left: 0;
    top: 31rem;

    img {
      width: 0.9375rem;
    }
  }
}

div.actual-padding {
  @media screen and (max-width: $break-small) {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    button {
      margin: 0 auto;
    }
  }
}

.input {
  padding-top: 25px;
  margin: auto;
  max-width: 30.125rem;
  button[disabled],
  button[disabled]:focus,
  button[disabled]:hover {
    opacity: 1;
    background-color: $color-white !important;
  }
}

.input > input {
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  font-family: 'Roboto Mono', monospace;
  line-height: 2rem;
  @media screen and (max-width: $break-small) {
    font-size: 16px;
    height: 42px;
  }
}

.grid-center {
  justify-content: center;
  padding-top: 10px;
  @media screen and (max-width: $break-small) {
    & > div {
      width: 100% !important;
    }
  }
}
.grid-cell {
  padding-top: 10px;
  margin-left: 40px;
  @media screen and (max-width: $break-small) {
    margin-left: 0px;
  }
}

.slider-container {
}

div.multi-choice-small {
  padding: 0.5rem 1rem;
}

.back-button {
  font-size: 16px;
  line-height: 20px;
  color: $color-black;
  font-weight: bold;
  border-radius: 30px;
  line-height: 20px;
  border: 2px solid $color-black;
  padding: 9px 30px 9px 30px;
  display: block;
  min-width: 112px;
  background: $color-white;
  cursor: pointer;
}

.buttons-row {
  max-width: 998px;
  position: absolute;
  bottom: 40px;
  width: 100%;
  @media screen and (max-width: $break-small) {
    bottom: 50px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    position: relative;
    bottom: 0;
  }
  .buttons-row-right {
    float: right;
    right: 0;
    margin-right: 15px;
    @media screen and (max-width: $break-small) {
      margin-right: 0px;
    }
  }
  .buttons-row-left {
    float: left;
    left: 0;
    margin-left: 15px;
    @media screen and (max-width: $break-small) {
      margin-left: 0px;
    }

    .backButton {
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-black;
    }
  }
  @media screen and (max-width: $break-small) {
    justify-content: center;
    position: absolute;
    width: 100%;
    display: flex;
    bottom: 50px;
    .buttons-row-right {
      margin-left: 20px;
    }
  }
  /* Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    position: relative;
    bottom: 0;
    margin-top: 35px;
  }
}
.single-button-row {
  max-width: 998px;
  position: absolute;
  bottom: 40px;
  width: 100%;
  @media screen and (max-width: $break-small) {
    bottom: 60px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    position: relative;
    bottom: 0;
    margin: 20px 0;
  }
  .buttons-row-right {
    float: right;
    right: 0;
  }
  .buttons-row-left {
    float: left;
    left: 0;
    margin-left: 15px;
    @media screen and (max-width: $break-small) {
      margin-left: 0px;
    }
  }
  .back-button {
    background-color: $color-white;
    color: $color-black;
    border: 2px solid $color-black;
  }
  @media screen and (max-width: $break-small) {
    display: flex;
    justify-content: center;
  }
}

.expirience-boxes {
  margin: 20px 0px;
  justify-content: center;
  @media screen and (max-width: 810px) {
    > div {
      max-width: 320px;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 20px 0 0 0;
  }
}

.modal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 451px;
  max-width: 900px;
  width: calc(100% - 45px);
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  @media screen and (max-width: 812px) {
    max-width: 560px;
  }
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    @media screen and (max-width: $break-small) {
      padding: 50px 15px 40px 15px;
    }
    h1 {
      padding: 0 0 0 0;
      color: $color-black;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 52px;
      text-align: center;
      margin-bottom: 0px;
    }
    p {
      padding: 10px 0 0 0;
      color: $color-black;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
    .buttonRow {
      padding: 40px 0 0px 0;
      @media screen and (max-width: 812px) {
        padding: 15px 0 0px 0;
        margin-bottom: -10px;
      }
      @media screen and (max-width: $break-small) {
        padding: 30px 0 0px 0;
      }
    }
  }
  .swipeCardInfo {
    position: relative;
    top: 3%;
    bottom: auto;
    @media screen and (max-width: $break-small) {
      padding: 0 0 20px 0px;
    }
  }
}

.chatbot {
  background-repeat: no-repeat;
  position: absolute;
  bottom: 25px;
  right: 50px;
  @media screen and (max-width: $break-small) {
    right: 10px;
    bottom: 25px;
  }
  img {
    height: 50px;
    width: 50px;
  }
}
.flex-container {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  position: relative;
  z-index: 0;
  /* Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    > div {
      padding: 0;
      > span {
        margin: 0;
      }
    }
  }
}

.label {
  height: 24px;
  width: 22px;
  color: $color-black;
  font-family: Barlow;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;
}
.swipeCardContent {
  max-width: 920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 810px) {
    max-width: 670px;
  }
  @media screen and (max-width: $break-small) {
    max-width: 460px;
  }
}

.swipeCardInfo {
  position: absolute;
  bottom: 40px;
  @media screen and (max-width: $break-small) {
    bottom: 10px;
  }
}

.error {
  position: relative;
  top: 3px;
  font-size: 14px;
}

.loader,
.loader:before,
.loader:after {
  background: $color-black;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: $color-black;
  text-indent: -9999em;
  margin: 20px auto;
  position: relative;
  font-size: 5px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.spinner {
  margin: 10px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;

  > div {
    background-color: $color-black;
    margin: 0 3px 0 0;
    height: 100%;
    width: 4px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.header {
  position: absolute;
  top: 10px;
  width: 100%;

  .header-left {
    float: left;
    padding-left: 15px;
    & > span {
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 20px;
      text-align: center;
    }
  }
  .header-right {
    float: right;
    padding-right: 15px;
    padding-top: 10px;
  }
}

:global(.pac-container) {
  border-radius: 10px;
}
:global(.pac-container:after) {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}
