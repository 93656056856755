@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
  & label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }
  input {
    box-shadow: none;
    @media screen and (max-width: $break-small) {
      height: 39px;
      font-size: 12px;
      padding: 0 20px;
    }
  }
}

.leftNav {
  padding: 0px !important;
}

.closeAccountModal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 340px;
  max-width: 332px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5rem;
    @media screen and (max-width: $break-small) {
      padding: 4rem 2rem;
    }
    h1 {
      font-size: 20px;
      line-height: 1.875rem;
      text-align: center;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }
  }
  .box {
    p {
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
}

.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  h1 {
    color: $color_black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    @media screen and (max-width: $break-small) {
      margin-left: -5px;
      font-size: 14px;
    }
  }
  .profileGrid {
    .profilePic {
      align-items: center;
      margin-left: -40px;
      @media screen and (max-width: $break-small) {
        margin-left: 0px;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100px;
        height: 100px;
        border-radius: 50px;
      }
      button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        min-width: 172px;
      }
      .removeBtnCell {
        margin-top: -12px;
        padding: 0;
      }
    }
    .info {
      margin-top: 12px;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-left: -20px;
      @media screen and (max-width: $break-small) {
        font-size: 14px;
      }
      .link {
        margin-top: 20px;
        cursor: pointer;
        text-decoration: underline;
        width: 180px;
      }
      .infoGrid {
        .lableCell {
          padding: 0;
          margin: 0;
          width: 22%;
          @media screen and (max-width: $break-small) {
            width: 40%;
          }
        }
        .valueCell {
          margin: 0;
          padding: 0;
        }
        .fullwidth {
          @media screen and (max-width: $break-small) {
            width: 100% !important;
          }
        }
      }
    }
    .email {
      word-break: break-all;
    }
  }
}

.modal {
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 500px;
  max-width: 700px;
  width: 100%;
  overflow: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 7rem;
  background-position: 33rem 4.5rem;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content {
    display: block;
    padding: 16px 18px;
  }
  .actions-container {
    display: flex;
    flex-direction: row;
    button {
      margin-right: 20px;
      border-radius: 5rem;
      @media screen and (max-width: $break-small) {
        padding: 0.6rem 0.7rem;
        font-size: 1rem;
      }
    }
  }
}
.profileModal {
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  min-height: 0px;
  max-height: 680px;
  max-width: 1001px;
  width: 100%;
  margin: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  margin-top: 2% !important;
  background-repeat: no-repeat;
  background-size: 7rem;
  background-position: 33rem 4.5rem;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content {
    text-align: center;
    display: block;
    padding: 16px 18px;
    h1 {
      font-family: Montserrat SemiBold;
      font-size: 22px;
      line-height: 32px;
      margin-top: 25px;
    }
    p {
      font-family: Barlow Regular;
      font-size: 16px;
      line-height: 24px;
    }
    .cropper {
      width: '100%';
      text-align: center;
      margin-top: 20px;
    }
  }
  .crop-action-grid {
    margin-left: 19%;
    margin-right: 19%;
    margin-top: -8px;
    margin-bottom: 3%;
    @media screen and (max-width: $break-small) {
      margin: 0;
    }
    .actions-container-left {
      text-align: left;
      float: left;
      flex-direction: row;
      button {
        border-radius: 5rem;
        font-size: 0.9rem;
        @media screen and (max-width: $break-small) {
          padding: 0.6rem 0.7rem;
          font-size: 0.6rem;
        }
      }
    }
    .actions-container-right {
      text-align: right;
      float: right;
      flex-direction: row;
      button {
        margin-left: 10px;
        border-radius: 5rem;
        font-size: 0.9rem;

        @media screen and (max-width: $break-small) {
          padding: 0.6rem 0.7rem;
          font-size: 0.6rem;
        }
      }
    }
  }
}
.showDialog {
  display: block;
}
.hideDialog {
  display: none;
}
.error {
  margin-left: 20px;
  color: red;
  font-size: 12px;
}
