@import '../../../styles/_breaks.scss';
@import '../../../styles/_colors.scss';

.root {
  max-width: 1100px;
  margin: auto;
  padding-top: 20px;
  padding: 0 15px;
  font-size: 13px;
  font-family: Montserrat;

  @media screen and (max-width: $break-small) {
    padding: 20px 10px;
  }
  .title,
  .schedule-header,
  .form {
    max-width: 680px;
    margin: 0 auto;
  }
  iframe {
    border: none;
    @media screen and (max-width: $break-small) {
      padding: 8px;
    }
  }
  .calendlyDiv {
    margin: 30px 0px;
    max-width: 800px;
    margin: 0 auto;
  }
  .btnDiv {
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
  }
  & label,
  .radio-label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }

  & h3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  .title {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin: 30px auto 20px;
  }

  .schedule-header,
  .header-left,
  .header-right {
    display: flex;
    align-items: center;
    @media screen and (max-width: $break-small) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .header-left {
    @media screen and (max-width: $break-small) {
      display: flex;
      flex-flow: row;
    }
    .schedule-user {
      float: left;
      width: 46px;
      height: 46px;
      border-radius: 50px;
      overflow: hidden;
      background-color: #eee;
    }
    h3 {
      font-size: 16px;
      margin: 0 0 0 20px;
      @media screen and (max-width: $break-small) {
        padding-top: 10px;
      }
    }
  }
  .header-right {
    margin-left: auto;
    @media screen and (max-width: $break-small) {
      display: flex;
      flex-direction: row;
      margin-left: 0;
      align-items: center;
    }
    .schedule-content {
      position: relative;
      padding-left: 30px;
      font-family: Montserrat;
      line-height: 15px;
      @media screen and (max-width: $break-small) {
        padding-left: 0px;
      }
      h3 {
        font-size: 15px;
        margin: 0 0 0 20px;
        letter-spacing: 0;
        @media screen and (max-width: $break-small) {
          padding-top: 10px;
          margin: 0 0 0 0px;
        }
      }
      h6 {
        font-size: 12px;
        margin: 0 0 0 20px;
        letter-spacing: 0;
        @media screen and (max-width: $break-small) {
          padding-top: 10px;
          margin: 0 0 0 0px;
        }
      }
    }
  }
  .continer {
    margin-top: 10px;
    @media screen and (min-width: 768px) {
      display: flex;
      text-align: center;
      padding-bottom: 24px;
    }
  }
  hr {
    margin: 30px 0 20px 0;
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #d8d8d8;
  }
  .radioBtn {
    * {
      height: auto;
    }
    > div {
      justify-content: normal;
      flex-wrap: wrap;
      @media screen and (max-width: $break-small) {
        justify-content: inherit;
        flex-wrap: inherit;
      }
    }
    label {
      margin: 0 10px 0 0;
      float: left;
      span {
        width: 20px;
        height: 20px;
        background-size: 9px;
      }
    }
  }
  .radio-buttons {
    margin-right: 30px;
    @media screen and (max-width: $break-small) {
      margin-right: 6px;
      font-size: 12px;
    }
  }
  .actions-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    & > * {
      text-align: center;
    }
    button {
      margin-left: 20px;
      border-radius: 5rem;
      @media screen and (max-width: $break-small) {
        margin-left: 10px;
        border-radius: 30px;
        padding: 8px 18px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
  .error-container {
    width: 100%;
    margin-left: 1rem;
    .error {
      position: relative;
      top: 3px;
      font-size: 14px;
      color: red;
    }
  }
  .cards {
    margin-bottom: 2.5rem;

    .cardItem {
      display: flex;
      border-radius: 1.625rem;
      height: 3.25rem;
      border: 1px solid $color-gray-light2;
      padding: 0 1.625rem;
      margin: 12px 0 8px;
      margin-left: 35px;

      @media screen and (max-width: $break-small) {
        margin: 10px 0 0 28px;
        height: 2.6rem;
        position: relative;
        p {
          padding-top: 0;
          margin-top: 0;
          font-size: 12px;
          text-overflow: ellipsis;
        }
      }

      p {
        padding-top: 0.6rem;
        width: 40%;
      }
      span {
        margin-right: 30px;
        background: url('../../../img/cards.png') no-repeat 0 0;
        background-size: auto 26px;
        width: 50px;
        display: inline-block;
        margin-top: 5px;
        @media screen and (max-width: $break-small) {
          margin-left: -12px;
          margin-right: 15px;
        }
        &.mastercard {
          background-position: -45px 0;
        }
        &.american_express {
          background-position: -235px 0;
        }
        &.jcb {
          background-position: -188px 0;
        }
        &.discover {
          background-position: -92px 0;
        }
        &.discover_diners {
          background-position: -141px 0;
        }
      }
    }
  }
  .card-radio {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    &:before {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      border: 1px solid #000;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: 10px;
      text-align: center;
      color: #000;
      background-size: 9px;
    }
  }
  .card-radio-default {
    &:before {
      content: '';
    }
  }
  .card-radio-checked {
    &:before {
      content: '';
      background: $color-black;
      background-image: url('../../../img/icons/check-mark.svg');
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      background-size: 9px;
      background-position: center;
    }
  }
  .formGrid {
    border-radius: 23px;
    border: 1px solid #d8d8d8;
    margin-left: 35px;
    @media screen and (max-width: $break-small) {
      display: flex;
      flex-direction: column;
    }
  }
  .newCard {
    margin-left: 35px;
  }
  .backButton {
    background-color: $color-black;
    color: $color-white;
    font-weight: bold;
    font-size: 14px;
    @media screen and (max-width: $break-small) {
      font-size: 10px;
    }
  }

  .text {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $break-small) {
      display: inline-block;
      width: 100%;
    }
    .type,
    .location {
      font-family: Barlow;
      letter-spacing: 0.75px;
      line-height: 24px;
      margin-right: 70px;

      @media screen and (max-width: $break-small) {
        font-family: Barlow;
        letter-spacing: 0.75px;
        line-height: 24px;
        float: left;
        width: 50%;
        padding-right: 10px;
        margin: 0px;
      }
      span {
        font-size: 12px;
        opacity: 0.8;
      }
      p {
        font-size: 16px;
        @media screen and (max-width: $break-small) {
          margin-bottom: 0.5rem;
        }
      }
    }
    .reason {
      max-width: 180px;
      width: 100%;
      @media screen and (max-width: $break-small) {
        width: 100%;
      }
    }
  }
}

.progressBar {
  margin: 10px 0 20px 0;
  display: flex;
  justify-content: center;
}
.progressHead {
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 10px 0 0 0;
    font-weight: 600;
    color: #3bc9e1;
  }
}
