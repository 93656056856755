@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.root {
  display: flex;

  .container {
    width: 100%;
  }
  & label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }
  input {
    box-shadow: none;
    @media screen and (max-width: $break-small) {
      height: 39px;
      font-size: 12px;
      padding: 0 20px;
    }
  }
  margin: 0 auto;
  @media screen and (max-width: $break-small) {
    padding: 10px;
  }

  .form {
    max-width: 52rem;
    margin: auto;
    font-size: 13px;
    padding: 20px 0px;

    .formTitle {
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: left;
      font-weight: bold;
      @media screen and (max-width: $break-small) {
        font-size: 22px;
        line-height: 32px;
      }
    }
    .formInfo {
      margin-top: -8px;
      font-family: 'Barlow', sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }
  .label-bold > div > div > label,
  & h5,
  & h3 {
    display: flex;
    text-align: left;
    margin: 0 0 3px;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
  .label-bold > div > div > label {
    margin-right: 12px;
  }
  & h3 {
    margin: 0 0 -12px;
    @media screen and (max-width: $break-small) {
      margin: 0 0 -5px;
    }
  }
  & label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }
  & h3 {
    text-align: left;
    margin: 0 0 3px;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  .radio {
    display: flex;
    flex-direction: column;
  }
  .radioBtnNp {
    padding: 0 !important;
  }
  .radio-buttons {
    margin-right: 30px;
  }
  .profileModal {
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
    position: fixed;
    max-height: 680px;
    max-width: 1001px;
    width: 100%;
    margin: auto;
    border: 1px solid #000000;
    border-radius: 10px;
    margin: auto;
    margin-top: 2% !important;
    background-repeat: no-repeat;
    background-size: 7rem;
    background-position: 33rem 4.5rem;
    @media screen and (max-width: $break-small) {
      max-width: 90%;
    }
    .content {
      text-align: center;
      display: block;
      padding: 16px 18px;
      h1 {
        font-family: Montserrat SemiBold;
        font-size: 22px;
        line-height: 32px;
        margin-top: 25px;
      }
      p {
        font-family: Barlow Regular;
        font-size: 16px;
        line-height: 24px;
      }
      .cropper {
        width: '100%';
        text-align: center;
        margin-top: 20px;
      }
    }
    .crop-action-grid {
      margin-left: 19%;
      margin-right: 19%;
      margin-top: -8px;
      margin-bottom: 3%;
      @media screen and (max-width: $break-small) {
        margin: 0;
      }
      .actions-container-left {
        text-align: left;
        float: left;
        flex-direction: row;
        button {
          border-radius: 5rem;
          font-size: 0.9rem;
          @media screen and (max-width: $break-small) {
            padding: 0.6rem 0.7rem;
            font-size: 0.6rem;
          }
        }
      }
      .actions-container-right {
        text-align: right;
        float: right;
        flex-direction: row;
        button {
          margin-left: 10px;
          border-radius: 5rem;
          font-size: 0.9rem;

          @media screen and (max-width: $break-small) {
            padding: 0.6rem 0.7rem;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .showDialog {
    display: block;
  }
  .hideDialog {
    display: none;
  }
  .error {
    margin-left: 20px;
    color: $color-validation-error;
    font-size: 12px;
  }
  .backDiv {
    position: relative;
    left: 30px;
    top: 30px;
    @media screen and (max-width: $break-small) {
      margin: 0 auto;
      left: -20px;
      top: -15px;
    }
    span {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
      text-align: left;
      color: $color-black;
      letter-spacing: 0.88px;
      line-height: 24px;
      padding-left: 5px;
      @media screen and (max-width: $break-small) {
        font-size: 12px;
      }
    }
  }
}
.insuranceDropDown {
  position: relative;
  > div:first-child {
    width: 100%;
    margin: 0 auto;
    max-width: 700px;
    display: block !important;
    > div {
      overflow: auto;
      max-height: 250px !important;
      border: 1px solid $color-gray-light2;
      border-radius: 10px;
    }
  }
  input {
    border-radius: 1.625rem;
    max-width: 700px;
    width: 100%;
    height: 3.25rem;
    border: 1px solid $color-gray-light2;
    padding: 0 1.625rem;
    margin: 15px 0 0 0 !important;
    z-index: 0;
    &:focus {
      outline-width: 0;
    }
  }
}
.insuranceLable {
  text-align: left;
  margin: 0 0 3px;
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.typeError {
  color: $color-validation-error;
  font-size: 0.625rem;
  font-weight: 400;
  margin-left: 10px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}
.licenseStatusError {
  color: $color-validation-error;
  font-size: 0.625rem;
  font-weight: 400;
  margin-left: 10px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  @media screen and (max-width: $break-small) {
    width: 150px;
  }
}

.radioCustomDiv {
  margin: 1rem 0;
  label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
    cursor: pointer;
    font-size: 13px;
    font-family: 'Barlow', sans-serif;
    position: relative;
  }
}
.radioCustom {
  opacity: 0;
  position: absolute;
  margin: 0;

  & + .radioCustomLabel:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 1.875rem;
    width: 1.6rem;
    margin-right: 1rem;
    height: 1.6rem;
    cursor: pointer;
  }
  &:checked + .radioCustomLabel:before {
    content: '';
    background: $color-black;
    background-image: url('../../../img/icons/check-mark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px;
    @media screen and (max-width: $break-small) {
      background-size: 10px;
    }
  }
}
.leftNav {
  padding: 0px !important;
}
.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}
.ErrortoastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid $color-error;
  color: black;
  border-radius: 2px;
}
.toastDiv {
  color: black;
  font-weight: 600;
}
