@import '../../../styles/_breaks.scss';
@import '../../../styles/_colors.scss';
@import '../../../styles/_sizes.scss';

.section-white {
  width: 100%;
}

.section-gray {
  @extend .section-white;
  background: $color-gray-bg;
}

.header {
  margin-top: -150px;
  padding-top: 150px;
  @media screen and (max-width: $break-small) {
    margin-top: 0;
    padding-top: 0;
  }
}

.container {
  max-width: $max-width-desktop;
  margin: 0 auto;
}
