@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  background-color: $color-white;
  position: relative;
  min-height: 320px;
  min-width: 290px;
  overflow: hidden;

  .contentDiv {
    overflow: hidden;

    @media screen and (max-width: $break-small) {
      padding: 20px;
    }
  }

  .leftnav {
    padding: 0px !important;
  }

  h2 {
    font-size: 30px;
    padding: 0 0 0 0;
    color: $color-black;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    margin-bottom: 0px;
    @media screen and (max-width: $break-small) {
      font-size: 22px;
      line-height: 32px;
      white-space: break-spaces;
    }
  }
  .button-row {
    display: flex;
    max-width: 402px;
    margin: 0 auto;
  }
  .lineArt {
    opacity: 0.3;
    left: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    width: 90%;
    position: absolute;
    height: 90%;
    display: flex;
    img {
      width: 100%;
      max-height: 100%;
    }
    @media screen and (max-width: $break-small) {
      left: 53%;
      width: 575px;
      height: 50%;
    }
  }
  .clinicList {
    position: relative !important;
    transform: none;
  }

  .form-div {
    width: 100%;
    position: relative;

    .formInput {
      max-width: 402px;
      margin: 0 auto;
      div {
        max-width: 100%;
        [class*='-control'] {
          max-height: 43px;
        }
        [class*='-ValueContainer'] {
          height: 100%;
        }
        input {
          margin-top: 5px;
        }
        label {
          color: $color-black;
        }
      }
    }

    > div input {
      height: 43px;
      font-size: 16px;
      margin: auto;
      max-width: 402px;
      line-height: 1;
      border-radius: 50px;
      padding: 0 20px;
    }
  }

  .content {
    max-width: 920px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0 auto;
    min-height: calc(100vh - 7.5rem);
    @media screen and (max-width: 810px) {
      max-width: 670px;
    }
    @media screen and (max-width: $break-small) {
      min-height: calc(100vh - 70px);
      padding: 20px 15px 100px;
      max-width: 530px;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      padding: 60px 15px 50px;
    }
  }

  .button {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin: 25px auto 0px;
    display: block;
    min-width: 112px;
    &[disabled] {
      opacity: 1 !important;
      background-color: #dddddd !important;
    }
  }
}

.input {
  margin: auto;
  max-width: 30.125rem;
  button[disabled],
  button[disabled]:focus,
  button[disabled]:hover {
    opacity: 1;
    background-color: $color-white !important;
  }
}

.error {
  position: relative;
  top: 3px;
  font-size: 14px;
  color: red;
}
.buttons-row {
  max-width: 998px;
  position: absolute;
  bottom: 40px;
  width: 100%;
  @media screen and (max-width: $break-small) {
    bottom: 60px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    position: relative;
    bottom: 0;
  }
}
