@import '../../styles/_breaks.scss';
@import '../../styles/_colors.scss';

.container {
  background-color: $color-gray-waitlist;
  position: absolute;
  max-width: 400px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: $color-white;
  border-radius: 3px;
  border: solid 1px #d8d8d8;
  box-shadow: 0 2px 0 hsla(0, 0%, 68.6%, 0.12);
}
