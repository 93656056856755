@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.formWrapper {
  background-image: linear-gradient(to bottom, #f0f2f8, #fff);
  font-family: 'Montserrat';
  color: $color-gray-dark;
  padding: 65px 0;

  .editClient {
    display: flex;
  }

  .modalClient {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    width: 128px;
    margin: 30px auto;
    display: block;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .superbillTable {
    width: 100%;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .superbillTable thead,
  .superbillTable tbody {
    border: 0;
    width: 100%;
  }

  .superbillTable thead th {
    color: $color-gray-dark;
    font-size: 15px;
  }

  .superbillTable tbody tr,
  .superbillTable tbody tr:nth-child(even) {
    background-color: $color-white;
  }

  .superbillTable tbody td {
    padding: 0.7rem 0.625rem;
    font-size: 13px;
    word-break: break-all;
    vertical-align: top;
  }

  .superbillTable tbody td:first-of-type {
    font-weight: bold;
  }

  .formTitle {
    border-bottom: 1px solid $color-gray-line;
    font-size: 18px;
  }

  p,
  span {
    font-size: 15px;
    color: $color-gray-dark;
  }

  .clientInfo {
    strong {
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $color-gray-dark;
    }
    p {
      font-size: 13px;
    }

    .paymentFields {
      strong:first-of-type {
        margin-bottom: 10px;
        display: inline-block;
      }
    }
  }

  label {
    font-size: 13px;
    color: $color-gray-dark;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  fieldset {
    width: 100%;
  }

  .paymentLabel {
    width: 100%;
    label {
      font-weight: bold;
    }
  }

  input,
  textarea {
    box-shadow: none;
    border: 1px solid $color-gray-dark;
    font-size: 13px;
    color: $color-gray-dark;
    height: 40px;
    border-radius: 100px;

    &:placeholder-shown {
      background-color: $color-gray-background;
      border: none;
    }
  }

  .selectDropdown > div {
    background-color: $color-gray-background;
    border: none;
    font-size: 13px;
  }

  .selectDropdown.touched > div {
    border: 1px solid $color-gray-dark;
    background-color: $color-white;
    color: $color-gray-dark;
  }

  textarea {
    border-radius: 10px;
    min-height: 100px;
    padding: 10px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    color: $color-gray-dark;
  }

  h2 {
    text-align: left;
    color: $color-gray-dark;
  }

  legend,
  h3 {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    padding: 0.9375rem;
  }

  h3 {
    padding: 0;
  }

  .superBillForm {
    width: 720px;
    margin: 0 auto;
    background-color: $color-white;
    border-radius: 20px;
    padding: 60px 100px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    .buttonAddItem {
      display: block;
      margin: 0 auto;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;

      &:disabled {
        color: $color-gray-disabled;
      }
    }
    .buttonsWrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      button {
        min-width: 144px;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        text-align: center;
        padding: 10px;
      }

      &.continueButton {
        justify-content: center;
        width: 100%;

        button {
          margin: 0;
        }
      }
    }

    a.viewPdf {
      font-size: 15px;
      font-weight: bold;
      text-decoration: underline;
      color: $color-gray-dark;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.mainContainer {
  font-family: 'Montserrat';
  font-size: 15px;
  color: $color-gray-dark;
  overflow: hidden;
  border-radius: 10px;
  background: $color-white;
  min-width: auto;
  min-width: 647px;
  z-index: 2;
  opacity: 1;
  overflow-y: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 20px;
  margin: 0;

  &.clientDetailsModal {
    padding: 30px 20px;

    h1 {
      font-size: 15px;
      text-align: left;
      line-height: 1;
      padding: 0;
    }
  }

  .editButton {
    padding: 15px;
    min-width: 180px;
    font-size: 15px;
    margin: 30px auto 0;
  }

  .closeButton {
    outline: none;
    font-size: 0px;
    min-width: 15px;
    min-height: 15px;
    cursor: pointer;
    background: url(/static/media/close-black.1da3c657.svg) no-repeat center top;
    position: absolute;
    top: 30px;
    right: 30px;
    border: 0;
    background-size: 15px;
  }

  fieldset legend {
    font-size: 20px;
    text-align: center;
  }
}
