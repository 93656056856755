@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.root {
  background-color: #ffffff;
  border: 2px solid $color-gray-border;
  border-radius: 10px;
  margin: 12px 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);

  .content {
    padding: 20px 0 20px 24px;
    display: flex;
    justify-content: space-between;

    .mainInfo {

    }

    .settings {
      display: flex;

      .settingsItem {
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:last-child) {
          border-right: 1px solid $color-gray-border;
        }
      }

      .settingsCheckbox {
        margin-top: 10px;

        & > div {
          align-items: center;

          & > p {
            font-size: 20px !important;
            color: $color-gray-dark !important;
          }
        }
      }
    }

    .title {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: bold;
      color: $color-gray-dark;
    }

    .eventDetails {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .eventDuration {
        width: 105px;
        color: $color-gray-dark;
      }

      .amount {
        > input {
          width: 105px;
          height: 40px;
        }
      }

      .amountFee {
        margin-left: 10px;
      }
    }
  }
}
