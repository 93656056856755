@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.root {
  font-family: Montserrat;
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 192px;
  padding: 30px;
  text-align: center;
  color: $color-gray-dark;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);

  h2 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
  }

  p {
    font-size: 15px;
    line-height: 1.33;
    margin-top: 10px;
  }

  button {
    font-size: 15px;
    padding: 10px;
    margin: 20px 0 0;
    min-width: 144px;
  }

  .createSuperBillButton {
    background-image: linear-gradient(to right, #7bbef2, #b65eba);
    border: none;
    padding: 10px 28px;
  }

  .viewSuperBillButton {
    border: 2px solid $color-gray-dark;
    border-radius: 30px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    background-color: transparent;
    color: #43425d;
  }
}
