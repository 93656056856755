@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.container {
  input {
    width: 100%;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 0;
    background-color: none !important;
    box-shadow: none !important;
    font-family: inherit;
    transition: none !important;
  }
  .customOption {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .checkbox {
      margin: 0 !important;
      padding: 0 !important;
      background: none !important;
      .box {
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
}
