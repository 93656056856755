@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.routes {
  padding-top: $header-height-desktop;
  @media screen and (max-width: $break-small) {
    padding-top: $header-height-mobile;
    overflow-x: hidden;
  }
}

.waitListRoutes {
  background-color: $color-gray-waitlist;
  height: 100vh;
  width: 100vw;
}
