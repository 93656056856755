@import '../../../../styles/_colors.scss';

%button {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 12px;
  padding: 10px 15px;
  min-height: 40px;
  min-width: 100px;
  box-sizing: border-box;
}

button.secondary,
.secondary {
  @extend %button;
}

.secondary,
.secondary:hover,
.secondary:focus {
  background-color: transparent;
  color: $color-gray-dark;
  border: 2px solid $color-gray-dark;
}

.secondary[disabled],
.secondary[disabled]:focus,
.secondary[disabled]:hover {
  opacity: 0.1;
  background-color: $color-black;
}
