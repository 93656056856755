@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_variables.scss';

.container {
  display: flex;
}
.leftNav {
  padding: 0px !important;
}
.content {
  overflow: hidden;
  padding: 20px;
  width: 100%;
  @media screen and (max-width: $break-small) {
    padding: 10px;
    .header {
      color: #000000;
      font-family: Montserrat;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 42px;
      text-align: left;
      padding: 0;
      margin: 10px 0;
    }
  }
  .morebutton {
    text-align: center;
    margin: 10px 0px;
  }
}

.title {
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  color: #43425d;
}

.subTitle {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > button {
    margin-bottom: 0;
  }
}

.backBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.subTitleLabel {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  color: $--dark;
}

.subTitleValue {
  margin-left: 30px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  color: $--dark;
}

.statsCardContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: 30px;
  margin-top: 20px;
}

.statsCard {
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f0f2f8;
  background-color: #fff;
  flex: 1 1 calc(50% - 30px);
  & > div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: $break-small) {
    padding: 10px 15px;
  }
}

.statsCardHeader {
  display: flex;
  grid-gap: 30px;
  & > div {
    display: inline-flex;
    align-items: center;
    &:nth-child(2) {
      justify-content: center;
    }
    // TODO add this once view details card functionality is here
    // &:last-child {
    //   margin-left: auto;
    // }
  }
}

.statsCardAttributeIcon,
.statsCardAttributeIcon > img {
  width: 18px !important;
  height: 20px !important;
  object-fit: contain;
}

.statsCardHeaderDisplay {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #43425d;
  margin-left: 10px;
}

.statsCardHeaderAlertMessage {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-left: 10px;
}

.up {
  color: #4bbb96;
}

.down {
  color: #e50046;
}

.steady {
  color: #0090ff;
}

.statsCardEmpty {
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f0f2f8;
  background-color: #b0b1b3;
  flex: 1 1 calc(50% - 30px);
  & > div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    & > div {
      display: flex;
      align-items: center;
      & > span {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        margin-left: 10px;
      }
    }
  }
}

.statsCardHeaderViewDetails {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #43425d;
  justify-content: flex-end;
  cursor: pointer;
}

.statsCardHeaderViewDetailsArrow {
  width: 8px;
  height: 12px;
  margin-left: 10px;
}

.statsCardContent {
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  flex-grow: 1;
  & > div {
    flex-grow: 1;
    flex-shrink: 1;
    &:first-child {
      flex-basis: calc(25% - 40px);
      display: flex;
      justify-content: center;
      padding: 0 20px 0 0;
    }
    &:last-child {
      flex-basis: calc(75% - 40px);
    }
  }
  @media screen and (max-width: $break-small) {
    flex-direction: column;
  }
}

.statsCardContentChart {
}

.statsCardContentTexts {
  border-left: 1px solid #ccc;
  margin-left: -1px;
  padding: 20px;
}

.statsCardContentHeadline {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #43425d;
}

.statsCardContentParagraph {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #43425d;
}

.chartWrapper {
  position: relative;
  float: left;
}

.chartCaption {
  text-align: center;
  position: absolute;
  line-height: 150px;
  width: 100%;
}

.takeAssessmentBtn {
  align-self: flex-end;
}
