@import '../../../../styles/_breaks.scss';
@import '../../../../styles/_colors.scss';

.textarea {
  font-size: 0.875rem;
  line-height: 1.6875rem;
  resize: none;
  border-radius: 10px;
  border:1px solid #808495;
  padding: 12px 20px;
  &:focus {
    border-color: rgba(112, 112, 112, 0.5);
  }
  &::placeholder {
    color: $color-black;
    opacity: 0.3;
  }
  @media screen and (min-width: $break-small) {
    font-size: 1.125rem;
    line-height: 2.1875rem;
  }
}
