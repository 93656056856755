@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;

  .content {
    max-width: 1170px;
    width: 100%;
    overflow: hidden;
    padding: 20px;
    @media screen and (max-width: $break-small) {
      padding: 20px 10px 10px 10px;
    }
    h1 {
      color: $color_black;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  .header {
    padding: 0 10px;
    @media screen and (min-width: $break-small) {
      display: flex;
    }
  }
}

.billingPlank {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid $color-gray-light4;
  box-shadow: 0 0 20px $color-gray-light4;
  width: 300px;
  margin-top: 20px;

  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  .price {
    color: $color-green-forest;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  .divline {
    height: 1px;
    width: 100%;
    background-color: $color-gray-border;
    margin: 20px 0;
  }

  .feature {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .featName {
      font-weight: bold;
    }

    .featDesc {
      color: $color-green-forest;
    }
  }

  .billingPlankBtn {
    margin-top: 20px;
    width: 100%;
  }
}
