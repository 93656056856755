@import '../../styles/_breaks.scss';
@import '../../styles/_colors.scss';

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
    padding: 15px 15px 0px 15px;
  }
  @media screen and (max-width: $break-small) {
    min-height: 180px;
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    text-align: center;
    padding-bottom: 30px;
    @media screen and (max-width: $break-small) {
      flex-direction: row;
      padding-bottom: 20px;
    }
    .info {
      text-align: left;
      @media screen and (max-width: $break-small) {
        padding-left: 10px;
      }
      .title {
        color: $color-black;
        font-family: Montserrat;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: bold;
      }
      .text {
        color: $color-black;
        font-family: Barlow;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        overflow: hidden !important;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        @media screen and (max-width: $break-small) {
          overflow: hidden !important;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
      .feesText {
        color: $color-black;
        font-family: Montserrat;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: bold;
        padding: 4px 0px;
      }
    }
  }
  .imgBox {
    min-width: 148px;
    margin-right: 15px;
    height: 148px;
    overflow: hidden;
    border-radius: 74px;
    @media screen and (max-width: $break-medium) {
      height: 80px;
      min-width: 80px;
    }
    img {
      object-fit: cover;
      height: 100%;

      @media screen and (max-width: $break-small) {
        border-radius: 50px;
      }
    }
  }
  .buttonDiv {
    display: flex;
    @media screen and (max-width: $break-small) {
      flex-direction: column;
    }
  }
  .button {
    width: 50%;
    margin: 10px;
    padding: 15px;
    font-size: 12px;
    @media screen and (max-width: $break-small) {
      width: 100%;
      margin: 10px 0px;
    }
  }
}

.modal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 451px;
  max-width: 900px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  max-height: 33.8125rem;
  max-width: 25.75rem;
  margin: auto;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 16px;
    @media screen and (max-width: $break-small) {
      padding: 4rem 2rem;
    }
    h1 {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }
    p {
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
    .primaryButton {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      border-radius: 30px;
      padding: 7px 18px;
    }
    .secondaryButton {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      border-radius: 30px;
      padding: 7px 18px;
    }
  }
}
