@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.radio-container {
  label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: 'Montserrat-Regular', sans-serif;
    position: relative;
    color: $color-gray-dark;
    @media screen and (max-width: $break-small) {
      font-size: 0.813rem;
    }
  }
}

.radio-container input:disabled {
  opacity: 0;
}
.radioCustom {
  opacity: 0;
  position: absolute;
  margin: 0;
  & + .radioCustomLabel:before {
    content: '';
    display: flex;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 1.875rem;
    width: 16px;
    margin: auto 1rem auto 0;
    height: 16px;
    cursor: pointer;
    @media screen and (max-width: $break-small) {
      font-size: 0.813rem;
      width: 1.1rem;
      height: 1.1rem;
    }
  }
  &:checked + .radioCustomLabel:before {
    content: '';
    background: $color-black;
    background-image: url('../../../../img/icons/check-mark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px;
    @media screen and (max-width: $break-small) {
      background-size: 10px;
      font-size: 0.813rem;
    }
  }
}
