@import '../../styles/_colors.scss';

.input {
  border-radius: 1.625rem;
  height: 3.25rem;
  border: 1px solid $color-gray-light2;
  padding: 0 1.625rem;
  font-family: Barlow;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  margin: 0;
  &:focus {
    outline-width: 0;
  }
}

.container {
  padding-top: 30px;
  margin: auto;
  max-width: 30.125rem;
}

input:disabled,
input[readonly] {
  opacity: 0.5;
  background: $color-white;
}
