@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.container {
  min-height: 700px;
  height: 100%;
  width: 360px;
  float: left;
  background-color: #f6f6f6;

  .title {
    padding: 32px 51px 15px 51px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
  .menu {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      display: block;
      a {
        padding: 8px 51px 8px 51px;
        font-family: Barlow;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        display: block;
        &.active {
          border-radius: 100px 0 0 100px;
          background-color: #ffffff;
          margin-left: 35px;
          text-decoration: none;
          padding-left: 15px;
          span {
            display: inline-block;
            background: linear-gradient(to right, #31c8e3 0%, #eb81d2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &:after {
              height: 1px;
              width: 100%;
              content: '';
              background-color: #979797;
              background: linear-gradient(270deg, #ea84d2 0%, #3bc9e1 100%);
              float: left;
            }
          }
        }
      }
    }
  }
}
