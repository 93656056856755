@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
  & label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }
  input {
    box-shadow: none;
    @media screen and (max-width: $break-small) {
      height: 39px;
      font-size: 12px;
      padding: 0 20px;
    }
  }
}

.leftNav {
  padding: 0px !important;
}

.content {
  width: 65%;
  overflow: hidden;
  padding: 30px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $break-small) {
    width: 100%;
  }
  h1 {
    color: $color_black;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    @media screen and (max-width: $break-small) {
      margin-left: -5px;
      font-size: 14px;
    }
  }
  h2 {
    color: $color_black;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    @media screen and (max-width: $break-small) {
      margin-left: -5px;
      font-size: 12px;
    }
  }
  .subTitle {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    color: #6c6f86;
  }
  .stepDesc {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }
  hr {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #edf0f6;
    border-color: #dfdfdf;
  }
  a,
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
  .marginTop20 {
    margin-top: 20px;
  }
  img {
    max-width: 100%;
    max-height: 450px;
    vertical-align: middle;
  }
  .api_key_img {
    margin: auto;
    display: inline-block;
    position: relative;
    margin-top: 10px;
    max-width: 70%;
  }
  .actions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > * {
      text-align: center;
    }
    button {
      margin-right: 30px;
      margin-top: 30px;
      margin-bottom: 0;
      min-width: 115px;
      padding: 10px;
      font-size: 16px;
    }
    .actions-container {
      margin-left: -10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      & > * {
        text-align: center;
      }
      button {
        margin-right: 20px;
        border-radius: 5rem;
      }
    }
  }
  .eventContainer {
    width: 100%;
    .events {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .errorMsg {
    font-size: 12px;
    color: #ff0080;
  }
}
