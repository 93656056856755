@import '../../../../styles/_colors.scss';

.input-label {
  display: flex;
  div {
    padding-bottom: 0;
  }
  div > label {
    text-align: left;
    margin: 0 12px 0 0;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
}

.input-field {
  .placeholder-right {
    position: relative;
    &::before {
      content: 'Press Enter to add';
      position: absolute;
      right: 25px;
      top: 10px;
      opacity: 0.2;
      font-size: 12px;
    }
  }
}

.addNewLink {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.tags {
  text-align: left;
  display: inline-block;
  border: 1px solid $color-black;
  background-color: $color-gray-light2;
  color: black;
  border-radius: 40px;
  padding: 0px 10px;
  margin: -8px 5px 10px 0;
  height: 30px;

  & input {
    padding-top: 0;
    background-color: $color-gray-light2;
    border: none;
    width: auto;
    min-width: auto;
    max-width: none;
    pointer-events: none;
    height: auto;
    padding: 0 5px;
    &:focus {
      outline-width: 0;
    }
  }
  & a {
    font-size: 16px;
    margin-left: 15px;
  }
}
