@import '../../../styles/_breaks.scss';

.container {
  width: 40rem;
  margin: 2rem auto;
  h1 {
    margin-bottom: 4rem;
    @media screen and (max-width: $break-small) {
      font-size: 2.8125rem;
      line-height: 2.1875rem;
      font-weight: bold;
    }
  }
  h6 {
    margin-bottom: 1.5rem;
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 1.5rem;
    @media screen and (max-width: $break-small) {
      font-size: 0.875rem;
      line-height: 1.0625rem;
    }
  }
  @media screen and (max-width: $break-small) {
    font-size: 0.875rem;
    line-height: 1.0625rem;
    margin: 2rem;
    text-align: left;
    width: auto;
  }
}
