@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.container {
  display: flex;
  cursor: pointer;
  background: $color-white;
  align-items: center;
  border-radius: 40px;
  width: 16.7675rem;
  height: 5rem;
  padding-left: 1rem;
  > :first-child {
    margin-right: 0.5rem;
  }
  @media screen and (max-width: $break-small) {
    width: 10.2338rem;
    height: 3.125rem;
  }
}

.box-name {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  line-height: 2.8125rem;
  @media screen and (max-width: $break-small) {
    font-size: 0.9375rem;
    font-weight: 400;
  }
}

.box {
  width: 2.2662rem;
  height: 2.2662rem;
  border-radius: 50%;
  border: 3px solid $color-black;
  @media screen and (max-width: $break-small) {
    width: 1.6875rem;
    height: 1.6875rem;
    border: 2px solid $color-black;
  }
}

.box-active {
  @extend .box;
  background: $color-black;
}
