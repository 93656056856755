@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.root {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f0f2f8;
  background-color: #ffffff;
  margin: 29px 0px 68px;
  overflow: hidden;
  position: relative;

  .content {
    float: left;
    padding: 30px 30px 20px;
    max-width: 500px;
    position: relative;
    @media screen and (max-width: $break-small) {
      padding: 120px 30px 20px 20px;
    }
    @media screen and (max-width: $break-medium) {
      padding: 150px 30px 20px 20px;
    }
    h2 {
      text-align: left;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      @media screen and (max-width: $break-small) {
        font-size: 22px;
      }
      @media screen and (max-width: $break-medium) {
        font-size: 22px;
      }
    }
    .text {
      font-family: Barlow;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      padding: 10px 0px;
    }
  }
  .img {
    float: right;
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 60%;
    @media screen and (max-width: $break-medium) {
      max-width: 100%;
    }
  }
  .closeButton {
    color: #ffffff;
    cursor: pointer;
    position: absolute;
    padding: 10px;
    right: 0;
    top: 0;
  }
}
