@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';
@import '../styles/_common.scss';

.root {
  margin: 20px auto;

  @media screen and (max-width: $break-small) {
    padding: 10px;
  }

  .form {
    max-width: 52rem;
    margin: auto;
    font-size: 13px;

    .formTitle {
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: left;
      font-weight: bold;
      color: $color-gray-dark;

      @media screen and (max-width: $break-small) {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .formInfo {
      margin-top: -8px;
      font-family: 'Montserrat-Regular', sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: $color-gray-dark;
    }
  }

  .label-bold > div > div > label,
  & h5,
  & h3 {
    display: flex;
    text-align: left;
    margin: 0 0 3px;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
    color: $color-gray-dark;
  }

  .label-bold > div > div > label {
    margin-right: 12px;
  }

  & h3 {
    margin: 0 0 -12px;

    @media screen and (max-width: $break-small) {
      margin: 0 0 -5px;
    }
  }

  & label {
    color: $color-gray-dark;
    text-transform: none;
    font-size: 13px;
    font-family: 'Montserrat-Bold', sans-serif;
    margin-bottom: 2px;
  }

  & h3 {
    text-align: left;
    margin: 0 0 3px;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  .radio {
    display: flex;
    flex-direction: column;
  }

  .radioBtnNp {
    padding: 0 !important;
  }

  .radio-buttons {
    margin-right: 30px;
  }

  .profileModal {
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
    position: fixed;
    max-height: 680px;
    max-width: 1001px;
    width: 100%;
    margin: auto;
    border: 1px solid #000000;
    border-radius: 10px;
    margin: auto;
    margin-top: 2% !important;
    background-repeat: no-repeat;
    background-size: 7rem;
    background-position: 33rem 4.5rem;

    @media screen and (max-width: $break-small) {
      max-width: 90%;
    }

    .content {
      text-align: center;
      display: block;
      padding: 16px 18px;

      h1 {
        font-family: Montserrat SemiBold;
        font-size: 22px;
        line-height: 32px;
        margin-top: 25px;
      }

      p {
        font-family: Barlow Regular;
        font-size: 16px;
        line-height: 24px;
      }

      .cropper {
        width: '100%';
        text-align: center;
        margin-top: 20px;
      }
    }

    .crop-action-grid {
      margin-left: 19%;
      margin-right: 19%;
      margin-top: -8px;
      margin-bottom: 3%;

      @media screen and (max-width: $break-small) {
        margin: 0;
      }

      .actions-container-left {
        text-align: left;
        float: left;
        flex-direction: row;

        button {
          border-radius: 5rem;
          font-size: 0.9rem;

          @media screen and (max-width: $break-small) {
            padding: 0.6rem 0.7rem;
            font-size: 0.6rem;
          }
        }
      }

      .actions-container-right {
        text-align: right;
        float: right;
        flex-direction: row;

        button {
          margin-left: 10px;
          border-radius: 5rem;
          font-size: 0.9rem;

          @media screen and (max-width: $break-small) {
            padding: 0.6rem 0.7rem;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .showDialog {
    display: block;
  }

  .hideDialog {
    display: none;
  }

  .error {
    margin-left: 20px;
    color: $color-validation-error;
    font-size: 12px;
  }
}

.addFileBtn {
  border-radius: 30px;
  background-color: $color-gray-dark;
  color: $color-white;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 12px;
  padding: 10px 15px;
  min-height: 40px;
  min-width: 100px;
  box-sizing: border-box;
  cursor: pointer;
}

.clinicDiv {
  display: flex;
  align-items: center;
}

.addClinic {
  width: 100%;
  font-size: 12px;
  margin: 0px 10px 10px 10px;
  text-decoration: underline;
}

.addClinicmodal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 500px;
  min-height: inherit;
  height: auto;
  max-width: 332px;
  width: 100%;
  margin: auto;
  overflow: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;

  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }

  @media screen and (min-width: $break-medium) {
    max-height: 600px;
    max-width: 600px;
  }

  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.5rem;

    @media screen and (max-width: $break-small) {
      padding: 4rem 2rem;
    }

    h1 {
      font-size: 22px;
      line-height: 1.875rem;
    }

    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }

    .notInvited {
      p {
        margin: 10px auto;
        font-size: 16px;
        color: $color-error;
      }
    }
  }

  .box {
    p {
      margin: 0;
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
}

.insuranceDropDown {
  position: relative;

  > div:first-child {
    width: 100%;
    margin: 0 auto;
    max-width: 700px;
    display: block !important;

    > div {
      overflow: auto;
      max-height: 250px !important;
      border: 1px solid $color-gray-light2;
      border-radius: 10px;
    }
  }

  input {
    border-radius: 1.625rem;
    max-width: 700px;
    width: 100%;
    height: 3.25rem;
    border: 1px solid $color-gray-light2;
    padding: 0 1.625rem;
    margin: 15px 0 0 0 !important;
    z-index: 0;

    &:focus {
      outline-width: 0;
    }
  }
}

.insuranceLable {
  text-align: left;
  margin: 0 0 3px;
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.newClinic {
  padding: 5px 10px;
  background-color: #f0f2f8;
  border-radius: 100px;
}

.dropdown {
  padding: 0px;
}

.closeBtn {
  width: 15px;
  height: 15px;
  background-color: #43425d;
  color: $color-white;
  border-radius: 50%;
  margin: 5px;
  outline: none;
}

.licenseStatusError {
  color: $color-validation-error;
  font-size: 0.625rem;
  font-weight: 400;
  margin-left: 10px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;

  @media screen and (max-width: $break-small) {
    width: 150px;
  }
}

.typeError {
  color: $color-validation-error;
  font-size: 0.625rem;
  font-weight: 400;
  margin-left: 10px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

.selectedStates {
  margin: 10px 43px 0px 10px;
  object-fit: contain;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #43425d;

  @media screen and (max-width: $break-small) {
    margin: 10px 10px 0px 10px;
  }
}

.statesInputDiv {
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $break-small) {
    flex-direction: column;
    justify-content: center;
  }

  .licenseNumber {
    margin-right: 20px;
    width: 100%;

    @media screen and (max-width: $break-small) {
      margin: 0;
    }
  }
}

.statesError {
  display: flex;
  justify-content: space-between;

  .licenseError {
    position: relative;
    max-width: 370px;
    width: 100%;
    padding: 5px 0 5px 0;
    color: #ff0080;
    font-size: 0.625rem;
    font-weight: 400;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
  }

  .expireDateError {
    max-width: 175px;
    width: 100%;
    left: 0;
    padding: 5px 0 5px 0;
    color: #ff0080;
    font-size: 0.625rem;
    font-weight: 400;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
  }
}

.radioCustomDiv {
  margin: 1rem 0;

  label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
    cursor: pointer;
    font-size: 13px;
    font-family: 'Barlow', sans-serif;
    position: relative;
  }
}

.radioCustom {
  opacity: 0;
  position: absolute;
  margin: 0;

  & + .radioCustomLabel:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 1.875rem;
    width: 1.6rem;
    margin-right: 1rem;
    height: 1.6rem;
    cursor: pointer;
  }

  &:checked + .radioCustomLabel:before {
    content: '';
    background: $color-black;
    background-image: url('../../../../img/icons/check-mark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px;

    @media screen and (max-width: $break-small) {
      background-size: 10px;
    }
  }
}

.input {
  border-radius: 1.625rem;
  height: 3.25rem;
  padding: 0 1.625rem;
  font-size: 13px;
  margin: 0;
  &:focus {
    outline-width: 0;
  }
}

.photoContainer {
  height: 150px;
  width: 100%;
  border-radius: 10px;
  border-style: dashed;
  border-color: $color-gray-light;
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}
.uploadPhotoContainer {
  display: 'flex';
  align-items: 'center';
  flex-direction: 'column';
}
.uploadPhotoSpan {
  font-size: 13px;
  font-family: 'Montserrat', 'sans-serif';
  color: 'red';
  text-align: 'center';
}
