@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/colors';

.root {
  width: 100%;

  .title {
    margin: 0;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    flex-shrink: 0;
    flex: 1 1;
    font-weight: bold;
  }

  .container {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 900px;
    box-sizing: border-box;
    text-align: left;
    border-radius: 6px;
    border: solid 2px #eee;

    .cancelview {
      display: flex;
      flex-direction: row;
      position: relative;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      min-height: 300px;
      padding: 30px;

      @media screen and (max-width: $break-small) {
        flex-direction: column;
      }

      .leftside {
        text-align: center;
        padding: 10px;
        width: 50%;

        @media screen and (max-width: $break-small) {
          width: 100%;
          margin-bottom: 30px;
        }

        .BookingSummary {
          h2 {
            font-size: 22px;
            line-height: 42px;
            white-space: pre-wrap;

            @media screen and (max-width: $break-small) {
              font-size: 18px;
              line-height: 32px;
            }
          }
        }
      }

      .divider {
        width: 1px;
        flex-grow: 0;
        border-left: 1px solid #eee;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 300px;

        @media screen and (max-width: $break-small) {
          display: none;
        }
      }

      .rightside {
        box-sizing: border-box;
        flex: 1.4 1;
        text-align: center;

        .formcontent {
          padding: 30px 70px 0;

          @media screen and (max-width: $break-small) {
            padding: 0;
          }
        }

        .formFields {
          margin-bottom: 20px;
          input {
            border-right: none;
            border-top: none;
            border-left: none;
            box-shadow: none;
            border-radius: inherit;
            padding: 0px;
          }
        }
      }
    }
  }
}
