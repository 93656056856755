@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
  .content {
    width: 100%;
    overflow: visible;
    padding: 20px;
    h1 {
      color: $color_black;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    .form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .card {
      max-width: 1080px;
      width: 100%;
      border: solid 1px #f0f2f8;
      margin: 0px 0px 20px 0px;
      padding: 20px 30px 20px;
      border-radius: 10px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      .lastDetailsDiv {
        padding: 30px 0px;
        display: flex;
        @media screen and (max-width: $break-small) {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
      .detailsDiv {
        padding: 20px 0px;
        border-bottom: solid 1px #e9e9f0;
        display: flex;
        @media screen and (max-width: $break-small) {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
      .fieldTitle {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: left;
        width: 200px;
        margin-right: 15px;
        color: #2f2e50;
      }
      .fieldValue {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #43425d;
        word-break: break-all;
      }
      .inputCell {
        padding: 10px 0px;
      }
      .scheduleSummarySection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .scheduleSummaryColumn {
          display: flex;
          flex-direction: column;
          .scheduleSummaryHeader {
            font-family: Montserrat;
            font-size: 12px;
            font-weight: bold;
            margin: 0;
          }
          .scheduleSummaryBody {
            font-family: Montserrat;
            font-size: 15px;
          }
        }
      }
    }
    .editButton {
      margin-right: 20px;
      font-family: Montserrat;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
    }
  }
  .header {
    @media screen and (min-width: $break-small) {
      display: flex;
    }
  }
  .cardTitle {
    display: flex;
    justify-content: space-between;
    color: $color_black;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    @media screen and (min-width: $break-small) {
      display: flex;
    }
  }
}

.scheduleModal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  overflow: auto;
  height: 510px;
  max-width: 332px;
  width: 100%;
  margin: auto;
  overflow: visible;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .scheduleForm {
    height: 100%;
  }
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    overflow: visible;
    @media screen and (max-width: $break-small) {
      padding: 1rem 0.5rem;
    }
    p {
      font-weight: bolder;
      font-size: 12px;
      margin-bottom: 5px;
    }
    .repeatSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 15px 0;
      .repeatLabel {
        margin-right: 5px;
        width: 25%;
      }
      .repeatCount {
        width: 25%;
        margin-right: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .counterArrows {
        margin-left: 5px;
      }
      .repeatSelect {
        width: 50%;
      }
    }
    .repetitionEndDateSection {
      width: 100%;
      .cell {
        padding: 0 !important;
        align-self: center;
        .datePicker {
          width: 100%;
        }
        .occurrenceCount {
          margin-right: 5px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .counterArrows {
            margin: 0 10px 0 5px;
            height: 50%;
          }
        }
      }
    }
    .checkRepetitionCompletionDate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 0 15px 0;
      p {
        margin: 0;
      }
    }
    .actionButtons {
      width: 100%;
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        min-width: 100px;
      }
    }
  }
}
