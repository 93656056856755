@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}
.error {
  color: $color-error;
}
.toastDiv {
  color: black;
  font-weight: 600;
}
.root {
  .container {
    margin: 50px auto;
    padding: 4rem 1rem;
    box-sizing: border-box;
    width: 801px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #ffffff;
    @media screen and (max-width: $break-small) {
      padding: 2rem 2rem;
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
    }
  }
  .userTypes {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    .radioLabel {
      margin-right: 20px;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 26px;
      text-transform: none;
    }
  }
  .backDiv {
    margin: auto;
    max-width: 450px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    span {
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 24px;
      padding-left: 10px;
    }
  }
  .content {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $break-small) {
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
      flex-direction: column;
    }
    form {
      width: 100%;
    }
    .aggreement {
      padding: 10px 0;
      display: flex;
      align-items: center;
      label {
        font-size: 12px;
        line-height: 20px;
        text-transform: none;
      }
    }
    & label {
      color: $color-black;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 24px;
      text-transform: capitalize;
    }
    h4 {
      padding: 50px 0px 10px 0;
      font-weight: bold;
    }
    .radioBtn,
    .radioBtnNp {
      > div {
        justify-content: normal;
        flex-wrap: wrap;
      }
      label {
        margin: 0 10px 0 0;
        float: left;
        span {
          width: 20px;
          height: 20px;
          background-size: 9px;
        }
      }
    }
    .radioBtnNp {
      padding: 0 !important;
    }
    .radio-buttons {
      margin-right: 30px;
    }
    .userInput {
      padding: 10px 0;
    }
    .nextBtn {
      text-align: left;
      padding: 15px 0;
      .primaryBtn {
        border-radius: 30px;
        background: linear-gradient(90deg, #3bc9e1 0%, #ea84d2 100%);
      }
    }
    .link {
      margin: 15px 0 15px 0;
    }
    .dobRow {
      padding: 10px 0;

      label {
        margin-bottom: 10px;
      }
    }
  }
}
.validation {
  max-width: 300px;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  height: 120px;
  flex-direction: column;
  font-family: 'Barlow';
  @media screen and (max-width: $break-small) {
    height: auto;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.75px;
  }
  .active {
    color: green;
  }
}
.userError {
  color: #ff0080;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}
.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}
.toastDiv {
  color: black;
  font-weight: 600;
}
.aggreementCheck {
  width: 40px;
  height: 20px;
  @media screen and (max-width: $break-small) {
    width: 60px;
    height: 20px;
  }
}
.disabled {
  pointer-events: none;

  input {
    opacity: 0.5;
  }
}
