@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.container-disabled {
  opacity: 0.2;
}

.content-container--column {
  display: flex;
  flex-direction: column;
}

.content-container--row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.children-container {
  display: flex;
  margin: 1rem 0;
}

.radio-button-container {
  height: 2.5rem;
}

.radio-button,
.radio-button-selected {
  display: none;
}

.radio-button + label span,
.radio-button-selected + label span {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 1.875rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  @media screen and (max-width: $break-small) {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.radio-button + label,
.radio-button-selected + label {
  margin-left: 0;
}

.radio-button-selected + label span {
  background: $color-black;
  background-image: url('../../../img/icons/check-mark.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
  @media screen and (max-width: $break-small) {
    background-size: 10px;
  }
}
