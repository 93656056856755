@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.root {
  .container {
    margin: 50px auto;
    padding: 4rem 1rem;
    box-sizing: border-box;
    width: 801px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #ffffff;
    @media screen and (max-width: $break-small) {
      padding: 20px;
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
  }
  .bottomLinks {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    a {
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none;
    }
  }
  .content {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $break-small) {
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
      flex-direction: column;
    }
    form {
      width: 100%;
      max-width: 360px;
    }
    & label {
      color: $color-black;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 24px;
      text-transform: capitalize;
    }
    h4 {
      padding: 50px 0px 10px 0;
      font-weight: bold;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    .userInput {
      padding: 10px 0;
    }
    .nextBtn {
      text-align: left;
      padding: 15px 0;
      .primaryBtn {
        font-weight: bold;
        letter-spacing: 0;
        border: none;
        border-radius: 30px;
        background: linear-gradient(90deg, #3bc9e1 0%, #ea84d2 100%);
      }
      @media screen and (max-width: $break-small) {
        button {
          padding: 10px 20px;
          font-size: 12px;
        }
      }
    }
  }
  input {
    @media screen and (max-width: $break-small) {
      padding: 0px 20px;
      height: 40px;
      font-size: 12px;
    }
  }
}

.emailExistsModal {
  max-width: unset;
}
