@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.root {
  .container {
    margin: 50px auto;
    padding: 4rem 1rem;
    box-sizing: border-box;
    width: 801px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #ffffff;
    @media screen and (max-width: $break-small) {
      padding: 2rem 2rem;
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
    }
  }
  .checkboxBtn {
    padding: 20px 0 10px 0;
  }
  .forgotPassword {
    padding-top: 10px;
    display: flex;
    font-size: 14px;
    justify-content: flex-end;
  }
  .userTypes {
    display: flex;
    margin: 0 0 20px 0;
    .radioLabel {
      margin-right: 20px;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 26px;
      text-transform: none;
    }
  }
  .logo {
    margin: auto;
    max-width: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      height: 31px;
      width: 101px;
    }
  }

  .content {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $break-small) {
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
      flex-direction: column;
    }
    form {
      width: 100%;
      max-width: 360px;
    }

    & label {
      color: $color-black;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 24px;
      text-transform: capitalize;
    }
    h4 {
      padding: 50px 0px 10px 0;
      font-weight: bold;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    .radioBtn,
    .radioBtnNp {
      > div {
        justify-content: normal;
        flex-wrap: wrap;
      }
      label {
        margin: 0 10px 0 0;
        float: left;
        span {
          width: 20px;
          height: 20px;
          background-size: 9px;
        }
      }
    }
    .radioBtnNp {
      padding: 0 !important;
    }
    .radio-buttons {
      margin-right: 30px;
    }
    .userInput {
      padding: 10px 0;
    }
    .nextBtn {
      text-align: left;
      padding: 15px 0;
      .primaryBtn {
        font-weight: bold;
        letter-spacing: 0;
        border: none;
        border-radius: 30px;
        background: linear-gradient(90deg, #3bc9e1 0%, #ea84d2 100%);
      }
    }
    .link {
      margin: 15px 0 15px 0;
    }
    .socialLogin {
      @media screen and (max-width: $break-small) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 300px;
        width: 100%;
      }
    }
    .withGoogle {
      box-sizing: border-box;
      border: 2px solid #000000;
      border-radius: 30px;
      max-width: 260px;
      width: 100%;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      background-image: url('../../img/google.svg');
      background-repeat: no-repeat;
      background-position: 10%;
      padding: 10px 40px;
    }
    .withApple {
      box-sizing: border-box;
      border: 2px solid #000000;
      max-width: 260px;
      border-radius: 30px;
      width: 100%;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      background-image: url('../../img/apple.svg');
      background-repeat: no-repeat;
      background-position: 10%;
      padding: 10px 40px;
    }
  }
}

.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}

.toastDiv {
  color: black;
  font-weight: 600;
}
.verticalLine {
  box-sizing: border-box;
  height: 166px;
  width: 1px;
  border: 1px solid #dddddd;
  @media screen and (max-width: $break-small) {
    max-width: 300px;
    width: 100%;
    height: 1px;
    margin: 10px 0 40px 0;
  }
}
