@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.menu {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .submenu {
    width: 100%;

    .logout {
      margin-left: 25px;
      font-size: 16px;
      letter-spacing: 1px;
    }
    li {
      margin-left: 1.3rem;
    }
  }
  .user {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding-left: 10px;
    h4 {
      font-size: 16px;
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
    span {
      margin-left: 0.5rem;
    }
    img {
      max-height: 2rem;
      width: 35px;
      height: 40px;
      border-radius: 20px;
    }
  }
}

.header {
  padding: 0.5rem 1rem !important;
  display: flex;
  justify-content: space-between;
  .logoDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-container {
    padding: 1rem;
    display: flex;
    img {
      width: 1.4756rem;
      height: 1.555rem;
    }
  }
}

.search {
  margin-top: 1.5rem;
  padding: 0 2rem !important;
}

.link {
  padding: 0 !important;
}

.shop-all-link {
  font-size: 0.875rem;
  text-align: center;
  font-weight: 600;
  padding: 0 !important;
}
.menu {
  margin: 0px;
  padding: 0px;
  list-style: none;

  hr {
    position: unset;
    margin: 15px 0 10px 0;
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #d8d8d8;
  }
  li {
    display: block;
    a {
      padding: 8px 51px 8px 51px;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      display: block;
      @media screen and (max-width: $break-medium) {
        padding: 8px 10px 8px 25px;
      }
      &.active {
        border-radius: 100px 0 0 100px;
        background-color: #ffffff;
        margin-left: 35px;
        text-decoration: underline;
        padding-left: 15px;
        @media screen and (max-width: $break-medium) {
          margin: 5px 0 0 10px;
          width: 100%;
        }
        span {
          padding: 5px 0px;
          display: inline-block;
          background: linear-gradient(to right, #31c8e3 0%, #eb81d2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:after {
            height: 1px;
            width: 100%;
            content: '';
            background-color: #979797;
            background: linear-gradient(270deg, #ea84d2 0%, #3bc9e1 100%);
            float: left;
          }
        }
      }
    }
    .bookAppointment {
      padding: 8px 51px 8px 25px;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }
}
.bookAppointmentModal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: none;
  max-width: 332px;
  width: 100%;
  margin: auto;
  overflow: visible !important;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  font-size: 14px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    text-align: center;
    h1 {
      font-size: 22px;
      line-height: 1.875rem;
      margin: 0 0 15px 0;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }
    .dropDown {
      max-width: 350px;
      padding: 15px;
    }
  }
  .box {
    p {
      margin: 0;
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
}

.bookBtn {
  font-size: 16px;
  font-family: Barlow;
  line-height: 24px;
  button {
    letter-spacing: 1px;
    color: $color-black;
    text-decoration: none;
    outline: none;
    display: block;
    width: 100%;
    text-align: left;
    span {
      padding: 8px 51px 8px 51px;
      outline: none;
      float: left;
    }

    &.active,
    &:focus {
      border-radius: 100px 0 0 100px;
      background-color: #ffffff;
      text-decoration: underline;
      margin-left: 35px;
      span {
        padding-left: 15px;
        background: linear-gradient(to right, #31c8e3 0%, #eb81d2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &:after {
          height: 1px;
          width: 100%;
          content: '';
          background-color: #979797;
          background: linear-gradient(270deg, #ea84d2 0%, #3bc9e1 100%);
          display: block;
        }
      }
    }
  }
}
