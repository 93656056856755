@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
  .content {
    width: 100%;
    overflow: hidden;
    padding: 20px;
    h1 {
      color: $color_black;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    .form {
      height: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .card {
      max-width: 1080px;
      width: 100%;
      border: solid 1px #f0f2f8;
      margin: 0px 0px 20px 0px;
      padding: 20px 30px 20px;
      border-radius: 10px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      .lastDetailsDiv {
        padding: 30px 0px;
        display: flex;
        @media screen and (max-width: $break-small) {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
      .detailsDiv {
        padding: 20px 0px;
        border-bottom: solid 1px #e9e9f0;
        display: flex;
        @media screen and (max-width: $break-small) {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
      .fieldTitle {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: left;
        width: 200px;
        margin-right: 15px;
        color: #2f2e50;
      }
      .fieldValue {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #43425d;
        word-break: break-all;
      }
      .inputCell {
        padding: 10px 0px;
      }
    }
    .editButton {
      margin-right: 20px;
      font-family: Montserrat;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
    }
  }
  .header {
    @media screen and (min-width: $break-small) {
      display: flex;
    }
  }
  .cardTitle {
    color: $color_black;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    @media screen and (min-width: $break-small) {
      display: flex;
    }
  }
}

.insuranceDropDown {
  position: relative;
  > div:first-child {
    width: 100%;
    margin: 0 auto;
    max-width: 700px;
    display: block !important;
    > div {
      overflow: auto;
      max-height: 250px !important;
      border: 1px solid $color-gray-light2;
      border-radius: 10px;
    }
  }
  input {
    border-radius: 1.625rem;
    max-width: 700px;
    width: 100%;
    height: 3.25rem;
    border: 1px solid $color-gray-light2;
    padding: 0 1.625rem;
    margin: 15px 0 0 0 !important;
    z-index: 0;
    &:focus {
      outline-width: 0;
    }
  }
}
