@import "../../../styles/_breaks.scss";
@import "../../../styles/_sizes.scss";
@import "../../../styles/_colors.scss";

.mainContainer {
  font-family: 'Montserrat';
  font-size: 15px;
  color: $color-gray-dark;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  background: $color-white;
  min-width: 720px;
  z-index: 2;
  opacity: 1;
  overflow-y: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 20px;
  margin: 0;

  .modalTitle {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .modalSubTitle {
    margin-bottom: 20px;
    text-align: center;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &.requestAgainButtonsWrapper {
      justify-content: space-between;
    }

    button {
      padding: 10px;
      min-width: 100px;
      font-size: 15px;
      margin-bottom: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .container {
      display: flex;
      width: 100%;

      .leftside {
        width: 50%;
        padding-right: 20px;
      }
      .rightside {
        width: 50%;
        padding-left: 20px;
        border-left: 1px solid $color-gray-border;
      }

      .googlebtn {
        display: block;
        outline: none;
        width: 191px;
        height: 46px;
        cursor: pointer;

        background-image: url("../../../img/google-btn/1x/btn_google_signin_light_normal_web.png");
        background-position: center;
        background-attachment: repeat;

        &:hover {
          background-image: url("../../../img/google-btn/1x/btn_google_signin_light_focus_web.png");
        }
        &:focus {
          background-image: url("../../../img/google-btn/1x/btn_google_signin_light_pressed_web.png");
        }
      }
      .googlebtndisabled {
        pointer-events: none;
        background-image: url("../../../img/google-btn/1x/btn_google_signin_light_disabled_web.png");

        &:hover {
          background-image: url("../../../img/google-btn/1x/btn_google_signin_light_disabled_web.png");
        }
        &:focus {
          background-image: url("../../../img/google-btn/1x/btn_google_signin_light_disabled_web.png");
        }
      }

      .sidebtn {
        margin-top: 10px;
      }
    }
  }
}
