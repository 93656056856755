@import '../../../../styles/_colors.scss';

%button {
  background-color: $color-gray-dark;
  color: $color-white;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 12px;
  padding: 10px 15px;
  min-height: 40px;
  min-width: 100px;
  box-sizing: border-box;
}

button.primary,
.primary {
  @extend %button;
}

.primary:hover,
.primary:focus {
  opacity: 0.9;
  background-color: $color-gray-dark;
}

.primary[disabled],
.primary[disabled]:focus,
.primary[disabled]:hover {
  opacity: 0.1;
  @extend %button;
}
.iconLeft {
  margin-right: 5px;
  padding-bottom: 3px;
  height: 17px;
  width: 17px;
}
