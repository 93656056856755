@import '../../styles/_breaks.scss';
@import '../../styles/_colors.scss';

.root {
  padding: 0px;
  @media screen and (max-width: $break-small) {
    margin-top: 15px;
    padding: 0;
  }

  h1 {
    margin-top: 10px;
    color: $color_black;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    @media screen and (max-width: $break-small) {
      margin: 10px 0 20px 0;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      text-align: left;
    }
  }
  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}
.link > a,
.link > a:visited {
  text-decoration: underline;
  color: $color-black;
  font-weight: bold;
}
.noData {
  padding: 5px 0px;
}
