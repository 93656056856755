@import '../../../styles/_colors.scss';

.container {
  background-color: $color-white;
  box-shadow: 0 3px 16px $color-gray-cert;
  & > div {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.menu-left {
  display: flex;
  align-items: center;
  font-family: 'Roboto Mono', monospace;
  font-size: 1.5rem;
  justify-content: flex-start;
  line-height: 2.8125rem;
  & > ul {
    flex-grow: 1;
    flex-shrink: 0;
    li {
      margin: 0 1rem;
    }
  }
}

.menu-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div {
    cursor: pointer;
    flex-shrink: 0;
    padding: 1rem 0;
  }
  & > div:first-child {
    flex-shrink: 1;
  }
  & > div + div {
    margin-left: 4rem;
  }
}

.logo {
  display: flex;
  justify-content: center;
}

.menu-items {
  li {
    padding-left: 2rem;
    cursor: pointer;
    a {
      padding-right: 0;
    }
  }
}

.home a,
.home span {
  color: $color-black !important;
  background: transparent;
}

.overlay-open {
  background: $color-white;
}

.shipping-banner {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 6.7%;
}

.dropdown-trigger {
  text-decoration-color: white !important;
  outline-color: white !important;
}
