@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.box-container {
  box-sizing: border-box;
  max-width: 500px;
  border: 2px solid #000000;
  border-radius: 8px;
  background-color: #f6f6f6;
  display: flex;
  cursor: pointer;

  .circle {
    display: flex;
    justify-content: center;
    padding: 15px;
  }

  .circle-small {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $color-black;
    &.is-selected {
      border: 6px solid $color-black;
      &:after {
        content: '';
        background: red;
      }
    }
    @media screen and (max-width: $break-small) {
      width: 20px;
      height: 20px;
      border: 2px solid $color-black;
      margin-right: 0px;
    }
  }
}

.text-container {
  text-align: left;
  font-weight: bold;
  @media screen and (min-width: $break-small) {
    text-align: left;
  }
}

.button-title {
  font-family: 'Montserrat', sans-serif;
  color: $color-black;
  font-size: 18px;
  padding: 10px 0px;
  @media screen and (max-width: $break-small) {
    max-width: inherit;
    font-size: 16px;
    padding: 13px 0px;
  }
}
