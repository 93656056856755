@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.container {
  margin-top: -6.5rem;
  @media screen and (max-width: $break-small) {
    margin-top: -40px;
  }
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.9)
  );
}

.header {
  display: flex;
  padding: 25px 20px;
}

.headerMobile {
  justify-content: center;
  .subHeader {
    display: flex;
    padding: 15px 10px;
  }
  .menu-right {
    margin-left: auto;
    display: block;
  }
}

.menu-right {
  position: absolute;
  right: 0;
  margin-top: 3px;
  margin-right: 20px;
  & > div {
    cursor: pointer;
  }
}

.logo {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  img {
    max-height: 60px;
    width: 100%;
    object-fit: contain;
    @media screen and (max-width: $break-small) {
      max-height: 40px;
      width: 100%;
      object-fit: contain;
    }
  }
}

.consentForm,
.bookingCal {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding: 15px;
  iframe {
    border: solid 2px #ddd;
  }
}

.step {
  color: #969696;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

  @media screen and (max-width: $break-small) {
    display: none;
  }
}
.step-visited {
  @extend .step;
  color: #000000;
}
.step-active {
  @extend .step-visited;
  font-weight: 600;
  @media screen and (max-width: $break-small) {
    display: block;
    width: 100 !important;
  }
}
.primaryButton {
  background-color: $color-black;
  color: $color-white;
  font-family: Montserrat;
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: 0;
  @media screen and (max-width: $break-small) {
    padding: 0.85rem 1.45rem !important;
  }
}
.modal {
  z-index: 99;
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 350px;
  max-width: 900px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 25.75rem;
  margin: auto;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 16px;
    @media screen and (max-width: $break-small) {
      padding: 4rem 2rem;
    }
    h1 {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }
    p {
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
  .primaryButton {
    background-color: $color-black;
    color: $color-white;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    padding: 0.85em 1em;
    max-width: 197px;
  }
  .secondaryButton {
    font-weight: bold;
    font-size: 12px;
    padding: 10px 18px;
  }
}
.link {
  font-size: 12px;
  color: $color-white;
  font-weight: bold;
  border-radius: 30px;
  line-height: 20px;
  font-family: Montserrat;
  border: 2px solid $color-black;
  padding: 6px 13px;
  min-width: 113px;
  background: $color-black;
  cursor: pointer;
  letter-spacing: 0;
  @media screen and (max-width: $break-small) {
    font-size: 0.813rem;
  }
}
