@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import './styles/_breaks.scss';
@import './styles/_colors.scss';
@import './styles/_sizes.scss';
@import './styles/_variables.scss';

html {
  font-size: 0.8333333333333333vw;

  @media screen and (max-width: $break-medium) {
    font-size: 1.15vw;
  }

  @media screen and (max-width: $break-small) {
    font-size: 3.8647343vw;
  }
}

body {
  font-family: 'Barlow', sans-serif;
  color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6,
b {
  font-family: 'Montserrat', sans-serif;
}

header {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
}

header > div {
  display: flex;
  height: $header-height-desktop;
  align-items: center;

  @media screen and (min-width: $break-small) {
    padding: 0 8.5%;
  }

  @media screen and (max-width: $break-small) {
    height: $header-height-mobile;
    margin: 0;
    padding: 0 1rem;
    width: 100%;
  }
}

.logo-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

hr {
  border-color: $color-black;
  opacity: 0.5;
  margin: 0;
  max-width: 100%;
}

a,
a:hover,
a:active,
a:visited {
  color: $color-black;
}

h2 {
  text-align: center;
  font-size: 1.5625rem;
  font-weight: 600;
}

.button {
  padding: 1rem 1.75rem;
  border-radius: 55px;
  font-size: 0.875rem;
}

.button:focus {
  outline: 0;
}

.menu .button,
.menu a,
.menu span {
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 2rem 0.7rem 0;
}

.cell {
  padding: 0.9375rem;
}

.pagination li {
  display: inline-block;
  padding: 0.875rem;
  cursor: pointer;

  @media screen and (max-width: $break-small) {
    padding: 0.4rem;
  }
}

// Mobile and Desktop media queries
@media screen and (max-width: $break-small) {
  .desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: $break-small) {
  .mobile-only {
    display: none !important;
  }
}

h1 {
  font-size: 3.375rem;
  font-weight: 500;
  padding-top: 0.5rem;

  @media screen and (max-width: $break-small) {
    font-size: 2.1875rem;
    padding-top: 0;
  }
}

.subtitle {
  color: $color-gray-subtitle;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2;
}

.error-message {
  color: $color-error;
  font-size: 0.875rem;
  padding: 0;
}

.yellow-bg {
  background: $color-yellow-bg;
}

.green-bg {
  background: $color-green-bg;
}

.orange-bg {
  background: $color-orange-bg;
}

.blue-bg {
  background: $color-blue-bg;
}

.purple-bg {
  background: $color-purple-bg;
}

.yellow-link {
  color: $color-yellow-lnk !important;
}

.green-link {
  color: $color-green-lnk !important;
}

.orange-link {
  color: $color-orange-lnk !important;
}

.blue-link {
  color: $color-blue-lnk !important;
}

.purple-link {
  color: $color-purple-lnk !important;
}

.hidden {
  display: none;
}

.fixed {
  position: fixed;
}

// Slider dots
.slick-dots {
  position: initial;

  li {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    border: 1px solid;

    &.slick-active {
      background-color: $color-black;
    }

    button::before {
      font-size: 0;
    }
  }
}

button.slick-arrow {
  display: none !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.separator {
  margin: 0 auto;
  background-color: $color-gray-line;
  height: 1px;
  width: 60%;
}

.grid-center {
  display: flex;
  padding: 1.5rem 2rem 0 2rem;
  flex-wrap: wrap;

  & > div {
    flex-grow: 1;
    flex-basis: 0;
    margin: 1rem;
  }
}

.filters-separator {
  height: 1px;
  background: $color-gray-line;
  max-width: 39rem;
  margin: 2rem auto;

  @media screen and (max-width: $break-small) {
    display: none;
  }
}

.no-scroll {
  overflow-y: hidden;

  @media screen and (max-width: $break-small) {
    position: fixed;
  }
}

.fixed-nav {
  background: $color-white;
  height: 5rem;
  -webkit-box-shadow: 0px 10px 6px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 10px 6px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 10px 6px 0px rgba(0, 0, 0, 0.03);
  margin-bottom: 5px;

  & > div:nth-child(2) {
    img {
      @media screen and (max-width: $break-small) {
      }
    }
  }

  a,
  span {
    color: $color-black !important;
  }

  @media screen and (max-width: $break-small) {
    height: $header-height-mobile;
  }
}

.link-underline {
  @extend .font-15;
  text-decoration: underline;
  cursor: pointer;
}

// Font-sizes
.font-25 {
  font-size: 1.5625rem;
}

.font-20 {
  font-size: 1.25rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-15 {
  font-size: 0.9375rem;
}

.font-10 {
  font-size: 0.625rem;
}

.left {
  text-align: left;
}

.text-editor {
  .ql-tooltip {
    left: auto !important;
  }

  .ql-editor {
    img {
      max-width: 100%;
      margin: 20px 0px 0px 0px;
    }
  }

  .ql-container {
    border: 0px;
  }
}
