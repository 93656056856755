@import '../../../styles/_colors.scss';

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: red;
  background: gray;
  opacity: 0.9;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;

  .loader {
    width: 4em;
    height: 4em;
    font-size: 1rem;
    box-sizing: border-box;
    border-top: 0.3em solid $color-blue-mobile-menu;
    border-radius: 50%;
    position: relative;
    animation: rotating 2s ease-in-out infinite;
    --direction: 1;
    margin: 0 auto;
  }

  .loader::before,
  .loader::after {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    box-sizing: border-box;
    top: -0.2em;
  }

  .loader::before {
    border-top: 0.3em solid $color-purple-mobile-menu;
    transform: rotate(120deg);
  }

  .loader::after {
    border-top: 0.3em solid $color-orange-mobile-menu;
    transform: rotate(240deg);
  }

  .loader span {
    position: absolute;
    color: $color-white;
    width: inherit;
    height: inherit;
    text-align: center;
    line-height: 10em;
    animation: rotating 2s linear infinite;
    --direction: -1;
    z-index: 10;
  }

  @keyframes rotating {
    50% {
      transform: rotate(calc(180deg * var(--direction)));
    }

    100% {
      transform: rotate(calc(360deg * var(--direction)));
    }
  }
}
