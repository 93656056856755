@import '../../../../styles/_colors.scss';
@import '../../../../styles/_breaks.scss';

.container {
  position: relative;
  > div:first-child {
    width: 100%;
    margin: 0 auto;
    max-width: 402px;
    display: block !important;
    > div {
      overflow: auto;
      max-height: 250px !important;
      border: 1px solid $color-gray-light2;
      border-radius: 10px;
      @media screen and (max-width: $break-small) {
        max-height: 155px !important;
      }
    }
  }
  input {
    border-radius: 1.625rem;
    max-width: 402px;
    width: 100%;
    height: 3.25rem;
    border: 1px solid $color-gray-light2;
    padding: 0 1.625rem;
    margin: 15px 0 0 0 !important;
    &:focus {
      outline-width: 0;
    }
  }
}
