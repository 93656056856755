@import '../../../../styles/_colors.scss';

.input {
  border-radius: 1.625rem;
  height: 3.25rem;
  border: 1px solid $color-gray-light2;
  padding: 0 1.625rem;
  font-size: 13px;
  margin: 0;
  color: $color-gray-dark;
  &:focus {
    outline-width: 0;
  }
}

.container {
  display: flex;
  align-items: baseline;
  position: relative;
}

.button {
  position: absolute;
  right: 1rem;
  top: 0.85rem;
  outline: none;
}

.arrow-container {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
}

.arrow-black-img {
  @extend .arrow-container;
  cursor: pointer;
  background-color: $color-black;
}

.arrow-white-img {
  @extend .arrow-container;
  background: $color-white;
}

input:disabled,
input[readonly] {
  opacity: 0.5;
  background: $color-white;
}
