@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';
.root {
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 31.6%;
  border-radius: 20px;
  background-color: #fff;
  box-sizing: border-box;
  margin-left: 24px;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  min-height: 196px;
  @media screen and (max-width: $break-medium) {
    margin: 0 10px;
    min-width: 350px;
    width: auto;
    &:first-child {
      margin: 10px;
    }
    &:last-child {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: $break-small) {
    min-width: 90vw;
    width: auto;
    margin-left: 10px;
    &:last-child {
      margin-right: 10px;
    }
  }
  &:first-child {
    margin: 0px;
  }

  > span {
    background-image: url('../../img/box-pattern-bg.png');
    position: absolute;
    background-size: auto 180px;
  }
  .topright {
    right: 0;
    background-position: 300px 0;
    height: 84px;
    width: 115px;
    top: -15px;
  }
  .bottomleft {
    left: 0;
    background-position: 60px 100%;
    height: 84px;
    width: 75px;
    bottom: -25px;
  }
  .rightimg {
    right: 0;
    background-position: 125px 100%;
    height: 84px;
    width: 115px;
    bottom: -27px;
  }
  .leftimg {
    left: 0;
    background-position: 224px 0;
    height: 96px;
    width: 98px;
    top: 50px;
  }
  .topLeft {
    left: 0;
    background-position: 224px 0;
    height: 96px;
    width: 85px;
    top: -24px;
  }
  .topcenter {
    left: 150px;
    background-position: -301px 0;
    height: 92px;
    width: 100px;
    top: -30px;
  }
  .bottomright {
    right: -25px;
    background-position: -406px 0;
    height: 92px;
    width: 100px;
    bottom: -35px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    z-index: 1;
    position: relative;
    .stepDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      .step {
        color: #000000;
        font-family: Barlow;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        text-align: center;
        margin: 0px;
      }
      img {
        width: 14px;
        height: 14px;
        margin-left: 10px;
      }
    }
    .head {
      color: #000000;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center;
      padding-bottom: 6px;
      min-height: 60px;
    }
    .text {
      color: #000000;
      font-family: Barlow;
      font-size: 12px;
      letter-spacing: 0.75px;
      line-height: 16px;
      text-align: center;
    }
    .noText {
      margin-top: 10px;
    }
    button.btn {
      border: 2px solid #000000;
      border-radius: 30px;
      padding: 10px 34px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
    button.btnMargin {
    }
  }
}
