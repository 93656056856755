@import '../../../styles/_breaks.scss';
@import '../../../styles/colors';

.container {
  display: flex;
}
.leftnav {
  padding: 0px !important;
}
.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  h1 {
    color: $color_black;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: start;
    padding: 25px 0px;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  margin-bottom: 33px;
  border-bottom: 2px solid #b4b4c6;
  .tab {
    background-color: unset;
    padding-left: unset;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    color: #b4b4c6;
    margin-right: 40px;
    cursor: pointer;
    letter-spacing: 1px;
    position: relative;

    &.activeTab {
      > span {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: bold;
        color: #43425d;
      }
      > div {
        text-align: center;
        padding-bottom: 2px;
        background-color: #808bff;
        position: relative;
        top: 2px;
      }
    }
  }
}
