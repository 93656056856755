@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
}

.leftNav {
  padding: 0px !important;
}

.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  font-weight: bold;

  .mainBox {
    margin-top: 25px;
    border: solid 12px #e3e9ef;
    background-color: #e3e9ef;
    @media screen and (max-width: $break-small) {
      margin: 20px -20px;
      border: solid 6px #e3e9ef;
    }
  }

  h1 {
    color: $color_black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
  .card {
    border-radius: 5px;
    background: white;
    opacity: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: none;
    flex: 1 1 0%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    justify-content: space-between;
    font-family: Barlow;

    .cardHeader,
    .cardFooter {
      display: flex;
      justify-content: space-between;
      height: 60px;
      margin-top: 8px;
      color: #6c6f86;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      padding: 15px 25px 12px 25px;
      font-family: Barlow;
      @media screen and (max-width: $break-small) {
        padding: 10px 15px;
        font-size: 16px;
        display: inline-block;
        height: auto;
        margin-top: 0px;
      }

      @media screen and (max-width: $break-small) {
        .cardHeader {
          > span {
            font-size: 16px;
            font-family: Barlow;
            @media screen and (max-width: $break-small) {
              font-size: 12px;
            }
          }
        }
      }
      .buttons {
        padding-left: 20px;
        @media screen and (max-width: $break-small) {
          display: flex;
          padding-left: 0px;
          margin-top: 15px;
          margin-left: -10px;
        }
        button {
          margin-left: 10px;
          margin-bottom: 0px;
        }
        .btn {
          box-sizing: border-box;
          border: 1px solid #eef1f6;
          border-radius: 10px;
          color: #6c6f86;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 20px;
          padding: 1rem 1.75rem;
          padding: 6px 19px;
          margin-top: -5px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
          @media screen and (max-width: $break-small) {
            font-size: 10px;
          }
        }
      }
    }
    .hr {
      box-sizing: border-box;
      height: 1px;
      border: 1px solid #edf0f6;
      width: 100%;
    }

    .cardRow {
      margin-top: 8px;
      padding: 5px 25px 12px 25px;
      justify-content: space-between;
      display: flex;
      color: #6c6f86;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
      @media screen and (max-width: $break-small) {
        font-size: 14px;
        padding: 5px 15px 5px 15px;
      }
    }
    .cardRow2 {
      height: 80px;
      margin-top: 8px;
      padding: 12px 25px 12px 25px;
      color: #6c6f86;
      font-size: 18px;
      @media screen and (max-width: $break-small) {
        padding: 10px 15px;
        height: auto;
      }
      div:nth-child(2) {
        margin-top: 3px;
        font-size: 15px;
        font-weight: normal;
      }
    }
    a {
      color: #bdc2ce;
    }
  }
  .cardMargin {
    margin-top: 15px;
  }
}
