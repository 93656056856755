@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;

  .leftNav {
    padding: 0px !important;
  }
  .content {
    width: 50%;
    @media screen and (max-width: $break-medium) {
      width: 100%;
    }
    @media screen and (max-width: $break-small) {
      width: 100%;
    }
    overflow: hidden;
    padding: 20px;
    h1 {
      color: $color_black;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    .formData {
      min-height: 473px;
      @media screen and (max-width: $break-small) {
        min-height: inherit;
      }
    }
    .logoImage {
      margin: 10px 0px;
      img {
        width: 70px;
        height: auto;
      }
    }
    .footerlogoImage {
      margin: 10px 0px;
      background: #000000;
      width: 70px;
      height: auto;
      img {
        width: 70px;
        height: auto;
      }
    }
    .clinicData {
      max-width: 1080px;
      width: 100%;
      border: solid 1px #f0f2f8;
      margin: 30.8px 0px 20px 0px;
      padding: 0px 30px 20px;
      border-radius: 10px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      .lastDetailsDiv {
        padding: 30px 0px;
        display: flex;
        @media screen and (max-width: $break-small) {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
      .detailsDiv {
        padding: 20px 0px;
        border-bottom: solid 1px #e9e9f0;
        display: flex;
        @media screen and (max-width: $break-small) {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
      .fieldTitle {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: left;
        width: 200px;
        margin-right: 15px;
        color: #2f2e50;
      }
      .fieldValue {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #43425d;
        word-break: break-all;
      }
    }
    .editButton {
      font-family: Montserrat;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
    }
    .saveButton {
      width: 100px;
    }
  }
  .header {
    @media screen and (min-width: $break-small) {
      display: flex;
    }
  }
}
.EditClinicmodal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 500px;
  min-height: inherit;
  height: auto;
  max-width: 600px;
  width: 100%;
  margin: auto;
  overflow: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  @media screen and (min-width: $break-medium) {
    max-height: 600px;
    max-width: 600px;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    @media screen and (max-width: $break-small) {
      padding: 30px 20px;
    }
    h1 {
      font-size: 22px;
      line-height: 1.875rem;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }
  }
  .box {
    p {
      margin: 0;
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
  label {
    color: $color-black;
    text-transform: none;
    font-size: 12px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 2px;
  }
}
