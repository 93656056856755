@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
}

.leftNav {
  padding: 0px !important;
}

.progressBar {
  margin: 10px 0 20px 0;
  display: flex;
  justify-content: center;
}
.progressHead {
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: $break-small) {
    justify-content: space-between;
  }
  p {
    margin: 10px 0 0 0;
    font-weight: 600;
  }
}
.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}
.toastDiv {
  color: black;
  font-weight: 600;
}
.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  font-weight: bold;

  .mainBox {
    margin-top: 25px;
    border: solid 12px #e3e9ef;
    background-color: #e3e9ef;
    @media screen and (max-width: $break-small) {
      margin: 20px -20px;
      border: solid 6px #e3e9ef;
    }
  }

  h1 {
    color: $color_black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
  .message {
    width: 500px;
    margin: 0 auto;
    @media screen and (max-width: $break-small) {
      width: 100%;
    }
    .warningImg {
      text-align: center;
      margin: 20px 0px;
      @media screen and (max-width: $break-small) {
        margin: 15px 0px;
      }
      img {
        width: 70px;
        height: 70px;
        object-fit: contain;
        @media screen and (max-width: $break-small) {
          width: 55px;
          height: 55px;
        }
      }
    }
    h2 {
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: center;
      @media screen and (max-width: $break-small) {
        font-size: 25px;
        line-height: 1.2;
      }
    }
    .subTitle {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      @media screen and (max-width: $break-small) {
        line-height: 1.27;
      }
    }
  }
}
