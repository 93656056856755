@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.mainContainer {
  display: flex;
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-width: 650px;
  max-height: max-content;
  height: auto;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
    left: 50%;
    top: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
  @media screen and (min-width: $break-medium) {
    max-height: 300px;
    max-width: 650px;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 25px 0px;
    .container {
      display: block;
      text-align: center;
      position: relative;
      width: 100%;
      h1 {
        font-size: 20px;
        line-height: 1.875rem;
        text-align: center;
      }
      h4 {
        font-weight: bold;
      }
      .warningImg {
        height: auto;
        width: 70px;
        margin: 20px auto;
      }
      .backDiv {
        position: absolute;
        left: 10px;
        top: 0;
        cursor: pointer;
        img {
          height: 18px;
          width: 18px;
          bottom: 1px;
          left: 0px;
        }
        span {
          font-weight: bold;
        }
      }
      .text {
        margin: 10px 0px;
      }
    }
  }
  .box {
    p {
      color: #000000;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
  }
}
