@import '../../styles/_colors.scss';
@import '../../styles/_breaks.scss';

.root {
  .container {
    margin: 50px auto;
    padding: 4rem 1rem;
    box-sizing: border-box;
    width: 801px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #ffffff;
    @media screen and (max-width: $break-small) {
      padding: 3rem 2rem;
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
    }
  }
  .backDiv {
    margin: auto;
    position: relative;
    left: 30px;
    top: 30px;
    @media screen and (max-width: $break-small) {
      margin: 0 auto;
      left: -20px;
      top: -15px;
    }
    span {
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 24px;
      padding-left: 5px;
      @media screen and (max-width: $break-small) {
        font-size: 12px;
      }
    }
  }
  .confirmationTitle {
    color: #000000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
  .warningImg {
    height: 80px;
    width: 80px;
    margin: 65px auto 25px;
  }
  .text {
    max-width: 427px;
    width: 100%;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    margin: 0 auto;
    word-break: break-word;
  }
}
.nextBtn {
  text-align: center;
  padding: 15px 0;
  .primaryBtn {
    font-weight: bold;
    letter-spacing: 0;
    border: none;
    border-radius: 30px;
    background: linear-gradient(90deg, #3bc9e1 0%, #ea84d2 100%);
  }
}
.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}
.toastDiv {
  color: black;
  font-weight: 600;
}
