@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
  hr {
    max-width: 900px;
    width: 100%;
    border-color: #cacaca !important;
    border-bottom: 2px solid #cacaca;
    position: relative;
    top: 30px;
    left: 55px;
    @media screen and (max-width: $break-small) {
      display: none;
    }
  }
}
.leftnav {
  padding: 0px !important;
}
.content,
.clientDetails {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  height: 100%;
  overflow: auto;
  @media screen and (max-width: $break-small) {
    height: 100%;
  }

  h1 {
    color: $color_black;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
  h2 {
    text-align: unset;
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .profile {
    margin: 16px 0px 0 0px;

    @media screen and (max-width: $break-small) {
      margin: 16px 0px 0px 0px;
    }

    .profileDiv {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media screen and (max-width: $break-small) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
      }

      .timeDiv {
        margin-left: 20px;
        display: flex;
        > img {
          height: 40px;
          max-width: 40px;
          margin-bottom: 12px;
          border-radius: 20px;
        }
        .personalDetailsDiv {
          padding: 0 20px;
        }
      }
      .headerButtons {
        display: inline-flex;
        gap: 10px;
      }
    }

    .profile-detail {
      display: flex;

      @media screen and (max-width: $break-small) {
        display: flex;
        flex-direction: column;
      }

      .patientDetails {
        font-family: Barlow;
        letter-spacing: 0.75px;
        line-height: 24px;
        margin-right: 50px;
        @media screen and (max-width: $break-small) {
          width: 50%;
          margin-right: 0px;
          float: left;
          line-height: 15px;
        }
        span {
          font-size: 12px;
          opacity: 0.8;
        }
        p {
          font-size: 16px;
          @media screen and (max-width: $break-small) {
            margin: 0px;
          }
        }
      }
    }
  }

  .timeline {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 30px;
    .timelineHead {
      display: flex;
      align-items: center;
      height: 40px;
      max-width: 1016px;
      width: 100%;
      border-radius: 20px;
      background-color: #f6f6f6;
      padding: 0 18px;
      .heading {
        margin: 0px;
        min-width: 209px;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        @media screen and (max-width: $break-small) {
          min-width: 110px;
        }
      }
    }
    .timelineList {
      margin: 0 20px;
      min-width: 460px;
      li {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        @media screen and (max-width: $break-small) {
          white-space: normal;
        }
        &:first-child :after {
          display: none;
        }
        &:last-child .connect {
          background: none;
        }
        &:last-child .connect:before {
          display: none;
        }
        div {
          display: inline-block;
          min-width: 200px;
          padding-right: 15px;
          position: relative;
          font-family: Barlow;
          font-size: 16px;
          letter-spacing: 1px;
          line-height: 24px;
          @media screen and (max-width: $break-small) {
            font-size: 14px;
            letter-spacing: 0.88px;
            line-height: 20px;
            width: 100px;
            min-width: 100px;
          }
          &.connect {
            padding: 8px 20px;
            position: relative;
            &:before {
              content: '';
              background-image: url('../../../img/timeline-border.svg');
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: cover;
              height: 59px;
              width: 215px;
              position: absolute;
              top: 25px;
              right: 0;
              @media screen and (max-width: $break-small) {
                height: 64px;
                width: 230px;
                position: absolute;
                top: 22px;
                right: 0;
                background-size: cover;
              }
            }
            @media screen and (max-width: $break-small) {
              min-width: 200px;
              margin-left: 0px !important;
              width: auto;
              padding-right: 15px;
            }
          }
        }
        .timelineDetails {
          white-space: nowrap;
          text-overflow: ellipsis;
          letter-spacing: 0;
          max-width: 200px;
          overflow: hidden;
        }
      }
      .timelineBox {
        cursor: pointer;
        min-width: inherit;
        display: inline-block;
        position: relative;
        padding: 7px 20px;
        box-sizing: border-box;
        background: #fff;
        background-clip: padding-box;
        border-radius: 50px;
        text-align: center;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          margin: -1px;
          border-radius: inherit;
          background: linear-gradient(to right, #31c8e3 0%, #eb81d2 100%);
        }
        &:after {
          content: '\2022';
          position: absolute;
          top: -10%;
          left: -7px;
          font-size: 40px;
          line-height: 1;
          color: #31c8e3;
        }
        > span {
          background: linear-gradient(to right, #31c8e3 0%, #eb81d2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          letter-spacing: 0;
          max-width: 170px;
          overflow: hidden;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  padding-top: 11px;
  padding-bottom: 15px;

  .tab {
    background-color: unset;
    padding-left: unset;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    color: black;
    margin-right: 18px;
    cursor: pointer;
    font-family: Barlow;
    letter-spacing: 1px;
    position: relative;

    &.activeTab {
      > span {
        background: linear-gradient(to right, #31c8e3 0%, #eb81d2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      > div {
        text-align: center;
        padding-bottom: 2px;
        background-color: #979797;
        background: linear-gradient(270deg, #ea84d2 0%, #3bc9e1 100%);
      }
    }
  }
}
.nodata {
  font-size: 16px;
  color: $color_black;
}
.buttonRow {
  text-align: right;
  margin-right: 30px;
}

.formModal {
  overflow: visible;
}

.modal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  width: 100%;
  margin: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 691px;
  top: 10%;
  max-height: 600px;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
    max-height: 86%;
  }
  .closeButton {
    top: 15px;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .content {
    display: block;
    padding: 16px 18px;
    overflow: visible !important;

    .closeButton {
      width: 16px;
      top: 10px;
      position: absolute;
      right: 10px;
      cursor: pointer;
      img {
        height: 32px;
        width: 32px;
      }
    }
    h1 {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.6875rem;
    }
    .contentDetail {
      padding-top: 10px;

      .box {
        border: solid 15px #f2f2f2;

        .content-detail {
          background-color: #ffffff;
          padding: 19px 28px;
          max-height: 451px;
          overflow: auto;

          h2 {
            color: #000000;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
          }

          span {
            color: #b3b3b3;
            font-family: Barlow;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
          p {
            color: #000000;
            font-family: Barlow;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }

          .detail {
            display: flex;
          }
          .column3 {
            width: 33%;
          }
          .column2 {
            width: 50%;
          }
        }
        .table {
          width: 100%;

          .table-tr {
            display: flex;
            div {
              width: 23%;
              color: #b3b3b3;
              font-family: Barlow;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
            }
            p {
              width: 23%;
              color: #000000;
              font-family: Barlow;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
            }
          }
          .table-td {
            display: flex;
            div {
              width: 25%;
              color: #b3b3b3;
              font-family: Barlow;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
            }
            span {
              width: 25%;
              color: #000000;
              font-family: Barlow;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
            }
          }
        }
      }

      .meeting-details {
        .meeting-time {
          display: flex;
          p {
            margin: 0 0 0 10px;
            font-family: Montserrat;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 24px;
            font-weight: bold;
            @media screen and (max-width: $break-small) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
        .isAfter {
          color: #3bc9e1;
        }
        .isBefore {
          color: black;
        }
        .title {
          font-family: Montserrat;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: bold;
          padding: 10px 0px;
        }

        .text {
          display: flex;
          @media screen and (max-width: $break-small) {
            display: inline-block;
            width: 100%;
          }
          .type,
          .location {
            font-family: Barlow;
            letter-spacing: 0.75px;
            line-height: 24px;
            margin-right: 0px;
            width: 33%;
            float: left;
            @media screen and (max-width: $break-small) {
              font-family: Barlow;
              letter-spacing: 0.75px;
              line-height: 24px;
              float: left;
              width: 25%;
              padding-right: 10px;
              margin: 0px;
            }
            span {
              font-size: 12px;
              opacity: 0.8;
            }
            p {
              font-size: 16px;
            }
          }
          @media screen and (max-width: $break-small) {
            .reason {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .buttonrow {
    margin: 10px 5px;
    button {
      margin-right: 10px;
    }
  }
  :global .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
  :global .ql-container.ql-snow {
    height: 350px;
    border: none;
  }
  :global .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc;
    height: 350px;
  }

  :global .ql-snow.ql-toolbar {
    border: none;
    display: block;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  :global .ql-editor {
    min-height: 18em;
  }
}

.header {
  display: flex;
  > p {
    margin-right: 40px;
    letter-spacing: 0.75px;
    line-height: 20px;
  }
}
.line {
  box-sizing: border-box;
  height: 1px;
  width: 662px;
  border: 1px solid #f1f1f1;
}
.richText {
  height: 247px;
  width: 580px;
  color: #000000;
  font-family: Barlow;
  font-size: 12px;
  letter-spacing: 0.75px;
  line-height: 20px;
}

.buttons {
  text-align: end;
  padding: 20px 0 0 0;
  @media screen and (max-width: $break-small) {
    padding-left: 20px;
    text-align: right;
    margin-top: 15px;
  }
  button {
    margin-left: 10px;
    margin-bottom: 0px;
  }
  .btn {
    box-sizing: border-box;
    border: 2px solid #000000;
    border-radius: 30px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding: 1rem 1.75rem;
    padding: 6px 19px;
  }
}
.text {
  display: flex;
  margin-left: 60px;
  @media screen and (max-width: $break-small) {
    display: block;
    margin: 0 auto;
    max-width: 320px;
    background-size: 300px auto;
    width: 100%;
    background-image: url('../../../img/SnakeyLine.png');
    background-position: center 28px;
    background-repeat: no-repeat;
    text-align: center;
  }
  .type,
  .location {
    padding: 10px 0px;
    font-family: Barlow;
    letter-spacing: 0.75px;
    line-height: 24px;
    margin-right: 15px;
    width: 100%;
    max-width: 230px;

    @media screen and (max-width: $break-small) {
      font-family: Barlow;
      letter-spacing: 0.75px;
      line-height: 17px;
      float: left;
      width: 20%;
      padding-right: 10px;
      margin: 0px 5px 0px 30px;
    }
    span {
      font-size: 12px;
      opacity: 0.8;
    }
    p {
      font-size: 16px;
      @media screen and (max-width: $break-small) {
        margin-bottom: 0.5rem;
      }
    }
  }
  .reason {
    max-width: 180px;
    width: 100%;
    @media screen and (max-width: $break-small) {
      width: 100%;
    }
  }
}
.yesNoDiv {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  .positivePopUp {
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    top: -60px;
    left: 50%;
    position: absolute;
    margin: 0 0 0 -95px;
    z-index: 2;
    width: 190px;
    height: auto;
    background: $color-white;
    border-radius: 6px;
    padding: 0.5rem;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $break-small) {
      width: 145px;
      top: -65px;
      left: 50px;
      font-size: 13px;
      letter-spacing: 0;
    }
  }
  .positivePopUp:after {
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    background: $color-white;
    position: absolute;
    border-left: 1px solid #85db78;
    border-bottom: 1px solid #85db78;
    -webkit-box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
  }
  &:hover .positivePopUp {
    opacity: 1;
    visibility: visible;
  }
  .negativePopUp {
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    top: -60px;
    left: 50%;
    position: absolute;
    margin: 0 0 0 -95px;
    z-index: 2;
    width: 190px;
    height: auto;
    background: $color-white;
    border-radius: 6px;
    padding: 0.5rem;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $break-small) {
      width: 145px;
      top: -65px;
      left: 50px;
      font-size: 13px;
      letter-spacing: 0;
    }
  }
  .negativePopUp:after {
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    background: $color-white;
    position: absolute;
    border-left: 1px solid #ff7979;
    border-bottom: 1px solid #ff7979;
    -webkit-box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    @media screen and (max-width: $break-small) {
      top: 2.1rem;
      right: 6.1rem;
    }
  }
  &:hover .negativePopUp {
    opacity: 1;
    visibility: visible;
  }
  .neutralPopUp {
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    top: -60px;
    left: 50%;
    position: absolute;
    margin: 0 0 0 -95px;
    z-index: 2;
    width: 13rem;
    height: auto;
    background: $color-white;
    border-radius: 6px;
    padding: 0.5rem;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $break-small) {
      width: 145px;
      top: -65px;
      left: 50px;
      font-size: 13px;
      letter-spacing: 0;
    }
  }
  .neutralPopUp:after {
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    background: $color-white;
    position: absolute;
    border-left: 1px solid #78ccdb;
    border-bottom: 1px solid #78ccdb;
    -webkit-box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    box-shadow: -7px 5px 6px -3px rgba(0, 0, 0, 0.1);
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
  }
  &:hover .neutralPopUp {
    opacity: 1;
    visibility: visible;
  }
}
.yesNo {
  z-index: 0;
  margin-bottom: 10px;
  height: 36px;
  width: 36px;
}
.neutralLabel {
  border: 1px solid #78ccdb;
  margin: 0 10px;
  color: #78ccdb;
  font-family: Barlow;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  @media screen and (max-width: $break-small) {
    font-size: 14px;
  }
}
.yesLabel {
  border: 1px solid #85db78;
  margin: 0 10px;
  color: #7bb95e;
  font-family: Barlow;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  @media screen and (max-width: $break-small) {
    font-size: 14px;
  }
}
.noLabel {
  border: 1px solid #ff7979;
  margin: 0 10px;
  color: #ff7979;
  font-size: 16px;
  font-family: Barlow;
  letter-spacing: 1px;
  line-height: 24px;
  @media screen and (max-width: $break-small) {
    font-size: 14px;
  }
}
.firstOrder {
  text-align: center;
  max-width: 450px;
  width: 100%;
  display: flex;
}
.mobileOrder {
  display: flex;
}
.desktopOrder {
  text-align: center;
  max-width: 450px;
  width: 100%;
  display: flex;
}
.typeError {
  color: $color-validation-error;
  font-size: 0.625rem;
  font-weight: 400;
  margin-left: 10px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

.toastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid #e8fade;
  color: black;
  border-radius: 2px;
}
.ErrortoastMsg {
  font-size: 14px;
  background-color: white;
  border-left: 10px solid $color-error;
  color: black;
  border-radius: 2px;
}
.toastDiv {
  color: black;
  font-weight: 600;
}

.clientDetails {
  padding: 30px;
  padding-top: 20px;
  height: 100vh !important;
  background-color: white;
  overflow-y: hidden;
}

.clientDetailsTitle {
  font-size: 20px;
  margin-bottom: 20px;
  flex-grow: 1;
}

.clientDetailsSectionTitle {
  color: #808bff;
  border-bottom: #707070 1px solid;
  font-size: 15px;
  font-weight: 900;
  padding-bottom: 6px;
  margin-bottom: 5px;
}

.clientDetailsInfoSectionWrapper {
  margin: 15px 0;
}

.clientDetailsModal {
  font-family: 'Montserrat';
  max-height: 95vh;
  max-width: 45rem;
  top: 0;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  .closeButton {
    top: 7px;
    line-height: 20px;
    right: 0;
  }
}

.clientDetailsModalContent::-webkit-scrollbar {
  display: none;
}
.clientDetailsModalContent {
  overflow-y: auto;
  height: 85vh;
  // Hide scroll
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.clientDetailsHeader {
  display: flex;
}

.dataBoxWrapper {
  color: #43425d;
  font-size: 15p;
  margin: 5px 0;
  flex-grow: 1;
  @media screen and (max-width: $break-small) {
    width: 100% !important;
  }
}

// Patient profile tables
.patientDetailsTableWrapper {
  overflow-x: auto;
  min-width: 100%;
  margin: 10px 0;
}

.patientDetailsTable {
  width: max-content;
  min-width: 100%;
  margin-bottom: 0;
  color: #43425d;
}

.patientDetailsTable thead,
.patientDetailsTable tbody {
  border: 0;
  width: 100%;
}

.patientDetailsTable thead th {
  color: $color-gray-dark;
  font-size: 15px;
}

.patientDetailsTable thead tr th {
  background-color: #808bff;
  color: white;
  text-align: center;
  min-width: 150px;
  width: auto;
}

.patientDetailsTable tbody tr:nth-child(even) {
  background-color: #b4b4c6;
}

.patientDetailsTable th {
  font-weight: 600;
}

.patientDetailsTable th,
.patientDetailsTable td {
  border-right: white 1px solid;
}

.patientDetailsTable th:last-child,
.patientDetailsTable td:last-child {
  border-right: none;
}

.patientDetailsTable tbody td {
  padding: 10px;
  font-size: 15px;
  word-break: break-all;
  vertical-align: top;
  text-align: center;
}

.patientDetailsTable tbody td:first-of-type,
.patientDetailsTable thead tr th:first-of-type {
  text-align: left;
}
