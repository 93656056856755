@import '../../../styles/_colors.scss';

.container {
  align-items: baseline;
  position: relative;
  > div:first-child {
    width: 100%;
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      right: 20px;
      top: 13px;
      background: url('../../../img/icons/calendar-grey.svg') no-repeat center
        center;
    }
  }
}
.date-picker {
  border-radius: 1.625rem;
  height: 3.25rem;
  border: 1px solid $color-gray-light2;
  padding: 0 1.625rem;
  margin: 0;
  &:focus {
    outline-width: 0;
  }
}
