@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $color-white;
  max-width: 31.375rem;
  min-height: max-content;
  margin: 2rem auto;
  z-index: 2;
  opacity: 1;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  animation: fade-in 0.3s;
  overflow-y: auto;
  @media screen and (max-width: $break-small) {
    margin: 1rem;
  }
}

.background {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $color-gray-light7;
  opacity: 0.5;
  z-index: 2;
  animation: fade-in 0.3s;
  backdrop-filter: blur(5px);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}
