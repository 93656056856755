// black
$color-black: #000;
$color-black-light: #1a1a1a;

// gray
$color-gray: #9e9e9e;
$color-gray-bg: #fafafa;
$color-gray-light: #a8a8a8;
$color-gray-light2: #d8d8d8;
$color-gray-subtitle: #464646;
$color-gray-callout: #b9b9b9;
$color-gray-copyright: #585858;
$color-gray-subtitle: #7e7e7e;
$color-gray-cert: #e6e6e6;
$color-gray-light-text: #818181;
$color-gray-light3: #efefef;
$color-gray-light4: #cbcbcb;
$color-gray-light5: #f4f4f4;
$color-gray-line: #707070;
$color-gray-bsellers: #fcfcfc;
$color-gray-search: #dddddd;
$color-gray-label: #b7b7b7;
$color-gray-bg2: #f0f0f0;
$color-gray-border: #a7a7a7;
$color-gray-cart-text: #797979;
$color-gray-faqs: #cccccc;
$color-gray-light6: #f8f7f7;
$color-gray-light7: #3b3b53;
$color-gray-waitlist: #f9f9f9;
$color-gray-dark: #43425d;
$color-gray-deep: #2f2e50;
$color-gray-background: #f0f2f8;
$color-gray-disabled: #bdbdbd;
$color-gray-placeholder: #b4b4c5;

// white
$color-white: #fff;

// pink
$color-pink-overlay2: #ffd9d5;
$color-pink-overlay: #fde3eb;

// blue
$color-blue-overlay: #d1deff;
$color-blue-bg: #d4eff9;
$color-blue-lnk: #a5d9f4;
$color-blue-mobile-menu: #fee2e1;
$color-blue-light: #d4eff9;
$color-blue-sky: #9ee8ef;
$color-blue-soft: #ccecfc;
$color-blue-turquoise: #c2eef3;
$color-blue-temple: #afe0f4;
$color-blue-soft2: #d8ebfc;
$color-blue-footer: #3bc9e1;
$color-blue-cookie: #a7d9f4;
$color-blue-dark: #d8e8ff;
$color-blue-light2: #c3faff;

// purple
$color-purple-bg: #f2e4ef;
$color-purple-lnk: #ebcde4;
$color-purple-mobile-menu: #f6e4fd;
$color-purple-light: #e2d3e7;
$color-purple-footer: #ea84d2;
$color-purple-bright: #ffebfb;
$color-purple-dark: #dbe9ff;

$color-bg-error: #f8f0f3;
$color-validation-error: #ff0080;

// red
$color-error: #d8000c;

// yellow
$color-yellow-light: #f8f3ba;
$color-yellow-bg: #fcffd9;
$color-yellow-lnk: #afba24;
$color-yellow-soft: #fef2d2;
$color-yellow-canary: #f5f0aa;
$color-yellow-bright: #fff9c5;
$color-yellow-dark: #ffeceb;

// orange
$color-orange-bg: #fbe2d0;
$color-orange-lnk: #cb8a5b;
$color-orange-mobile-menu: #e3ebff;
$color-orange-dark: #ffe0bb;

// green
$color-green-bg: #e9fadf;
$color-green-lnk: #82a76c;
$color-green-cbd: #c9d2d1;
$color-green-light: #f6f2d6;
$color-green-soft: #e1f3e5;
$color-green-soft2: #e4fde3;
$color-green-dark: #bef9ff;
$color-green-forest: #19913d;

:export {
  black: $color-black;
  blackLight: $color-black-light;
  gray: $color-gray;
  grayBg: $color-gray-bg;
  grayLight: $color-gray-light;
  grayLight2: $color-gray-light2;
  graySubtitle: $color-gray-subtitle;
  white: $color-white;
  pinckOverlay: $color-pink-overlay;
  grayBorder: $color-gray-border;
  grayDark: $color-gray-dark;
  grayPlaceholder: $color-gray-placeholder;
  grayBackground: $color-gray-background;
  grayDisabled: $color-gray-disabled;
}
