@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.mainContainer {
  font-family: 'Montserrat';
  font-size: 15px;
  color: $color-gray-dark;
  display: flex;
  overflow: hidden;
  z-index: 2;
  opacity: 1;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  max-width: none;
  min-height: unset;
  box-shadow: none;
  background: transparent;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    width: 720px;
    border-radius: 10px;
    padding: 50px 20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);

    div {
      display: flex;
      justify-content: center;
    }
    .container {
      display: block;
      text-align: center;
      position: relative;
      max-width: 360px;

      .warningImg {
        height: auto;
        width: 90px;
        margin: 0 auto 20px;
      }

      .text {
        margin: 10px 0px;
      }
    }
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: $color-gray-dark;
  }

  button {
    padding: 10px;
    min-width: 100px;
    font-size: 15px;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 1rem;
  }
  div > button {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
