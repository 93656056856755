@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.container {
  text-transform: uppercase;
  flex-flow: wrap;
  display: flex;
  align-items: center;
}

.label {
  font-size: 13px;
  font-weight: 800;
  color: $color-gray-dark;
  font-family: 'Montserrat-Bold';
}

.is-error--strong {
  font-family: 'Montserrat', sans-serif;
  color: $color-validation-error;
}

.error-label {
  font-size: 0.625rem;
  margin-top: 5px;
  font-family: 'Montserrat', sans-serif;
  color: $color-validation-error;
  @media screen and (max-width: $break-small) {
    font-size: 0.438rem;
  }
}
