@import '../../../styles/_colors.scss';
@import '../../../styles/_breaks.scss';

.fieldsContainer {
  font-family: 'Montserrat';
  border-top: 1px solid $color-gray-dark;
  border-bottom: 1px solid $color-gray-dark;
  color: $color-gray-dark;
  width: 100%;
}

.fieldArray {
  display: flex;
  justify-content: space-between;
  padding: 9px 0;
  align-items: center;
}

.fieldsWrapper {
  span:first-child {
    font-weight: bold;
  }

  span {
    margin-right: 20px;
    font-size: 13px;
  }
}

.itemButtonsWrapper {
  button:first-child {
    margin-right: 10px;
  }
}
.remove,
.edit {
  outline: none;
  font-size: 0px;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  background: url('../../../img/icons/dustbin.svg') no-repeat center top;
}

.edit {
  margin-left: 10px;
  background: url('../../../img/icons/edit-icon-solid.svg') no-repeat center top;
  color: #000;
}

.close {
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
}
