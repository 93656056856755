@import '../../styles/breaks';
@import '../../styles/_colors.scss';

.link {
  font-size: 13px;
  color: $color-white;
  font-weight: bold;
  border-radius: 30px;
  line-height: 20px;
  font-family: Montserrat;
  border: 2px solid $color-black;
  padding: 7px 15px 7px 15px;
  min-width: 113px;
  background: $color-black;
  cursor: pointer;
  letter-spacing: 0;
}
.modal {
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 451px;
  max-width: 900px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 550px;
  margin: auto;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 16px;
    @media screen and (max-width: $break-small) {
      padding: 2rem ​2rem;
    }
    h1 {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }
    p {
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
    }
    .buttonsrow {
      padding: 15px 0px;
      button {
        margin: 0px 10px;
        padding: 0.85rem 1.78rem;
      }
    }
    .warningImg {
      height: auto;
      width: 70px;
      margin: 20px auto;
    }
    .backDiv {
      position: absolute;
      right: 10px;
      top: 5px;
      cursor: pointer;
      img {
        height: 15px;
        width: 15px;
        bottom: 1px;
        left: 0px;
      }
      span {
        font-weight: bold;
      }
    }
  }
}
