.chartWrapper {
  position: relative;
  float: left;
}

.chartCaption {
  text-align: center;
  position: absolute;
  line-height: 100px;
  width: 100%;
}

.circle {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
}

.circleAnimation {
  -webkit-animation: css 1s reverse ease-in;
  animation: css 1s reverse ease-in;
}

@keyframes css {
  to {
    stroke-dashoffset: 100;
  }
}
