@import '../../styles/_breaks.scss';
@import '../../styles/_sizes.scss';
@import '../../styles/_colors.scss';

.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  @media screen and (min-width: $break-small) {
    margin-top: -$header-height-desktop;
  }
  @media screen and (max-width: $break-small) {
    background-size: 125% auto;
    min-height: 36.625rem;
    justify-content: start;
    margin-top: 1rem;
  }
  h2 {
    font-size: 2.375rem;
    white-space: pre-wrap;
  }
  h3 {
    font-size: 2.5rem;
    font-weight: bold;
    @media screen and (max-width: $break-small) {
      padding-top: 1.5625rem;
      font-size: 1.5625rem;
    }
  }
  p {
    font-family: 'Roboto Mono', monospace;
    font-size: 1rem;
    font-weight: normal;
  }
  .transition-container {
    @media screen and (max-width: $break-small) {
      margin-top: 7rem;
    }
  }
  .transition-subtitle {
    max-width: 21.25rem;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: $break-small) {
      max-width: 16rem;
    }
  }
  form {
    margin: 0 auto;
    max-width: 62.5rem;
    & > div {
      margin: 0 auto;
      width: 100%;
    }
    @media screen and (max-width: $break-small) {
      padding: 0 1rem;
    }
    @media screen and (min-width: $break-medium) {
      margin-top: 6.25rem;
    }
  }
  .logo {
    top: 1.7rem;
    width: 100%;
    position: absolute;
    margin: 0 auto;
    img {
      width: 6.25rem;
    }
    @media screen and (max-width: $break-small) {
      top: 1rem;
    }
  }
}

.background-1 {
  background-image: url('../../img/main1.jpg');
}

.background-2 {
  background-image: url('../../img/main2.jpg');
}

.background-3 {
  background-image: url('../../img/main3.jpg');
}

.background-4 {
  background-image: url('../../img/main4.jpg');
}

.background-5 {
  background-image: url('../../img/main5.jpg');
}

.back-link {
  font-family: 'Roboto Mono', monospace;
  display: flex;
  position: absolute;
  left: 5rem;
  bottom: 5rem;
  cursor: pointer;
  span {
    font-size: 1.25rem;
    margin-left: 1rem;
  }
  @media screen and (max-width: $break-small) {
    margin: 1rem;
  }
}

form > div.back-link {
  width: 11.5rem;
  height: 6.25rem;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $break-small) {
    width: 4.375rem;
    height: 4.375rem;
    left: 0;
    top: 31rem;

    img {
      width: 0.9375rem;
    }
  }
}

div.actual-padding {
  @media screen and (max-width: $break-small) {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    button {
      margin: 0 auto;
    }
  }
}

.input {
  max-width: 30.125rem;
  @media screen and (max-width: $break-small) {
    max-width: 20.75rem;
  }
  button[disabled],
  button[disabled]:focus,
  button[disabled]:hover {
    opacity: 1;
    background-color: $color-white !important;
  }
}

.input > input {
  font-family: 'Roboto Mono', monospace;
  line-height: 2rem;
  @media screen and (max-width: $break-small) {
    font-size: 1.125rem;
  }
}

.grid-center {
  justify-content: center;
}

.slider-container {
}

div.multi-choice-small {
  padding: 0.5rem 1rem;
}
