@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  table tbody tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }
  table tbody tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }
  table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  display: flex;
  .createTemplateButtonRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .button {
      font-family: Montserrat;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
    }
    .templateButton {
      height: max-content;
    }
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  margin-bottom: 33px;
  border-bottom: 2px solid #b4b4c6;
  .tab {
    background-color: unset;
    padding-left: unset;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    color: #b4b4c6;
    margin-right: 40px;
    cursor: pointer;
    letter-spacing: 1px;
    position: relative;

    &.activeTab {
      > span {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: bold;
        color: #43425d;
      }
      > div {
        text-align: center;
        padding-bottom: 2px;
        background-color: #808bff;
        position: relative;
        top: 2px;
      }
    }
  }
}

.leftnav {
  padding: 0px !important;
}
.content {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 41px;
    width: -webkit-fill-available;
    text-align: left;
  }
  .buttons {
    text-align: center;
    margin-top: 32px;

    .btn {
      box-sizing: border-box;
      border: 2px solid #000000;
      border-radius: 30px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      padding: 1rem 1.75rem;
      padding: 6px 19px;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $break-small) {
    display: flex;
  }

  .subheader {
    display: flex;
  }

  .list {
    display: flex;
    align-items: center;

    > div {
      display: inline-block;
      width: 100px;
      @media screen and (min-width: $break-small) {
        display: flex;
        width: 130px;
      }
      label {
        white-space: nowrap;
        color: #000000;
        font-family: Barlow;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-transform: none;
        @media screen and (max-width: $break-small) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.timeline {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 30px;
  .timelineHead {
    padding: 0px !important;
    background: unset;
    border: unset;
    .heading {
      margin: 0px;
      color: #b4b4c6;
      font-size: 18px;
      font-weight: bold;
      font-family: Montserrat;
      letter-spacing: 1px;
      line-height: 24px;
      @media screen and (max-width: $break-small) {
        min-width: 110px;
      }
    }
  }
  .timelineList {
    tr {
      background: unset;
      td {
        color: #2f2e50;
        font-family: Barlow;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        @media screen and (max-width: $break-small) {
          font-size: 14px;
          letter-spacing: 0.88px;
          line-height: 20px;
        }
        .formTitle {
          font-family: Montserrat;
          font-size: 18px !important;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          letter-spacing: 0;
          max-width: 200px;
          overflow: hidden;
        }
        .formsAndProtocols {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
          letter-spacing: 0;
          max-width: 300px;
          overflow: hidden;
        }
        .useTemplateButton {
          font-family: Montserrat;
          margin: 0;
          padding: 0.75em 1em;
        }
      }
      .timelineDetails {
        white-space: nowrap;
        text-overflow: ellipsis;
        letter-spacing: 0;
        max-width: 200px;
        overflow: hidden;
      }
    }
  }
}

.cell {
  padding: 0 !important;
}

.templateTable {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 30px;
  .templateHead {
    background: unset;
    border: unset;
    padding: 8px 15px 0px 15px !important;
    .heading {
      padding: 0px !important;
      margin: 0px;
      color: #b4b4c6;
      font-size: 15px;
      font-weight: bold;
      font-family: Montserrat;
      letter-spacing: 1px;
      line-height: 24px;
      @media screen and (max-width: $break-small) {
        min-width: 110px;
      }
    }
  }
  color: #2f2e50;
  font-family: Barlow;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  @media screen and (max-width: $break-small) {
    font-size: 14px;
    letter-spacing: 0.88px;
    line-height: 20px;
  }

  .timelineList {
    background-color: #fefefe;
    border-radius: 10px;
    padding: 5px 15px;

    .templateRow {
      padding: 5px 0px !important;
    }

    .templateTitle {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0;
      overflow: hidden;
      padding: 0 15px 0 0;
      align-self: center;
    }
    .formsAndProtocols {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0;
      overflow: hidden;
      padding: 0 15px 0 0;
      align-self: center;
    }
    .useTemplateColumn {
      padding: 0 !important;
    }
    .useTemplateButton {
      font-family: Montserrat;
      margin: 0;
      padding: 0.7em 1em !important;
      align-self: center;
      @media screen and (max-width: $break-small) {
        font-family: Montserrat;
        margin: 0;
        padding: 0.75em 1em !important;
        font-size: 10px !important;
        align-self: center;
      }
    }

    .deleteTemplateButton {
      text-align: end;
      padding: 0 !important;
      align-self: center;
      margin-bottom: 2px !important;
      cursor: pointer;
    }
  }
  .activeProtocolList {
    background-color: #fefefe;
    border-radius: 10px;
    padding: 5px 15px;

    .templateRow {
      padding: 5px 0px !important;
    }

    .templateTitle {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0;
      overflow: hidden;
      padding: 0 15px 0 0;
      align-self: center;
      cursor: pointer;
    }
    .assignToColumn {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0;
      overflow: hidden;
      padding: 0 15px 0 0;
      align-self: center;
    }
    .formsAndProtocols {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0;
      overflow: hidden;
      padding: 0 15px 0 0;
      align-self: center;
    }
    .deleteAndEditButton {
      text-align: end;
      padding: 0 !important;
      align-self: center;
      margin-bottom: 2px !important;
      .editButton {
        cursor: pointer;
        margin-right: 20px;
      }
      .deleteButton {
        cursor: pointer;
      }
    }
  }
}

.modal {
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 10px 24px -10px rgba(32, 32, 32, 0.5);
  position: fixed;
  max-height: 479px;
  max-width: 647px;
  width: 100%;
  margin: auto;
  overflow: auto;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 7rem;
  background-position: 33rem 4.5rem;
  @media screen and (max-width: $break-small) {
    max-width: 90%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px;
    font-family: Montserrat Light;

    .closeButton {
      top: 15px;
      position: absolute;
      right: 15px;
      cursor: pointer;
    }
    .modalTitle {
      font-size: 35px;
      align-self: center;
    }
    .subHeader {
      margin-top: 5px;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: bold;
    }
    .details {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: normal;
    }
    .primaryButton {
      align-self: center !important;
    }
  }
}
