@import '../../../styles/_breaks.scss';
@import '../../../styles/_sizes.scss';
@import '../../../styles/_colors.scss';

.container {
  display: flex;
  background: #f0f2f8;
}
.leftnav {
  padding: 0px !important;
}
.content {
  overflow: hidden;
  padding: 20px;
  width: 100%;

  h1 {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
  }
  .nodata {
    font-size: 16px;
    color: $color_black;
  }

  .buttons {
    text-align: center;
    margin-top: 32px;

    .btn {
      box-sizing: border-box;
      border: 2px solid #000000;
      border-radius: 30px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      padding: 1rem 1.75rem;
      padding: 6px 19px;
    }
  }
  .header {
    color: #000000;
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 42px;
    text-align: left;
    padding: 0;
    margin: 10px 0;
  }
}

.stepsCard {
  display: flex;
  margin-bottom: 30px;
  @media screen and (max-width: $break-medium) {
    overflow: auto;
    margin-right: -20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: $break-small) {
    display: flex;
    overflow: auto;
    margin-right: -20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
}

.superBillWrapper {
  list-style: none;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  @media screen and (max-width: $break-medium) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $break-small) {
    grid-template-columns: 1fr;
  }
}

.info {
  padding-top: 40px;
  h3 {
    color: #000000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
    min-height: 60px;
  }
  h1 {
    color: #000000;
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .text {
    max-width: 489px;
    color: #000;
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 42px;
  }
}
